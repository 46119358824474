import PaymentConvenience from "../Assets/Images/slideravatar.png";
const LandingSliderData = [
  {
    
    heading: "AI for Effortless Job Closing",
    backgroundColor: "#6a9b41",
    paragraph:
      "GoSignings automates the entire job closing process with AI, eliminating the need for manual scheduling and communication.",
  },
  {
    
    heading: "Intelligent Job Matching",
    backgroundColor: "#6a9b41",
    paragraph:
      "GoSignings finds the perfect job fit based on your availability, preferences, and desired fees, surpassing basic scheduling systems.",
  },
  {
    
    heading: "Notifications",
    backgroundColor: "#6a9b41",
    paragraph:
      "GoSignings will send job alerts instantly to your phone.",
  },
  {
    
    heading: "Calendar",
    backgroundColor: "#6a9b41",
    paragraph:
      "Once a job is accepted, GoSignings automatically adds it to your calendar, allowing you to easily manage and view all your Jobs.",
  },
];
export default LandingSliderData;






















// import PaymentConvenience from "../Assets/Images/slideravatar.png";
// const LandingSliderData = [
//   {
//     icon: PaymentConvenience,
//     heading: "Schedule Appointments",
//     backgroundColor: "#fcede0",
//     paragraph:
//       "I have been using this platform for quite some time now and I have to say it has been the best  platform that I've used. The quality of service on this site is very high and they're totally reliable. I would highly recommend it!",
//   },
//   {
//     icon: PaymentConvenience,
//     heading: "Simplified Booking",
//     backgroundColor: "#ebf5e5",
//     paragraph:
//       "I have been using this platform for quite some time now and I have to say it has been the best  platform that I've used. The quality of service on this site is very high and they're totally reliable. I would highly recommend it!",
//   },
//   {
//     icon: PaymentConvenience,
//     heading: "Customer",
//     backgroundColor: "#fff7da",
//     paragraph:
//       "I have been using this platform for quite some time now and I have to say it has been the best  platform that I've used. The quality of service on this site is very high and they're totally reliable. I would highly recommend it!",
//   },
//   {
//     icon: PaymentConvenience,
//     heading: "Schedule Appointments",
//     backgroundColor: "#fcede0",
//     paragraph:
//       "I have been using this platform for quite some time now and I have to say it has been the best  platform that I've used. The quality of service on this site is very high and they're totally reliable. I would highly recommend it!",
//   },
//   {
//     icon: PaymentConvenience,
//     heading: "Simplified Booking",
//     backgroundColor: "#ebf5e5",
//     paragraph:
//       "I have been using this platform for quite some time now and I have to say it has been the best  platform that I've used. The quality of service on this site is very high and they're totally reliable. I would highly recommend it!",
//   },
//   {
//     icon: PaymentConvenience,
//     heading: "Customer",
//     backgroundColor: "#fcede0",
//     paragraph:
//       "I have been using this platform for quite some time now and I have to say it has been the best  platform that I've used. The quality of service on this site is very high and they're totally reliable. I would highly recommend it!",
//   },
// ];
// export default LandingSliderData;
