import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Buttonn from "../Components/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import DecliendModal from "./DecliendModal";
import ConfirmedModal from "./ConfirmedModal";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../Requests/Requests";
import { updateInvitationsBackup } from "../Redux/Slices/InvitationsSlice";
// import 'react-toastify/dist/ReactToastify.css';

const PendingListBox = ({
  recieveddate,
  recievedtime,
  orderdate,
  location,
  order,
}) => {
  const [t, i18n] = useTranslation("global");
  const [showDeclined, setShowDeclined] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [currentTask, setCurrentTask] = useState(null);
  // const [stateee, setState] = useState(true);

  const Declined = () => {
    setCurrentTask("decline");
    setShowDeclined(true);
  };
  const Confirmed = () => {
    setCurrentTask("confirm");
    setShowConfirmed(true);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const [loading, setloading] = useState(false);

  const confirmOrder = async (opportunityId) => {
    if (opportunityId) {
      const formData = new FormData();
      formData.append("email", user?.email);
      formData.append("opportunityId", opportunityId);
      formData.append("declineReason", declineReason);
      setloading(true);
      postRequest("/closingOppotunity/confirm", formData)
        .then((data) => {
          dispatch(updateInvitationsBackup(data?.invitations));
          setloading(false);
          toast.success(t("dialogue.confirmed"));
        })
        .catch((err) => {
          // console.error(err);
          setloading(false);
        });
    }
  };

  const declineOrder = async (opportunityId) => {
    const formData = new FormData();
    formData.append("email", user?.email);
    formData.append("opportunityId", opportunityId);
    setloading(true);
    postRequest("/closingOppotunity/decline", formData)
      .then((data) => {
        if (data?.error) {
          toast.success(data?.error);
          return;
        }
        dispatch(updateInvitationsBackup(data?.invitations));
        setloading(false);
        toast.success(t("dialogue.declined"));
      })
      .catch((err) => {
        // console.error(err);
        setloading(false);
      });
  };

  const calculateDeclineAfterTime = () => {
    try {
      const date = new Date(recieveddate);
      date.setMinutes(date.getMinutes() + 7);
      return date.toLocaleTimeString();
    } catch (e) {
      return recieveddate;
    }
  };

  return (
    <div>
      <Grid
        sx={{
          boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.10)",
          borderRadius: "10px",
          my: 2,
        }}
        container
        spacing={2}
      >
        <Grid item>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: { md: "48px", xs: "24px" },
                height: { md: "48px", xs: "20px" },
                backgroundColor: "#6A9B41",
                fontSize: { md: "21px", xs: "14px" },
                color: "white",
                borderRadius: "50%",
                mx: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              1
            </Box>
            <Box>
              <Box
                sx={{
                  display: { lg: "flex" },
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 800 }}>
                  {t("recievedlist.title")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { lg: "18px", xs: "12px" },
                    fontWeight: 400,
                    border: "1px solid #8C8C8C",
                    borderRadius: "24px",
                    px: 2,
                    color: "#8C8C8C",
                    mx: { lg: 1 },
                    textAlign: "center",
                  }}
                >
                  {t("recievedlist.orderdecline")}{" "}
                  <span style={{ color: "#F26F21", fontWeight: 500 }}>
                    {" "}
                    {calculateDeclineAfterTime()}
                  </span>
                </Typography>
              </Box>
              <List component="nav">
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M9 6V9L10.875 10.875"
                        stroke="#F26F21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.20311 4.2031L3.67278 3.67277V3.67278L4.20311 4.2031ZM3.25332 5.15289L2.50333 5.15666C2.5054 5.56793 2.83828 5.90081 3.24955 5.90288L3.25332 5.15289ZM5.15872 5.91248C5.57292 5.91456 5.91039 5.58046 5.91247 5.16625C5.91456 4.75205 5.58046 4.41458 5.16625 4.41249L5.15872 5.91248ZM3.99372 3.23996C3.99164 2.82575 3.65417 2.49165 3.23996 2.49374C2.82575 2.49582 2.49165 2.83329 2.49374 3.2475L3.99372 3.23996ZM3.05572 8.11415C3.11197 7.70378 2.82489 7.3255 2.41452 7.26925C2.00414 7.213 1.62586 7.50008 1.56961 7.91045L3.05572 8.11415ZM14.2793 3.72074C11.3421 0.783638 6.59344 0.75211 3.67278 3.67277L4.73344 4.73344C7.05829 2.40858 10.8572 2.42001 13.2186 4.7814L14.2793 3.72074ZM3.72074 14.2793C6.65785 17.2164 11.4066 17.2479 14.3272 14.3272L13.2666 13.2666C10.9417 15.5914 7.1428 15.58 4.7814 13.2186L3.72074 14.2793ZM14.3272 14.3272C17.2479 11.4066 17.2164 6.65785 14.2793 3.72074L13.2186 4.7814C15.58 7.1428 15.5914 10.9417 13.2666 13.2666L14.3272 14.3272ZM3.67278 3.67278L2.72299 4.62256L3.78365 5.68322L4.73344 4.73343L3.67278 3.67278ZM3.24955 5.90288L5.15872 5.91248L5.16625 4.41249L3.25709 4.4029L3.24955 5.90288ZM4.00331 5.14912L3.99372 3.23996L2.49374 3.2475L2.50333 5.15666L4.00331 5.14912ZM1.56961 7.91045C1.26086 10.163 1.97995 12.5385 3.72074 14.2793L4.7814 13.2186C3.38388 11.8211 2.80842 9.91833 3.05572 8.11415L1.56961 7.91045Z"
                        fill="#F26F21"
                      />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#787486",
                      fontSize: { lg: "18px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("recievedlist.receivedon")}
                    <span style={{ color: "#1A1A1A" }}>
                      {" "}
                      {/* 1 Sep 2023{" "} */}
                      {recieveddate}
                    </span>
                  </Typography>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M15 7.5V5.25C15 4.42157 14.3284 3.75 13.5 3.75H4.5C3.67157 3.75 3 4.42157 3 5.25V7.5M15 7.5V14.25C15 15.0784 14.3284 15.75 13.5 15.75H4.5C3.67157 15.75 3 15.0784 3 14.25V7.5M15 7.5H3M6 2.25V5.25M12 2.25V5.25"
                        stroke="#F26F21"
                        stroke-linecap="round"
                      />
                      <path
                        d="M6.375 9H4.875C4.66789 9 4.5 9.16789 4.5 9.375V10.875C4.5 11.0821 4.66789 11.25 4.875 11.25H6.375C6.58211 11.25 6.75 11.0821 6.75 10.875V9.375C6.75 9.16789 6.58211 9 6.375 9Z"
                        fill="#F26F21"
                      />
                      <path
                        d="M9.75 9H8.25C8.04289 9 7.875 9.16789 7.875 9.375V10.875C7.875 11.0821 8.04289 11.25 8.25 11.25H9.75C9.95711 11.25 10.125 11.0821 10.125 10.875V9.375C10.125 9.16789 9.95711 9 9.75 9Z"
                        fill="#F26F21"
                      />
                      <path
                        d="M13.125 9H11.625C11.4179 9 11.25 9.16789 11.25 9.375V10.875C11.25 11.0821 11.4179 11.25 11.625 11.25H13.125C13.3321 11.25 13.5 11.0821 13.5 10.875V9.375C13.5 9.16789 13.3321 9 13.125 9Z"
                        fill="#F26F21"
                      />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#787486",
                      fontSize: { lg: "18px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("recievedlist.orderdate")}
                    <span style={{ color: "#1A1A1A" }}>
                      {/* 2 Sep 2023 */}
                      {orderdate}
                    </span>{" "}
                    {t("recievedlist.at")}{" "}
                    <span style={{ color: "#1A1A1A" }}>
                      {recievedtime}
                      {/* 9:00AM */}
                    </span>
                  </Typography>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_530_2386)">
                        <path
                          d="M3.75 6.38598C3.75 3.68753 6.10051 1.5 9 1.5C11.8995 1.5 14.25 3.68753 14.25 6.38598C14.25 9.06328 12.5744 12.1874 9.96005 13.3047C9.35061 13.5651 8.64939 13.5651 8.03995 13.3047C5.42562 12.1874 3.75 9.06328 3.75 6.38598Z"
                          stroke="#F26F21"
                          stroke-width="1.5"
                        />
                        <path
                          d="M10.5 6.75C10.5 7.57843 9.82843 8.25 9 8.25C8.17157 8.25 7.5 7.57843 7.5 6.75C7.5 5.92157 8.17157 5.25 9 5.25C9.82843 5.25 10.5 5.92157 10.5 6.75Z"
                          stroke="#F26F21"
                          stroke-width="1.5"
                        />
                        <path
                          d="M15.7204 11.625C16.2194 12.0769 16.5 12.5862 16.5 13.125C16.5 14.989 13.1421 16.5 9 16.5C4.85786 16.5 1.5 14.989 1.5 13.125C1.5 12.5862 1.78059 12.0769 2.2796 11.625"
                          stroke="#F26F21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_530_2386">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#787486",
                      fontSize: { lg: "18px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("recievedlist.location")}
                    <span style={{ color: "#1A1A1A" }}>
                      {location}
                      {/* 50 Hospital Road, Leominster, MA, 01453 */}
                    </span>
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            px: { lg: 2, xs: 1 },
            pb: { lg: 2, xs: 1 },
          }}
        >
          <Button
            onClick={Declined}
            sx={{
              fontSize: { lg: "14px", xs: "10px" },
              backgroundColor: "rgba(255, 122, 122, 1)",
              color: "white",
              borderRadius: "50px",
              px: 2,
              textTransform: "none",
              border: "1px solid rgba(255, 122, 122, 1)",
              "&:hover": {
                color: "rgba(255, 122, 122, 1)",
                backgroundColor: "white",
              },
            }}
          >
            {loading && currentTask == "decline"
              ? "processing..."
              : t("dashboard.declined")}
          </Button>
          <Button
            onClick={Confirmed}
            sx={{
              fontSize: { lg: "14px", xs: "10px" },
              backgroundColor: "#7cb661",
              color: "white",
              float: "right",
              borderRadius: "50px",
              px: 2,
              textTransform: "none",
              border: "1px solid #7cb661",
              "&:hover": {
                color: "#7cb661",
                backgroundColor: "white",
              },
            }}
          >
            {loading && currentTask == "confirm"
              ? "processing..."
              : t("dashboard.confirmed")}
          </Button>
        </Box>
        {showDeclined && (
          <DecliendModal
            open={showDeclined}
            setDeclineReason={setDeclineReason}
            declineReason={declineReason}
            parentNotify={() => declineOrder(order?.id)}
            handleClose={() => setShowDeclined(false)}
          />
        )}
        <Toaster />
        {showConfirmed && (
          <ConfirmedModal
            open={showConfirmed}
            parentNotify={() => {
              confirmOrder(order?.id);
            }}
            handleClose={() => setShowConfirmed(false)}
          />
        )}
      </Grid>
    </div>
  );
};

export default PendingListBox;
