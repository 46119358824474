// import React, { useState } from "react";
// import { CirclePicker } from "react-color";
// import ModalLayout from "../Layouts/ModalLayout";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Grid,
//   Paper,
//   Typography,
// } from "@mui/material";

// const ColorPicker = ({open, handleClose}) => {
//   const [currentColor, setCurrentColor] = useState("#000000");
//   const [oopen, setOpen] = useState(false);
//   const [colorPickerIndex, setColorPickerIndex] = useState(null);
//   const [colors, setColors] = useState([
//     "#AD1457",
//     "#F4511E",
//     "#9E69AF",
//     "#FFFF00",
//     "#33B679",
//     "#00FFFF",
//     "#616161",
//   ]); 

//   const handleColorChange = (color) => {
//     const updatedColors = [...colors];
//     updatedColors[colorPickerIndex] = color.hex;
//     setColors(updatedColors);
//     setCurrentColor(color.hex);
//     handleClosee();
//   };

//   const handleClickOpen = (index) => {
//     setColorPickerIndex(index);
//     setOpen(true);
//   };

//   const handleClosee = () => {
//     setOpen(false);
//   };

//   return (
//     <ModalLayout
//       open={open}
//       handleClose={handleClose}
//       sx={{
//         width: { lg: "25%", xs: "25%" },
//         p: { lg: 7, xs: 2 },
//         height: { lg: "35%", xs: "12%" },
//         mx: { sm: 5, lg: 0 },
//         borderRadius: "10px",
//         bgcolor: "background.paper",
//       }}
//     >
//       <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
//         Display this only
//       </Typography>
//       <Grid container spacing={2} sx={{my:2}}>
//         {colors.map((color, index) => (
//           <Grid item key={index}>
//             <Paper
//               style={{
//                 width: "30px",
//                 height: "30px",
//                 borderRadius: "50%",
//                 backgroundColor: color,
//                 cursor: index === 6 ? "pointer" : "auto",
//               }}
//               onClick={() => index === 6 && handleClickOpen(index)}
//             >
//               {index === 6 && <Typography variant="body2">Add</Typography>}
//             </Paper>
//           </Grid>
//         ))}
//       </Grid>
//       <Dialog open={oopen} onClose={handleClosee}>
//         <DialogTitle>Select Color</DialogTitle>
//         <DialogContent>
//           <CirclePicker color={currentColor} onChange={handleColorChange} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//       </ModalLayout>
//   );
// };

// export default ColorPicker;


import React, { useState } from "react";
import { CirclePicker } from "react-color";
import ModalLayout from "../Layouts/ModalLayout";
import {
 Button,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 Grid,
 Paper,
 Typography,
} from "@mui/material";

const ColorPicker = ({ open, handleClose, onColorSelected }) => {
 const [currentColor, setCurrentColor] = useState("#000000"); // Initial color black
 const [colorPickerIndex, setColorPickerIndex] = useState(null);
 const [colors, setColors] = useState([
    "#AD1457",
    "#F4511E",
    "#9E69AF",
    "#FFFF00",
    "#33B679",
    "#00FFFF",
    "#616161",
 ]); // The 7th circle is initially white

 const handleColorChange = (color) => {
    const updatedColors = [...colors];
    updatedColors[colorPickerIndex] = color.hex;
    setColors(updatedColors);
    setCurrentColor(color.hex);
    if (onColorSelected) {
      onColorSelected(color.hex);
    }
    handleClose(); // Ensure this is the correct function to close the dialog
 };

 const handleClickOpen = (index) => {
    setColorPickerIndex(index);
    // Assuming you want to open the dialog here, ensure you're managing the state correctly
    // If you have a separate state for controlling the dialog visibility, use that here
 };

 return (
    // <ModalLayout
    //   open={open}
    //   handleClose={handleClose}
    //   sx={{
    //     width: { lg: "75%", xs: "25%" },
    //     p: { lg: 7, xs: 2 },
    //     height: { lg: "75%", xs: "12%" },
    //     mx: { sm: 5, lg: 0 },
    //     borderRadius: "10px",
    //     bgcolor: "background.paper",
    //   }}
    // >
    //   <Typography sx={{ fontSize: "18px", fontWeight: 800 }}>
    //     Display this only
    //   </Typography>
    //   <Grid container spacing={2} sx={{ my: 2 }}>
    //     {colors.map((color, index) => (
    //       <Grid item key={index}>
    //         <Paper
    //           style={{
    //             width: "30px",
    //             height: "30px",
    //             borderRadius: "50%",
    //             backgroundColor: color,
    //             cursor: index === 6 ? "pointer" : "auto",
    //           }}
    //           onClick={() => index === 6 && handleClickOpen(index)}
    //         >
    //           {index === 6 && <Typography variant="body2">Add</Typography>}
    //         </Paper>
    //       </Grid>
    //     ))}
    //   </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Color</DialogTitle>
        <DialogContent>
          <CirclePicker color={currentColor} onChange={handleColorChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    // </ModalLayout>
 );
};

export default ColorPicker;