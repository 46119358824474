import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const LandingSlider = ({ icon, heading, paragraph, backgroundColor }) => {
  return (
    <Box
      sx={{
        minHeight: { lg: "300px", xs: "300px", }, // Same height for all screens
        maxHeight: { lg: "300px", xs: "250px" }, // Set maxHeight to ensure no overflow
        mx: {md: 1, xs: 4.0},
        justifyContent: "",
        p: 4,
        backgroundColor: backgroundColor || "white",
        borderRadius: '10px',
        padding: '25px 15px',
      }}
    >
      <Box sx={{ display: "flex" }}>
        {/* <img src={icon} alt="" style={{width: "45px", height: "45px"}} /> */}
        <Box>
          <Typography sx={{ fontSize: { lg: "22px", xs: "22px", }, color: '#FFFFFF', mb: '10px', fontWeight: {md: '700', xs: '500', sm: '600'} }}>
            {heading}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ fontSize: { lg: "18px", xs: "16px" }, color: '#FFFFFF', fontWeight: '100' }}>
        {paragraph}
      </Typography>
    </Box>
  );
};

export default LandingSlider;
