import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "../../Layouts/DashboardLayout";
import SettingSidebar from "../../Components/SettingSidebar";
import CustomField from "../../Components/CustomField";
import Buttons from "../../Components/Button";
import photo from "../../Assets/Images/photoplaceholder.png";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import Cookie from "js-cookie";
import { updateOnlyUser, updateUser } from "../../Redux/Slices/userSlice";
import PopoverHover from "../../Components/Popover";
import UserPrefrenceStep1 from "../../Components/UserPrefrenceStep1";
import { useNavigate } from "react-router-dom";
import { updatePreferences } from "../../Redux/Slices/preferenceSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const backendpoint = process.env.REACT_APP_GOSIGNING_BACKEND_ENDPOINT;

const VoipPage = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.user);
  const preferences = useSelector((state) => state.preferences.data);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(preferences);

  return (
    <DashboardLayout>
      <Toaster />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ display: { xs: "none", lg: "block" } }}>
            <SettingSidebar />
          </Grid>
          <Grid item xs={12} lg={9}>
            {loading && ( // Conditional rendering of loader
              <Box
                sx={{
                  zIndex: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  backgroundColor: "black",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%",
                  opacity: 0.8,
                  width: { lg: "100%", xs: "100%" },
                  height: { lg: "100%", xs: "100%" },
                }}
              >
                <CircularProgress sx={{ color: "#F26F21" }} size={80} />
              </Box>
            )}

            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                color: "black",
                visibility: "hidden",
              }}
            >
              Settings
            </Typography>
            <Grid
              item
              xs={11}
              sx={{
                mx: "auto",
                px: { lg: 7, xs: 2 },
                py: 8,
                boxShadow: 1,
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "32px", xs: "18px" },
                  fontWeight: 800,
                  mb: 1,
                  color: "#545454",
                }}
              >
                {t("voip.title")}
              </Typography>
              <UserPrefrenceStep1 lg={12} show={false} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default VoipPage;
