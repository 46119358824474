// import React, { useRef, useEffect } from "react";
// import Cookie from "js-cookie";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { updateUser } from "../Redux/Slices/userSlice";
// import toast from "react-hot-toast";
// const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

// export default function Paypal({ plan, setloading }) {
//   const paypal = useRef();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.user?.user);

//   useEffect(() => {
//     const paypalButtons = window.paypal.Buttons({
//       createOrder: (data, actions, err) => {
//         return actions.order.create({
//           intent: "CAPTURE",
//           purchase_units: [
//             {
//               description: "Cool looking table",
//               amount: {
//                 currency_code: "USD",
//                 value: plan == "monthly" ? 9.99 : 120,
//               },
//             },
//           ],
//         });
//       },
//       onApprove: async (data, actions) => {
//         try {
//           const order = await actions.order.capture();
//           // console.log("PayPal Order:", order);

//           const myHeaders = new Headers();
//           myHeaders.append(
//             `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
//             `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
//           );
//           myHeaders.append(
//             "Authorization",
//             `Bearer ${Cookie.get("access_token")}`
//           );

//           const form_data = new FormData();
//           form_data.append("email", user?.email);
//           form_data.append("subscription_plan_name", plan);
//           form_data.append("payment_method", "paypal");
//           form_data.append("payment_id", order.id);
//           // form_data.append("card_number", '-');

//           const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: form_data,
//           };

//           setloading(true);
//           const response = await fetch(
//             `${apiEndpoint}/subscription/update/paypal`,
//             requestOptions
//           );
//           const data = await response.json();

//           // console.log(data);
//           if (data.status == 1) {
//             setloading(false);
//             dispatch(updateUser(data.user));
//             navigate("/subscription");
//             toast.success(data?.message);
//           } else {
//             setloading(false);
//             toast.error(data?.error);
//           }
//         } catch (err) {
//           console.error("PayPal Error:", err);
//           setloading(false);
//         }
//       },
//       onError: (err) => {
//         console.error("PayPal Error:", err);
//       },
//     });

//     paypalButtons.render(paypal.current).catch((error) => {
//       console.log("PayPal Button Rendering Error:", error); // Log rendering errors
//     });

//     // Cleanup function
//     return () => {
//       paypalButtons.close(); // Close the PayPal buttons
//     };
//   }, []);

//   return (
//     <div>
//       <div ref={paypal}></div>
//     </div>
//   );
// }
import React, { useRef, useEffect, useState } from "react";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../Redux/Slices/userSlice";
import toast from "react-hot-toast";
import { postRequest } from "../Requests/Requests";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function Paypal({ plan, setloading }) {
  const paypal = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user);
  const [orderId, serorderid] = useState(null);
  const paypalButtonRendered = useRef(false);

  useEffect(() => {
    const createOrder = async () => {
      try {
        let formData = new FormData();
        formData.append("plan", plan);
        formData.append("description", `gosignings ${plan} plan`);
        formData.append("email", user?.email);
        const response = postRequest(`/paypal/create-order`, formData);

        const data = await response;
        // console.log(data);
        if (data.id) {
          serorderid(data.id);
        } else {
          // console.log("Order creation failed");
          toast.error("some error occured!");
        }
      } catch (err) {
        // console.error("Order Creation Error:", err);
        toast.error("Failed to create order");
        setloading(false);
      }
    };

    if (orderId) {
      // Render PayPal Button when orderId is available

      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return orderId;
          },
          onApprove: async (data, actions) => {
            try {
              const captureUrl = `https://api.sandbox.paypal.com/v2/checkout/orders/${orderId}/capture`;
              const response = await fetch(captureUrl, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: `Basic ${btoa(
                  //   `${process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_SECRET}`
                  // )}`,
                  Authorization: `Basic ${btoa(
                    `AYB5I_lvxPO-DJkTQdSsO44tBNzwUApYqKiOG3-5NgaarDDYWzRZfrspCS5fa__W9CKfBwu1CFAETypM:EH9fIDMQny7ssNRnQz1sGt-i80rCZMRQXUYUd57rSS_l3emY66tepr_d4Aw6N2KWIDukkDBCOX8Vu9E1}`
                  )}`,
                },
              });
              const order = await response.json();
              console.log("Payment Captured:", order);
              try {
                const myHeaders = new Headers();
                myHeaders.append(
                  `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
                  `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
                );
                myHeaders.append(
                  "Authorization",
                  `Bearer ${Cookie.get("access_token")}`
                );

                const form_data = new FormData();
                form_data.append("email", user?.email);
                form_data.append("subscription_plan_name", plan);
                form_data.append("payment_method", "paypal");
                form_data.append("payment_id", order.id);
                // form_data.append("card_number", '-');

                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: form_data,
                };

                setloading(true);
                const response = await fetch(
                  `${apiEndpoint}/subscription/update/paypal`,
                  requestOptions
                );
                const data = await response.json();

                // console.log(data);
                if (data.status == 1) {
                  setloading(false);
                  dispatch(updateUser(data.user));
                  navigate("/subscription");
                  toast.success(data?.message);
                } else {
                  setloading(false);
                  toast.error(data?.error);
                }
              } catch (err) {
                // console.error("PayPal Error:", err);
                toast.error("some error occured!");
                setloading(false);
              }
            } catch (error) {
              // console.error("Capture Error:", error);
              toast.error("some error occured!");
            }
          },
          onError: (err) => {
            // console.error("PayPal Button Error:", err);
            toast.error("some error occured!");
          },
        })
        .render(paypal.current)
        .catch((error) => {
          // console.log("PayPal Button Rendering Error:", error); // Log rendering errors
          toast.error("some error occured!");
        });
    } else {
      createOrder();
    }
  }, [orderId]);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
