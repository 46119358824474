import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import AuthTopBgImg from "../Assets/Images/AuthTopBgPic.png";
import AuthBottomBgImg from "../Assets/Images/AuthbottomBgPic.png";
// import { height } from '@mui/system';

const styles = {
  authBackground: {
    // height: '1000px',
    position: 'relative',
    height: '50%'
  },
  gradientLayer: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#D6EBCC',
    zIndex: -1,
  },
  gradientLayer1: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(90deg, #FADCC1 -33.77%, #FADCC1 -33.76%, rgba(246, 185, 128, 0.76) 23.1%, rgba(242, 111, 33, 0.00) 62.2%)',
    zIndex: -1,
  },
  image1: {
    position: 'absolute',
    top: 0,
    right: 0,
    objectFit: 'contain',
  },
  image2: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    objectFit: 'contain',
  },
};

const AuthLayout = ({ children  }) => {
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust the breakpoint as needed

  return (
    <Box style={styles.authBackground} sx={{
      // height: '50%',
      minHeight:"100vh", maxHeight:"100%", p:0, m:0
    }}>
      <div style={styles.gradientLayer}></div>
      <div style={{ ...styles.gradientLayer1, opacity: isMobile ? 0.2 : 0.7 }}></div>
      <img
        src={AuthTopBgImg}
        alt="Image 1"
        style={{
          ...styles.image1,
          ...(isMobile && { rotate:"270deg", height:"101px", width:"223px", top:"-40px", right:"-59px" }), // Add mobile styles here
        }}
      />
      <img src={AuthBottomBgImg} alt="Image 2" 
      style={{
        ...styles.image2,
        ...(isMobile && { rotate:"270deg", width:"223px", left:"-220px", bottom:"-98px"  }), // Add mobile styles here
      }} />
      {children}
    </Box>
  );
};

export default AuthLayout;
