import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Input,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "../../Components/Button";
import PopoverHover from "../../Components/Popover";
import CustomSelect from "../../Components/CustomSelect";
import RowRadioButtonsGroup from "../../Components/RowRadioButtonsGroup";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { updateStepper2 } from "../../Redux/Slices/preferenceSlice";
import toast from "react-hot-toast";
import { persistor } from "../../Redux/store";
import CustomField from "../../Components/CustomField";
import { setSteppers } from "../../Redux/Slices/stepperInfoSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const Region = [
  { label: "AL - Alabama", value: "Alabama" },
  { label: "AK - Alaska", value: "Alaska" },
  { label: "AS - American Samoa", value: "American Samoa" },
  { label: "AZ - Arizona", value: "Arizona" },
  { label: "AR - Arkansas", value: "Arkansas" },
  { label: "CA - California", value: "California" },
  { label: "CO - Colorado", value: "Colorado" },
  { label: "CT - Connecticut", value: "Connecticut" },
  { label: "DE - Delaware", value: "Delaware" },
  { label: "DC - District Of Columbia", value: "District Of Columbia" },
  { label: "FL - Florida", value: "Florida" },
  { label: "GA - Georgia", value: "Georgia" },
  { label: "HI - Hawaii", value: "Hawaii" },
  { label: "ID - Idaho", value: "Idaho" },
  { label: "IL - Illinois", value: "Illinois" },
  { label: "IN - Indiana", value: "Indiana" },
  { label: "IA - Iowa", value: "Iowa" },
  { label: "KS - Kansas", value: "Kansas" },
  { label: "KY - Kentucky", value: "Kentucky" },
  { label: "LA - Louisiana", value: "Louisiana" },
  { label: "ME - Maine", value: "Maine" },
  { label: "MD - Maryland", value: "Maryland" },
  { label: "MA - Massachusetts", value: "Massachusetts" },
  { label: "MI - Michigan", value: "Michigan" },
  { label: "MN - Minnesota", value: "Minnesota" },
  { label: "MS - Mississippi", value: "Mississippi" },
  { label: "MO - Missouri", value: "Missouri" },
  { label: "MT - Montana", value: "Montana" },
  { label: "NE - Nebraska", value: "Nebraska" },
  { label: "NV - Nevada", value: "Nevada" },
  { label: "NH - New Hampshire", value: "New Hampshire" },
  { label: "NJ - New Jersey", value: "New Jersey" },
  { label: "NM - New Mexico", value: "New Mexico" },
  { label: "NY - New York", value: "New York" },
  { label: "NC - North Carolina", value: "North Carolina" },
  { label: "ND - North Dakota", value: "North Dakota" },
  { label: "OH - Ohio", value: "Ohio" },
  { label: "OK - Oklahoma", value: "Oklahoma" },
  { label: "OR - Oregon", value: "Oregon" },
  { label: "PA - Pennsylvania", value: "Pennsylvania" },
  { label: "RI - Rhode Island", value: "Rhode Island" },
  { label: "SC - South Carolina", value: "South Carolina" },
  { label: "SD - South Dakota", value: "South Dakota" },
  { label: "TN - Tennessee", value: "Tennessee" },
  { label: "TX - Texas", value: "Texas" },
  { label: "UT - Utah", value: "Utah" },
  { label: "VT - Vermont", value: "Vermont" },
  { label: "VA - Virginia", value: "Virginia" },
  { label: "WA - Washington", value: "Washington" },
  { label: "WV - West Virginia", value: "West Virginia" },
  { label: "WI - Wisconsin", value: "Wisconsin" },
  { label: "WY - Wyoming", value: "Wyoming" },
];

const areaCode = [
  { label: "202", value: "202" },
  { label: "205", value: "205" },
  { label: "206", value: "206" },
  { label: "207", value: "207" },
  { label: "208", value: "208" },
  { label: "210", value: "210" },
  { label: "213", value: "213" },
  { label: "214", value: "214" },
  { label: "215", value: "215" },
  { label: "216", value: "216" },
  { label: "217", value: "217" },
  { label: "218", value: "218" },
  { label: "219", value: "219" },
  { label: "220", value: "220" },
  { label: "223", value: "223" },
  { label: "224", value: "224" },
  { label: "225", value: "225" },
  { label: "228", value: "228" },
  { label: "229", value: "229" },
  { label: "231", value: "231" },
  { label: "234", value: "234" },
  { label: "239", value: "239" },
  { label: "240", value: "240" },
  { label: "248", value: "248" },
  { label: "251", value: "251" },
  { label: "252", value: "252" },
  { label: "253", value: "253" },
  { label: "254", value: "254" },
  { label: "256", value: "256" },
  { label: "260", value: "260" },
  { label: "262", value: "262" },
  { label: "267", value: "267" },
  { label: "269", value: "269" },
  { label: "270", value: "270" },
  { label: "272", value: "272" },
];

const StepperStep2 = ({
  onBack,
  onNext,
  lg = 7,
  show,
  // formData,
  // onFormChange,
  setloading,
}) => {
  const [t, i18n] = useTranslation("global");
  const [selectRegionError, setSelectRegionError] = useState(false);
  const [selectNumberError, setSelectNumberError] = useState(false);
  const [voipMsgError, setVoipMsgError] = useState(false);
  const [deleteMsgError, setDeleteMsgError] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [region, setregion] = useState(null);
  // const [loading, setloading] = useState(false);
  const [voipNumbers, setVoipNumbers] = useState([]);
  const [message, setmessage] = useState("");
  const [errors, seterrors] = useState(null);

  const user = useSelector((state) => state.user?.user);
  const prefSet =
    typeof user?.preferences_set == Boolean
      ? user?.preferences_set
      : user?.preferences_set == "true"
      ? true
      : false;
  const [stepper2Completed, setStepper2Completed] = useState(false);

  const [numbergot, setnumbergot] = useState(false);
  const typelist = [
    { label: "State", value: "state" },
    { label: "Area Code", value: "area_code" },
  ];

  const [Ntype, setNtype] = useState("state");
  const [NumberValue, setNumberValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pno, setPno] = useState(user?.phone_number);
  const dispatch = useDispatch();
  const reduxFormData = useSelector((state) => state.preferences.stepper2);
  const stepperInfo = useSelector((state) => state.stepperInfo.steppers);

  const [formData, setFormData] = useState(
    reduxFormData ?? {
      select_region: null,
      select_number: null,
      voipmsg: null,
      delete_msg: null,
    }
  );
  const onFormChange = (formKey, fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  useEffect(() => {
    // if (true) {
    if (stepperInfo?.stepper_2 && !reduxFormData) {
      setIsReady(formData.voipmsg && formData.delete_msg);
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/get/stepper1`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          if (result.error) {
            setLoading(false);
          } else {
            setLoading(false);
            if (result?.data) {
              setFormData({ ...result?.data, select_region: null });
              dispatch(
                updateStepper2({
                  delete_msg: result?.data?.delete_msg,
                  select_number: result?.data?.select_number,
                  voipmsg: result?.data?.voipmsg,
                  select_region: null,
                })
              );
              dispatch(setSteppers(result?.stepperInfo));
              setStepper2Completed(true);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, []);

  const handleNext = () => {
    if (!numbergot) {
      alert("select voip");
      return;
    }
    if (pno.replace(/\D/g, "").length < 10) {
      toast.error("Invalid phone number");
      return;
    }

    if (numbergot) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("data", JSON.stringify(formData));
      form_data.append("email", user?.email);
      form_data.append("phone", pno.replace(/\D/g, ""));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/add/stepper2`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          if (result.error) {
            toast.error(result.error);
            console.log(result.error);
            setLoading(false);
          } else {
            if (result?.data) {
              dispatch(
                updateStepper2({
                  delete_msg: result?.data?.delete_msg,
                  select_number: result?.data?.select_number,
                  voipmsg: result?.data?.voipmsg,
                  select_region: null,
                })
              );
              dispatch(setSteppers(result?.stepperInfo));
            }
            setLoading(false);
            onNext();
          }
        })
        .catch((error) => {
          toast.error("some error occured! refresh the page and try again!");
          console.error(error);
          setLoading(false);
        });
    } else {
      setSelectRegionError(!formData.select_region);
      setSelectNumberError(!formData.voipmsg);
      setVoipMsgError(!formData.voipmsg);
      setDeleteMsgError(!formData.delete_msg);
    }
  };

  const getVoipNumbers = async (e) => {
    if (e.target.value) {
      setmessage("Getting available phone numbers...");
      setloading(true);
      seterrors(null);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        `${apiEndpoint}/voip/phone-numbers/search/US/${e.target.value}/${Ntype}`,
        requestOptions
      )
        // fetch(`${apiEndpoint}/list/telnyx/phone/numbers`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          if (result?.data?.length > 0) {
            setVoipNumbers(
              result?.data?.map((item) => {
                return {
                  label: `${item?.phone_number}`,
                  value: item?.phone_number,
                  id: item?.id,
                };
              })
            );
          }
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      setVoipNumbers([]);
      seterrors(null);
    }
  };

  const OrderNumber = async (e) => {
    // console.log(e.target.value);
    // if (e.target.value) {
    //   setmessage("Ordering phone number... ");
    //   setloading(true);
    //   seterrors(null);
    //   const myHeaders = new Headers();
    //   myHeaders.append(
    //     `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
    //     `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    //   );
    //   myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    //   const requestOptions = {
    //     method: "GET",
    //     headers: myHeaders,
    //   };

    // fetch(
    //   `${apiEndpoint}/voip/phone-numbers/order/${e.target.value}`,
    //   requestOptions
    // )
    //   // fetch(
    //   //   `${apiEndpoint}/assign/messagingProfile/2483233245903193311`,
    //   //   requestOptions
    //   // )
    //   .then((response) => {
    //     if (response.status === 200) {
    //       return response.json();
    //     }
    //   })
    //   .then((result) => {
    //     console.log(result);
    //     if (result.error || result?.errors) {
    //       if (result?.errors[0]?.title) {
    //         seterrors(result?.errors[0]?.title);
    //       } else if (result?.error) {
    //         seterrors(result?.error);
    //       }
    //     } else if (result?.data?.messaging_profile_id != null) {
    setnumbergot(true);
    //       }
    //       setloading(false);
    //     })
    //     .catch((err) => {
    //       setloading(false);
    //     });
    // }
  };

  const udpateData = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("data", JSON.stringify(formData));
    form_data.append("email", user?.email);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/preferences/update/stepper2`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        if (result.error) {
          toast.error(result.error);
          setLoading(false);
        } else {
          setLoading(false);
          if (result?.data) {
            setFormData(result?.data);
            dispatch(
              updateStepper2({
                delete_msg: result?.data?.delete_msg,
                select_number: result?.data?.select_number,
                voipmsg: result?.data?.voipmsg,
                select_region: formData?.select_region,
              })
            );

            toast.success("Updated");
          }
        }
      })
      .catch((error) => {
        toast.error("some error occured! refresh the page and try again!");
        console.error(error);
        setLoading(false);
      });
  };

  const formatPhoneNumber = (input) => {
    if (input) {
      input = input.replace(/\D/g, "");

      if (input.length > 0) {
        input = "(" + input;
      }
      if (input.length > 4) {
        input = input.slice(0, 4) + ") " + input.slice(4);
      }
      if (input.length > 9) {
        input = input.slice(0, 9) + "-" + input.slice(9);
      }
      if (input.length > 14) {
        input = input.slice(0, 14); // Limit to (123) 456-7890 format
      }
    }
    return input;
  };
  return (
    <Container sx={{ position: "relative" }}>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "transparent",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}

      <Typography
        sx={{
          mx: { lg: "auto", xs: 3 },
          textAlign: "center",
          fontSize: { lg: "40px", xs: "18px" },
          fontWeight: { lg: 700, xs: 600 },
        }}
      >
        {user?.first_name} {show && <>{t("prefrence.choose_voipno")}</>}
      </Typography>
      <Grid container>
        <Grid
          item
          lg={lg}
          xs={12}
          sx={{
            backgroundColor: "white",
            mx: "auto",
            my: 2,
            p: { lg: 3, xs: 2 },
            borderRadius: "8px",
          }}
        >
          <form>
            <Typography
              sx={{
                mx: { lg: "auto", xs: 3 },
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.voipno")}
              <PopoverHover text="prefrence.voip_popover" />
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                  <CustomSelect
                    // label={t("prefrence.selectregion")}
                    disabled={formData?.select_number ? true : false}
                    label={"Search By"}
                    menuItems={typelist}
                    required={true}
                    name="search_by"
                    id="search_by"
                    value={Ntype} // Use formData for value
                    onChange={(e) => {
                      setNtype(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <CustomSelect
                    // label={t("prefrence.selectregion")}
                    disabled={formData?.select_number ? true : false}
                    label={
                      Ntype == "state" ? "Select State" : "Select Area Code"
                    }
                    menuItems={Ntype == "area_code" ? areaCode : Region}
                    required={true}
                    name="select_region"
                    id="select_region"
                    value={formData.select_region} // Use formData for value
                    onChange={(e) => {
                      onFormChange("stepper2", "select_region", e.target.value);
                      setSelectRegionError(false); // Clear error when field is selected
                      getVoipNumbers(e);
                    }}
                  />
                  {selectRegionError && (
                    <Typography sx={{ color: "red" }}>
                      Please select a region
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={4} xs={12}>
                  {formData?.select_number ? (
                    <>
                      <Box
                        sx={{
                          border: "1px solid black",
                          borderRadius: "10px",
                          height: "3.4rem",
                          marginTop: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: "#BDBDBD",
                        }}
                      >
                        {formData?.select_number}
                      </Box>
                    </>
                  ) : (
                    <>
                      <CustomSelect
                        disabled={formData?.select_number ? true : false}
                        label={t("prefrence.selectno")}
                        menuItems={voipNumbers}
                        required={true}
                        name="select_number"
                        id="select_number"
                        value={formData?.select_number} // Use formData for value
                        onChange={(e) => {
                          onFormChange(
                            "stepper2",
                            "select_number",
                            e.target.value
                          );
                          setSelectNumberError(false);
                          OrderNumber(e);
                        }}
                      />
                      {selectNumberError && (
                        <Typography sx={{ color: "red" }}>
                          Please select a number
                        </Typography>
                      )}
                    </>
                  )}

                  {errors && (
                    <Typography sx={{ color: "red" }}>{errors}</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>

            {!user?.phone_number && (
              <>
                <Typography
                  sx={{
                    mx: { lg: "auto", xs: 3 },
                    fontSize: { lg: "25px", xs: "14px" },
                    fontWeight: { lg: 600, xs: 500 },
                    display: "flex",
                  }}
                >
                  {t("prefrence.personal_number")}
                  <PopoverHover text="prefrence.voip_popover" />
                </Typography>
                <Box sx={{ display: "flex", my: 2 }}>
                  <CustomField
                    label={t("form.pno")}
                    placeholder={t("form.pnoplaceholder")}
                    type="tel"
                    id="pno"
                    name="pno"
                    value={formatPhoneNumber(pno)}
                    onChange={(inputValue) => {
                      inputValue = formatPhoneNumber(inputValue);
                      setPno(inputValue);
                    }}
                  />
                </Box>
              </>
            )}

            <Box sx={{ display: "flex", my: 2 }}>
              <RowRadioButtonsGroup
                label={t("prefrence.recievedvoip")}
                labelConfigs={[
                  { value: "Get all Messages", label: t("prefrence.voipmsg1") },
                  {
                    value: "Get Selected Messages",
                    label: t("prefrence.voipmsg2"),
                  },
                ]}
                selectedValue={formData.voipmsg}
                setSelectedValue={(value) => {
                  onFormChange("stepper2", "voipmsg", value);
                  setVoipMsgError(false); // Clear error when field is selected
                }}
                poptext={t("prefrence.voipmsg_popover")}
                name="voipmsg"
                id="voipmsg"
              />
            </Box>
            {voipMsgError && (
              <Typography sx={{ color: "red" }}>
                Please select a voip message
              </Typography>
            )}
            <Box sx={{ display: "flex", my: 2 }}>
              <RowRadioButtonsGroup
                label={t("prefrence.deletemsgafter")}
                labelConfigs={[
                  { value: "7 Days", label: t("inbox.7days") },
                  { value: "30 Days", label: t("inbox.30days") },
                  { value: "90 Days", label: t("inbox.90days") },
                  { value: "none", label: t("prefrence.none") },
                ]}
                selectedValue={formData.delete_msg}
                setSelectedValue={(value) => {
                  onFormChange("stepper2", "delete_msg", value);
                  setDeleteMsgError(false); // Clear error when field is selected
                }}
                poptext={t("prefrence.deletemsg_popover")}
                name="delete_msg"
                id="delete_msg"
              />
            </Box>
            {deleteMsgError && (
              <Typography sx={{ color: "red" }}>
                Please select a delete message option
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                btnname="button.back"
                type="button"
                onClick={onBack}
                variant="contained"
                className="greybtn"
                sx={{
                  px: { lg: 7, xs: 3 },
                  py: 1.2,
                  fontSize: { lg: "16px", xs: "12px" },
                  mx: 1,
                  borderRadius: "8px",
                }}
              />
              {/* {formData?.select_number && stepper2Completed ? ( */}
              {stepperInfo?.stepper_2 ? (
                <>
                  <Button
                    btnname="update"
                    onClick={udpateData} // Use handleNext function to check before moving to step 3
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                    disabled={!isReady} // Disable the button if not all fields are filled
                  />
                  <Button
                    btnname="button.next"
                    onClick={() => onNext()} // Use handleNext function to check before moving to step 3
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                    disabled={!isReady} // Disable the button if not all fields are filled
                  />
                </>
              ) : (
                <Button
                  btnname="button.next"
                  onClick={handleNext} // Use handleNext function to check before moving to step 3
                  type="button"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    px: { lg: 7, xs: 3 },
                    py: 1.2,
                    fontSize: { lg: "16px", xs: "12px" },
                    mx: 1,
                    borderRadius: "8px",
                  }}
                  disabled={!isReady} // Disable the button if not all fields are filled
                />
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepperStep2;
