import React from "react";

const EnvironmentVariableTest = () => {
  const envVariable_REACT_APP_TEST = process.env.REACT_APP_TEST;

  return (
    <>
      <h1>
        {envVariable_REACT_APP_TEST
          ? envVariable_REACT_APP_TEST
          : "env variable REACT_APP_TEST not found"}
      </h1>
    </>
  );
};

export default EnvironmentVariableTest;
