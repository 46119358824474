import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import ReceivedInvitationbg from "../../Assets/Images/receivedinvitationbg.png";
import PendingInvitationbg from "../../Assets/Images/pendinginvitationbg.png";
import ConfirmedInvitationbg from "../../Assets/Images/confirmedinvitationbg.png";
import DeclinedInvitationbg from "../../Assets/Images/decliedinvitationbg.png";
import DeleteInvitationIcon from "../../Assets/Icons/delete invitationicon.svg";
import ConfirmeInvitationIcon from "../../Assets/Icons/confirminvitation.svg";
import PendingInvitationIcon from "../../Assets/Icons/pendinginvitation.svg";
import RecievedInvitationIcon from "../../Assets/Icons/recievedinvitationicon.svg";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PopoverHover from "../../Components/Popover";
import Button1 from "../../Components/Button";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SatelliteOutlinedIcon from "@mui/icons-material/SatelliteOutlined";
import ListTab from "../DashboardScreens/ListTab/List";
import CalenderTab from "./CalenderTab";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import Cookie from "js-cookie";
import Map from "../../Components/map";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderFilter, updateOrders } from "../../Redux/Slices/orderSlice";
import {
  updateInvitationFilter,
  updateInvitations,
} from "../../Redux/Slices/InvitationsSlice";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { display } from "@mui/system";
import DashboardCard from "../../Components/DashboardCard";
const Dashboard = () => {
  const [t, i18n] = useTranslation("global");
  const [value, setValue] = React.useState("1");

  const user = useSelector((state) => state.user.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (sessionStorage.getItem("signin") == "false") {
    toast.error(t("dialogue.pngimg"));
    sessionStorage.setItem("signin", "true");
  }

  if (sessionStorage.getItem("step5") == "false") {
    toast.success(t("dialogue.account_updated"));
    sessionStorage.setItem("step5", "true");
    console.log("stepper 5 completed");
  }

  const Invitations = useSelector((state) => state.invitations.invitations);
  const orders = useSelector((state) => state.orders.data);
  const BackupOrders = useSelector((state) => state.orders.BackupOrders);
  const orderfilter = useSelector((state) => state.orders.orderfilter);
  const invitationfilter = useSelector(
    (state) => state.invitations.invitationfilter
  );

  const dispatch = useDispatch();

  const changeOrdersFilter = (e) => {
    dispatch(updateOrderFilter(e.target.value));
  };

  const changeInvitationFilter = (e) => {
    dispatch(updateInvitationFilter(e.target.value));
  };

  useEffect(() => {
    updateVisibleOrders();
  }, [orderfilter, BackupOrders]);

  useEffect(() => {
    updateVisibleInvitations();
  }, [invitationfilter, BackupOrders]);

  function updateVisibleOrders() {
    if (BackupOrders) {
      let today = dayjs();
      let limit = today;

      switch (orderfilter) {
        case "day":
          break;
        case "week":
          limit = today.subtract(7, "day");
          break;
        case "month":
          limit = today.subtract(30, "day");
          break;
      }

      let pending = [];
      if (BackupOrders.pending) {
        pending = BackupOrders.pending.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      let recieved = [];
      if (BackupOrders.recieved) {
        recieved = BackupOrders.recieved.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      let declined = [];
      if (BackupOrders.declined) {
        declined = BackupOrders.declined.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      let confirmed = [];
      if (BackupOrders.confirmed) {
        confirmed = BackupOrders.confirmed.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      dispatch(
        updateOrders({
          recieved,
          pending,
          declined,
          confirmed,
        })
      );

      return {
        recieved,
        pending,
        declined,
        confirmed,
      };
    }
  }

  function updateVisibleInvitations() {
    if (BackupOrders) {
      let today = dayjs();
      let limit = today;

      switch (invitationfilter) {
        case "day":
          break;
        case "week":
          limit = today.subtract(7, "day");
          break;
        case "month":
          limit = today.subtract(30, "day");
          break;
      }

      let pending = [];
      if (BackupOrders.pending) {
        pending = BackupOrders.pending.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      let received = [];
      if (BackupOrders.received) {
        // console.log(BackupOrders.received);
        received = BackupOrders.received.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      let declined = [];
      if (BackupOrders.declined) {
        declined = BackupOrders.declined.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      let confirmed = [];
      if (BackupOrders.confirmed) {
        confirmed = BackupOrders.confirmed.filter((order) => {
          let createdAt = dayjs(order.created_at);
          return (
            createdAt.isSame(today, "day") ||
            (createdAt.isBefore(today, "day") &&
              createdAt.isAfter(limit, "day"))
          );
        });
      }

      dispatch(
        updateInvitations({
          received: received.length ? received.length : 0,
          pending: pending.length ? pending.length : 0,
          declined: declined.length ? declined.length : 0,
          confirmed: confirmed.length ? confirmed.length : 0,
        })
      );
    }
  }

  const [dateFilter, setDateFilter] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);

  useEffect(() => {
    if (dateFilter || timeFilter) {
      dispatch(updateOrders(filterOrdersbyDateTime(updateVisibleOrders())));
    }
  }, [dateFilter, timeFilter]);

  let filterOrdersbyDateTime = (orders) => {
    const filterByDate = (ordersArray) => {
      return ordersArray.filter((order) => {
        return dayjs(order.closing_date).isSame(dayjs(dateFilter), "day");
      });
    };

    const convertTimeStringToDate = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, seconds, 0);
      return date;
    };

    const filterByTime = (ordersArray) => {
      const startTime = dayjs(timeFilter, "HH:mm:ss");
      const endTime = startTime.add(5, "minute");

      return ordersArray.filter((order) => {
        const orderTime = order.closing_time;
        return (
          convertTimeStringToDate(orderTime).getTime() <
          convertTimeStringToDate(endTime.format("HH:mm:ss")).getTime() &&
          convertTimeStringToDate(orderTime).getTime() >=
          convertTimeStringToDate(startTime.format("HH:mm:ss")).getTime()
        );
      });
    };

    if (dateFilter && timeFilter) {
      return {
        confirmed: filterByTime(filterByDate(orders.confirmed)),
        declined: filterByTime(filterByDate(orders.declined)),
        pending: filterByTime(filterByDate(orders.pending)),
        recieved: filterByTime(filterByDate(orders.recieved)),
      };
    } else if (dateFilter) {
      return {
        confirmed: filterByDate(orders.confirmed),
        declined: filterByDate(orders.declined),
        pending: filterByDate(orders.pending),
        recieved: filterByDate(orders.recieved),
      };
    } else if (timeFilter) {
      return {
        confirmed: filterByTime(orders.confirmed),
        declined: filterByTime(orders.declined),
        pending: filterByTime(orders.pending),
        recieved: filterByTime(orders.recieved),
      };
    }
  };

  const filterUpcommingJobs = (e) => {
    if (e.target.checked) {
      setDateFilter(dayjs());
      setTimeFilter(null);
    } else {
      updateVisibleOrders();
      setDateFilter(null);
    }
  };

  return (
    <DashboardLayout>
      <Toaster />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{ fontSize: { md: '40px', xs: '18px' }, fontWeight: 600 }}
          >
            Good Morning, {user?.first_name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button1
            btnname="Start Tracking"
            variant="outlined"
            className="Orangebtn"
            sx={{
              fontSize: { lg: '16px', xs: '12px', sm: '16px' },
              textTransform: 'none',
              width: { lg: '155px', xs: '80px', sm: '155px' },
              height: { lg: '40px', xs: '32px', sm: '40px' },
              border: {
                xs: '1px solid ',
                md: '2px solid ',
                sm: '2px solid ',
              },
              borderRadius: '7px',
              color: '#ffffff',
              borderColor: '',
              '&:hover': {
                backgroundColor: '#7cb74a',
                color: 'white',
                border: '1px solid #7cb74a',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} sx={{ float: "right" }}>
        <select
          name="cars"
          id="cars"
          value={invitationfilter}
          onChange={(e) => changeInvitationFilter(e)}
          style={{
            borderColor: "#F26F21",
            color: "#F26F21",
            padding: "5px",
            margin: "5px",
            borderRadius: "6px",
          }}
        >
          <option value="day">{t("dashboard.today")}</option>
          <option value="week">{t("dashboard.weekly")}</option>
          <option value="month">{t("dashboard.monthly")}</option>
        </select>
      </Grid>

      <Box sx={{ py: { lg: 3, xs: 1 }, px: { lg: 0, xs: 1 } }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${ReceivedInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={RecievedInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "16px", xs: "12px" } }}>
                  {t("dashboard.recieved")} {t("dashboard.invitations")}
                </Typography>
                <PopoverHover text="dashboard.recieved_popover" />
                {/* {t('recieved_popover')} */}
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {Invitations?.pending +
                  Invitations?.confirmed +
                  Invitations?.received +
                  Invitations?.declined}

                {/* {Number(
                  orders?.recieved?.length ? orders?.recieved?.length : 0
                ) +
                  Number(
                    orders?.declined?.length ? orders?.declined?.length : 0
                  ) +
                  Number(
                    orders?.pending?.length ? orders?.pending?.length : 0
                  ) +
                  Number(
                    orders?.confirmed?.length ? orders?.confirmed?.length : 0
                  )} */}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${DeclinedInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={DeleteInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "16px", xs: "12px" } }}>
                  {t("dashboard.declined")} {t("dashboard.invitations")}
                </Typography>
                <PopoverHover text="dashboard.decliend_popover" />
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {/* {Number(
                  orders?.declined?.length ? orders?.declined?.length : 0
                )} */}
                {Invitations?.declined}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${PendingInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={PendingInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "16px", xs: "12px" } }}>
                  {t("dashboard.pending")} {t("dashboard.invitations")}
                </Typography>
                <PopoverHover text="dashboard.pending_popover" />
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {/* {Number(orders?.pending?.length ? orders?.pending?.length : 0)} */}
                {Invitations?.pending}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${ConfirmedInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={ConfirmeInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "15px", xs: "11px" } }}>
                  {t("dashboard.confirmed")} {t("dashboard.invitations")}
                </Typography>
                <PopoverHover text="dashboard.confirmed_popover" />
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {/* {Number(
                  orders?.confirmed?.length ? orders?.confirmed?.length : 0
                )} */}
                {Invitations?.confirmed}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${ReceivedInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={RecievedInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "16px", xs: "12px" } }}>
                  {t("dashboard.total")} {t("dashboard.income")}
                </Typography>
                <PopoverHover text="dashboard.recieved_popover" />
                {/* {t('recieved_popover')} */}
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {Invitations?.pending +
                  Invitations?.confirmed +
                  Invitations?.received +
                  Invitations?.declined}

                {/* {Number(
                  orders?.recieved?.length ? orders?.recieved?.length : 0
                ) +
                  Number(
                    orders?.declined?.length ? orders?.declined?.length : 0
                  ) +
                  Number(
                    orders?.pending?.length ? orders?.pending?.length : 0
                  ) +
                  Number(
                    orders?.confirmed?.length ? orders?.confirmed?.length : 0
                  )} */}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${DeclinedInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={DeleteInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "16px", xs: "12px" } }}>
                  {t("dashboard.total")} {t("dashboard.drivetime")}
                </Typography>
                <PopoverHover text="dashboard.decliend_popover" />
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {/* {Number(
                  orders?.declined?.length ? orders?.declined?.length : 0
                )} */}
                {Invitations?.declined}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${PendingInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={PendingInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "16px", xs: "12px" } }}>
                  {t("dashboard.total")} {t("dashboard.mileage")}
                </Typography>
                <PopoverHover text="dashboard.pending_popover" />
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {/* {Number(orders?.pending?.length ? orders?.pending?.length : 0)} */}
                {Invitations?.pending}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={6} lg={3} sx={{ p: 0.5 }}>
            <Grid
              item
              sx={{
                backgroundImage: `url(${ConfirmedInvitationbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                pl: { lg: 3, xs: 1 },
                pb: { lg: 1, xs: 2 },
                pt: { lg: 3, xs: 2 },
                borderRadius: "15px",
              }}
            >
              <img src={ConfirmeInvitationIcon} />
              <Grid sx={{ display: "flex", my: { xs: 1, lg: 0 } }}>
                <Typography sx={{ fontSize: { md: "15px", xs: "11px" } }}>
                  {t("dashboard.total")} {t("dashboard.completedorders")}
                </Typography>
                <PopoverHover text="dashboard.confirmed_popover" />
              </Grid>
              <Typography sx={{ fontSize: { md: "48px", xs: "14px" } }}>
                {/* {Number(
                  orders?.confirmed?.length ? orders?.confirmed?.length : 0
                )} */}
                {Invitations?.confirmed}
              </Typography>
              <Grid
                lg={4}
                xs={6}
                sx={{ float: "right", mt: "-20%", mr: { lg: 3, xs: 1 } }}
              >
                <Typography
                  sx={{
                    fontSize: { md: "12px", xs: "8px" },
                    color: "#227438",
                    px: 1,
                    borderRadius: "15px",
                    py: 0.5,
                    backgroundColor: "rgba(138, 197, 62, 0.60)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.44 4.965C16.3638 4.78174 16.2182 4.63611 16.035 4.56C15.9448 4.52157 15.848 4.50118 15.75 4.5H12C11.801 4.5 11.6103 4.57902 11.4696 4.71967C11.329 4.86032 11.25 5.05109 11.25 5.25C11.25 5.44891 11.329 5.63968 11.4696 5.78033C11.6103 5.92098 11.801 6 12 6H13.9425L9.74995 10.1925L7.28245 7.7175C7.21273 7.6472 7.12978 7.59141 7.03839 7.55333C6.94699 7.51526 6.84896 7.49565 6.74995 7.49565C6.65095 7.49565 6.55292 7.51526 6.46152 7.55333C6.37013 7.59141 6.28718 7.6472 6.21745 7.7175L1.71745 12.2175C1.64716 12.2872 1.59136 12.3702 1.55329 12.4616C1.51521 12.553 1.49561 12.651 1.49561 12.75C1.49561 12.849 1.51521 12.947 1.55329 13.0384C1.59136 13.1298 1.64716 13.2128 1.71745 13.2825C1.78718 13.3528 1.87013 13.4086 1.96152 13.4467C2.05292 13.4847 2.15095 13.5043 2.24995 13.5043C2.34896 13.5043 2.44699 13.4847 2.53839 13.4467C2.62978 13.4086 2.71273 13.3528 2.78245 13.2825L6.74995 9.3075L9.21745 11.7825C9.28718 11.8528 9.37013 11.9086 9.46152 11.9467C9.55292 11.9847 9.65095 12.0043 9.74995 12.0043C9.84896 12.0043 9.94699 11.9847 10.0384 11.9467C10.1298 11.9086 10.2127 11.8528 10.2825 11.7825L15 7.0575V9C15 9.19891 15.079 9.38968 15.2196 9.53033C15.3603 9.67098 15.551 9.75 15.75 9.75C15.9489 9.75 16.1396 9.67098 16.2803 9.53033C16.4209 9.38968 16.5 9.19891 16.5 9V5.25C16.4988 5.15199 16.4784 5.05517 16.44 4.965Z"
                      fill="#227438"
                    />
                  </svg>{" "}
                  7.5%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button1
            btnname="+Create Manual Order"
            variant="outlined"
            className="Orangebtn"
            sx={{
              fontSize: { lg: '16px', xs: '12px', sm: '16px' },
              textTransform: 'none',
              width: { lg: '255px', xs: '140px', sm: '255px' },
              height: { lg: '40px', xs: '32px', sm: '40px' },
              border: {
                xs: '1px solid ',
                md: '2px solid ',
                sm: '2px solid ',
              },
              borderRadius: '7px',
              color: '#ffffff',
              borderColor: '',
              '&:hover': {
                backgroundColor: '#7cb74a',
                color: 'white',
                border: '1px solid #7cb74a',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button1
            btnname="Generate Milage Report"
            variant="outlined"
            className="Orangebtn"
            sx={{
              fontSize: { lg: '16px', xs: '12px', sm: '16px' },
              textTransform: 'none',
              width: { lg: '255px', xs: '140px', sm: '255px' },
              height: { lg: '40px', xs: '32px', sm: '40px' },
              border: {
                xs: '1px solid ',
                md: '2px solid ',
                sm: '2px solid ',
              },
              borderRadius: '7px',
              color: '#ffffff',
              borderColor: '',
              '&:hover': {
                backgroundColor: '#7cb74a',
                color: 'white',
                border: '1px solid #7cb74a',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "end",
          alignItems: "end",
        }}
      >
        {/* <Grid
          item
          style={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column", // for screens below the 'sm' breakpoint
                md: "row", // for screens above the 'md' breakpoint
              },
            }}
          >
            <Grid sx={{}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "center", xs: "start" },
                  alignItems: "center",
                  marginRight: 1,
                }}
              >
                <Checkbox
                  id="upcommingjobs"
                  checked={
                    dateFilter != null &&
                      dayjs(dateFilter).isSame(dayjs(), "day")
                      ? true
                      : false
                  }
                  onClick={(e) => filterUpcommingJobs(e)}
                  sx={{}}
                ></Checkbox>
                <label htmlFor="upcommingjobs">
                  <Typography>Upcomming</Typography>
                </label>
              </Box>
            </Grid>

            <LocalizationProvider
              sx={{ display: "flex" }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                value={dateFilter}
                onChange={(date) => {
                  if (date) {
                    const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");

                    setDateFilter(formattedDate);
                  }
                }}
                sx={{ mr: { md: 1, xs: 0 } }}
              ></DatePicker>
              <TimePicker
                sx={{ mt: { md: 0, xs: 1 } }}
                value={timeFilter}
                onChange={(time) => {
                  if (time) {
                    const formattedDate = time.format("HH:mm:ss");
                    setTimeFilter(formattedDate);
                  }
                }}
                use12Hours={false}
                placeholder="HH:MM:SS"
              ></TimePicker>
              <Button
                onClick={() => {
                  setDateFilter(null);
                  setTimeFilter(null);
                  updateVisibleOrders();
                }}
                sx={{ cursor: "pointer", color: "#F26F21" }}
              >
                Clear Filters
              </Button>
            </LocalizationProvider>
          </Box>
        </Grid> */}

        <Grid item>
          <select
            onChange={(e) => changeOrdersFilter(e)}
            value={orderfilter}
            name="cars"
            id="cars"
            style={{
              borderColor: "#F26F21",
              color: "#F26F21",
              padding: "5px",
              margin: "5px",
              borderRadius: "6px",
            }}
          >
            <option value="day">{t("dashboard.today")}</option>
            <option value="week">{t("dashboard.weekly")}</option>
            <option value="month">{t("dashboard.monthly")}</option>
          </select>
        </Grid>
      </Grid>
      <Box sx={{ typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ display: "flex" }}>
            <TabList
              textColor="#6A9B41"
              indicatorColor="#6A9B41"
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{}}
            >
              <Tab
                icon={<FormatListBulletedIcon />}
                iconPosition="start"
                label={t("listtab.list")}
                value="1"
                sx={{
                  fontSize: { lg: "16px", xs: "12px" },
                  textTransform: "capitalize",
                  fontWeight: 500,
                  color: "#545454", // Default text color
                  "&:hover": {
                    color: "#6A9B41",
                    fontWeight: 500, // Text color on hover
                    "& .MuiSvgIcon-root": {
                      stroke: "#6A9B41", // Icon color on hover
                    },
                    "&::after": {
                      backgroundColor: "#6A9B41", // Underline color on hover
                    },
                  },
                  "&.Mui-selected": {
                    color: "#6A9B41",
                    fontWeight: 500, // Text color when active
                    "& .MuiSvgIcon-root": {
                      stroke: "#6A9B41", // Icon color when active
                    },
                    "&::after": {
                      backgroundColor: "#6A9B41", // Underline color when active
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: "50%",
                    bottom: "0",
                    transform: "translateX(-50%)",
                    width: "100%",
                    height: "2px",
                    backgroundColor: "", // Set the underline color to green
                  },
                }}
              />
              <Tab
                icon={<DateRangeIcon />}
                iconPosition="start"
                label={t("listtab.calender")}
                value="2"
                sx={{
                  fontSize: { lg: "16px", xs: "12px" },
                  textTransform: "capitalize",
                  fontWeight: 500,
                  color: "#545454", // Default text color
                  "&:hover": {
                    color: "#6A9B41",
                    fontWeight: 500, // Text color on hover
                    "& .MuiSvgIcon-root": {
                      stroke: "#6A9B41", // Icon color on hover
                    },
                    "&::after": {
                      backgroundColor: "#6A9B41", // Underline color on hover
                    },
                  },
                  "&.Mui-selected": {
                    color: "#6A9B41",
                    fontWeight: 500, // Text color when active
                    "& .MuiSvgIcon-root": {
                      stroke: "#6A9B41", // Icon color when active
                    },
                    "&::after": {
                      backgroundColor: "#6A9B41", // Underline color when active
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: "50%",
                    bottom: "0",
                    transform: "translateX(-50%)",
                    width: "100%",
                    height: "2px",
                    backgroundColor: "", // Set the underline color to green
                  },
                }}
              />
              <Tab
                icon={<SatelliteOutlinedIcon />}
                iconPosition="start"
                label={t("listtab.map")}
                value="3"
                sx={{
                  fontSize: { lg: "16px", xs: "12px" },
                  textTransform: "capitalize",
                  fontWeight: 500,
                  color: "#545454", // Default text color
                  "&:hover": {
                    color: "#6A9B41",
                    fontWeight: 500, // Text color on hover
                    "& .MuiSvgIcon-root": {
                      stroke: "#6A9B41", // Icon color on hover
                    },
                    "&::after": {
                      backgroundColor: "#6A9B41", // Underline color on hover
                    },
                  },
                  "&.Mui-selected": {
                    color: "#6A9B41",
                    fontWeight: 500, // Text color when active
                    "& .MuiSvgIcon-root": {
                      stroke: "#6A9B41", // Icon color when active
                    },
                    "&::after": {
                      backgroundColor: "#6A9B41", // Underline color when active
                    },
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: "50%",
                    bottom: "0",
                    transform: "translateX(-50%)",
                    width: "100%",
                    height: "2px",
                    backgroundColor: "", // Set the underline color to green
                  },
                }}
              />
            </TabList>
          </Box>
          <DashboardCard hasSecondButton={true} />
          <DashboardCard hasSecondButton={false} />
          <TabPanel value="1" sx={{ p: 0 }}>
            <ListTab />
          </TabPanel>
          <TabPanel value="2">
            <CalenderTab />
          </TabPanel>
          <TabPanel value="3">
            {/* <Map apiKey="AIzaSyCi76nnMh57AEohyRP5kQU2SpH6mMpf3y0" /> */}
            <Map />
          </TabPanel>
        </TabContext>
      </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
