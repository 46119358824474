import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { borderRadius, Container, fontSize, fontWeight, height, textTransform, width } from '@mui/system';
import Button from '../Components/Button'
import { Link } from 'react-router-dom';

const ImageText = ({ Image, Heading, Text, mainhead, hightlighted, hei, coloredWord }) => {
  return (
    <div>
      <Box
        sx={{
          height: { xs: 'auto', md: { hei } },
          background: "#fff3ec",
        }}
      >


        <Container>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flexEnd' }, alignItems: 'center', }}>
              <Typography sx={{ fontSize: { xs: '36px', md: '40px' }, color: '#000000', fontWeight: 'bolder', textAlign: 'center', padding: { xs: '5px 0px', md: '20px 0px' } }}> <span style={{ color: '#F26F21' }}>{hightlighted}</span> {mainhead}  </Typography>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: { md: 'center', xs: 'flex-end' }, alignItems: 'center' }}>
              <Box component="img" src={Image} alt="Image" sx={{ height: { xs: 'auto', md: '461px', sm: 'auto' }, width: { xs: '100%', md: 'auto', sm: '80%' } }} />
            </Grid>

            <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left', marginTop: { md: '-20px', xs: '0px' } }}>
              <Typography
                sx={{ padding: { xs: '20px 0px', md: '0px' }, fontFamily: 'inter', fontSize: { xs: '36px', md: '40px', sm: '36px' }, letterSpacing: { md: '1.4px', xs: 'auto' }, color: '#000000', textAlign: 'left', lineHeight: { xs: '100%', md: '130%' }, fontWeight: { xs: 600, md: 700 } }}
                variant=""
              >
                {Heading}<span style={{ color: '#F26F21' }}>{coloredWord}</span>
              </Typography>
              <Typography
                sx={{ fontFamily: 'inter', fontSize: { XS: '20px', md: '24px' }, color: '#000000', textAlign: 'left', lineHeight: '150%', fontWeight: '100', marginBottom: '25px' }}
                variant="body1"
              >
                {Text}
              </Typography>
              <Link to="/Signup" style={{textDecoration: 'none'}}>
                <Button
                  btnname='Register Now'
                  variant='Contained'
                  className='Orangebtn'
                  sx={{ fontSize: { xs: '18px', md: '24px' }, width: { xs: '100%', md: '50%' }, fontWeight: 'bolder', borderRadius: '15px', textTransform: 'none', color: 'white', height: { xs: '50px', md: '70px' }, marginBottom: '30px' }}
                />
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default ImageText;
