// import * as React from 'react';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { useTranslation } from "react-i18next";

// export default function CheckboxLabel({ label, sx, color }) {
//   const [t, i18n] = useTranslation("global");
//   const labelProps = { inputProps: { 'aria-label': label } };

//   return (
//     <FormGroup>
//       <FormControlLabel
//         control={
//           <Checkbox
//             {...labelProps}
//             sx={{
//               color: color ? color : "#F6B980",
//               '&.Mui-checked': {
//                 color: color ? color : "#F6B980",
//               },
//             }}
//           />
//         }
//         label={t(label)}
//         className='checkbox'
//         sx={{ fontSize: { xs: "12px", md: "17px", ...sx} }}
//       />
//     </FormGroup>
//   );
// }

import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

export default function CheckboxLabel({ label, sx, color, onClick ,checked}) {
  const [t, i18n] = useTranslation("global");
  const labelProps = { inputProps: { "aria-label": label } };

  return (
    <FormGroup>
      <FormControlLabel
        onClick={onClick}
        control={
          <Checkbox
            // {...labelProps}
            checked={checked}
            sx={{
              color: color ? color : "#F6B980",
              "&.Mui-checked": {
                color: color ? color : "#F6B980",
              },
            }}
          />
        }
        label={t(label)}
        className="checkbox"
        sx={{
          fontSize: { xs: "12px", md: "17px", ...sx },
          ".MuiFormControlLabel-label": {
            // Target the label text
            color: color ? "black" : "#F6B980", // Apply the color to the label text
          },
        }}
      />
    </FormGroup>
  );
}
