import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../Requests/Requests";

const addAppleAccount = createAsyncThunk(
  "appleAccount/add",
  async (data, rejectWithValue) => {
    try {
      const formData = new FormData();
      formData.append("grant_id", data.grant_id);
      formData.append("email", data.email);
      formData.append("signing_agency_id", data.signing_agency_id);
      return await postRequest("/add/appleAccount", formData);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const getAppleAccounts = createAsyncThunk(
  "appleAccount/add",
  async (data, rejectWithValue) => {
    try {
      return await getRequest("/get/appleAccount");
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export { addAppleAccount };
