


import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";

const ManageJobSlider = ({ slides }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false, // Remove next and previous buttons
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4, // Show 4 slides in a row on desktop
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 slide per row on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Box
            key={index}
            p={2}
            textAlign="center"
            sx={{
              backgroundColor: "green", // Set background color to green
              color: "white", // Text color to white for contrast
            }}
          >
            <Typography variant="h6" gutterBottom>
              {slide.heading}
            </Typography>
            <Typography variant="body1">{slide.text}</Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ManageJobSlider;
