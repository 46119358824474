import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

const TraditionalMethod = ({ number, Icon, Heading, Text, isOrderedList }) => {
  return (
    <Grid container spacing={4} sx={{ paddingLeft: 0 }}>
      {/* Timeline Section */}
      <Grid item xs={12} md={4} sx={{ paddingLeft: 0 }}>
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  bgcolor: '#6a9b41',
                  width: { xs: '35px', md: '45px' },
                  height: { xs: '35px', md: '45px' },
                  boxShadow: 0,
                  color: 'white',
                  fontSize: { xs: '24px', md: '32px' },
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 0,
                  marginTop: 2, // Add top margin to the icon
                }}
              >
                {number}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  backgroundColor: '#6a9b41',
                  height: { xs: '150px', md: '316.16px' },
                  width: '2px',
                }}
              />
              <Box
                sx={{
                  backgroundColor: '#6a9b41',
                  height: {md: '60px', xs: '60px'}, // Height of the bold section
                  width: {md: '6px', xs: '6px'},
                  position: 'absolute',
                  top: {md: '30%', xs: '30%'}, // Center it vertically
                  left: {md: 'auto', xs: 'auto'}, // Align with the left side
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ paddingLeft: 0, width: 'auto' }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                sx={{
                  width: { xs: '230px', md: '220px' },
                }}
              >
                <Box
                  component="img"
                  src={Icon}
                  alt={Icon}
                  sx={{
                    width: 'auto',
                    height: { xs: '70px', md: '90px' },
                    mb: 2,
                    paddingTop: '90px'
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '16px' },
                    fontWeight: '600',
                    color: 'black',
                    display: 'flex',
                    alignItems: {xs: 'center', md: 'flex-start'}, // Align text and dot
                  }}
                >
                  <span style={{ fontSize: '24px', marginRight: '8px' }}>•</span> {/* Dot */}
                  {Heading}
                </Typography>
                
                {/* Conditionally render ordered list or plain text */}
                {isOrderedList ? (
                  <ol>
                    {Text.map((item, index) => (
                      <li key={index} style={{marginLeft: '30px'}}>
                        <Typography
                          sx={{
                            fontSize: { lg: '18px', xs: '14px' },
                            fontWeight: '400',
                            color: 'black',
                            width: { xs: '180px', md: '280px' },
                            marginLeft: {xs: 'auto', md: '10px'}
                          }}
                        >
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <Typography
                    sx={{
                      fontSize: { lg: '18px', xs: '14px' },
                      fontWeight: '200',
                      color: 'black',
                      width: { xs: '180px', md: '280px' },
                      marginLeft: {xs: '20px', md: '20px'}
                    }}
                  >
                    {Text}
                  </Typography>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>
    </Grid>
  );
};

export default TraditionalMethod;
