import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import DeclineListBox from "../../../Components/DeclineListBox";
import { useTranslation } from "react-i18next";

const DeclineList = ({ Declined }) => {
  const [t, i18n] = useTranslation("global");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  // Logic to paginate the Declined items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Declined?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {currentItems &&
        currentItems.map((item, index) => (
          <DeclineListBox
            key={index}
            order={item}
            recieveddate={item.created_at}
            recievedtime={item.closing_time}
            orderdate={item.closing_date}
            location={item.closing_city}
            status={item.closing_status}
          />
        ))}
      {/* Pagination controls */}
      <div style={{ marginTop: "20px" }}>
        {Declined &&
          Array.from(
            { length: Math.ceil(Declined.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                style={{
                  margin: "0 5px",
                  padding: "5px 10px",
                  cursor: "pointer",
                  backgroundColor: currentPage === index + 1 ? "#ccc" : "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                {index + 1}
              </button>
            )
          )}
      </div>
    </div>
  );
};

export default DeclineList;
