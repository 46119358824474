import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../Requests/Requests";
import { Typography } from "@mui/material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import Cookie from "js-cookie";
import { updateUser } from "../../Redux/Slices/userSlice";
import toast, { Toaster } from "react-hot-toast";
import AccountVarification from "./AccountVarification";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const REACT_APP_GOSIGNING_FRONTEND = process.env.REACT_APP_GOSIGNING_FRONTEND;

export default function Outlook({
  setLoading,
  authFlowType,
  setCalenderAccountAdded,
  onEmailSelect,
  setFormData,
  formData,
  index,
  activity,
  setEmail,
  AccountVarifications,
  seterrors,
  setShowAccountVarificationModal,
  showAccountVarificationModal,
}) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user);

  const dispatch = useDispatch();

  // Function to generate a random code verifier (43 to 128 characters)
  function generateCodeVerifier() {
    const randomArray = new Uint8Array(32); // 32 bytes generates a string of ~43 characters
    window.crypto.getRandomValues(randomArray);
    return base64URLEncode(randomArray);
  }

  // Function to hash the code verifier using SHA-256 and then base64 encode it
  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return base64URLEncode(new Uint8Array(hashBuffer));
  }

  // Function to URL-safe Base64 encode a string
  function base64URLEncode(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, arrayBuffer))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  const clientId = "e300ce34-2d3a-45ea-afba-ff68965a608f";
  const redirectUri = "http://localhost:3000/outlook/callback"; // Redirect URL should match what you set in Azure Portal
  const scopes =
    "openid profile email Mail.Read Mail.Send User.Read Calendars.Read Calendars.ReadBasic Calendars.ReadWrite";

  const handleOAuthCallback = (event) => {
    if (event.origin !== "http://localhost:3000" || !event.data?.code) {
      return;
    }

    const authorizationCode = event.data.code;
    const codeVerifier = localStorage.getItem("code_verifier");
    if (authorizationCode && codeVerifier) {
      getToken(authorizationCode, codeVerifier);
    }
  };

  const handleOutlookLogin = async () => {
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);
    localStorage.setItem("code_verifier", verifier);

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_mode=query&scope=${encodeURIComponent(
      scopes
    )}&code_challenge=${challenge}&code_challenge_method=S256`;

    const authWindow = window.open(authUrl, "_blank", "width=500,height=600");
    window.addEventListener("message", handleOAuthCallback);
  };

  const getToken = async (code, codeVerifier) => {
    const tokenUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/token";
    const body = new URLSearchParams({
      client_id: clientId,
      scope:
        "openid profile email Mail.Read Mail.Send Calendars.Read Calendars.ReadBasic Calendars.ReadWrite",
      code: code,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
      code_verifier: codeVerifier,
    });

    try {
      const response = await axios.post(tokenUrl, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const tokens = response.data;
      if (tokens) {
        const { access_token, refresh_token, expires_in } = tokens;
        getUserInfo(access_token, refresh_token);
      }
    } catch (error) {
      return null;
    }
  };
  const getUserInfo = async (accessToken, refresh_token) => {
    try {
      const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userinfo = response.data;
      localStorage.setItem("userinfo", JSON.stringify(userinfo));

      if (authFlowType == "signin") {
        if (userinfo && authFlowType && authFlowType == "signin") {
          const email = userinfo.mail;
          const password = userinfo.id;
          setEmail(email);
          const formdata = new FormData();
          formdata.append("email", email);
          formdata.append("password", password);
          setLoading(true);
          postRequest("/login", formdata)
            .then((result) => {
              setLoading(false);
              localStorage.removeItem("userinfo");
              localStorage.removeItem("authFlowType");
              if (result.error) {
                if (result.error.email == "Email not verified!") {
                  dispatch(updateUser(result.data.user));
                  Cookie.set("user_email", result.data.user.email);
                  Cookie.set(
                    "profile_image_url",
                    result.data.user.profile_image_url
                  );
                  Cookie.set("access_token", result.data.token, {
                    expires: 14,
                  });
                  toast.error("Your email is not verified yet!");
                  AccountVarifications();
                  return;
                }
                seterrors(result.error);
              } else if (result.data.message == "Preferences not set!") {
                dispatch(updateUser(result.data.user));
                if (result.data.token != null) {
                  Cookie.set("access_token", result.data.token, {
                    expires: 14,
                  });
                  Cookie.set("user_email", result.data.user.email, {
                    expires: 14,
                  });
                }
                navigate(
                  `/accountconfirmation/subscription/${result.data.user?.id}`
                );
              } else {
                dispatch(updateUser(result.data.user));
                Cookie.set("access_token", result.data.token, { expires: 14 });
                Cookie.set("user_email", result.data.user.email, {
                  expires: 14,
                });
                sessionStorage.setItem("signin", false);
                navigate("/Dashboard");
              }
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
            });
        }
      } else if (authFlowType == "signup") {
        if (userinfo) {
          const firstName = userinfo.givenName;
          const lastName = userinfo.surname;
          const email = userinfo.mail;
          const password = userinfo.id;
          setEmail(email);

          const myHeaders = new Headers();
          myHeaders.append(
            `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
            `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
          );
          const formdata = new FormData();
          formdata.append("first_name", firstName);
          formdata.append("last_name", lastName);
          formdata.append("email", email);
          formdata.append("password", password);
          formdata.append("isApple", false);

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          };
          setLoading(true);
          fetch(`${apiEndpoint}/register`, requestOptions)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else {
                return { error: { message: "Unauthorized" } };
              }
            })
            .then((result) => {
              localStorage.removeItem("userinfo");
              localStorage.removeItem("authFlowType");
              setLoading(false);
              if (result.error) {
                toast.error(result.error.email);
              } else {
                dispatch(updateUser(result.data.user));
                if (result.data.token != null) {
                  Cookie.set("access_token", result.token);
                }
                Cookie.set("user_email", result.data.user.email);
                AccountVarifications();
              }
            })
            .catch((error) => {
              setLoading(false);
            });
        }
      } else if (authFlowType == "calendar") {
        if (activity == "mail") {
          let mailAccounts = [...formData.mail_accounts];
          mailAccounts[index] = {
            ...mailAccounts[index],
            account_type: "outlook",
            account_email: userinfo?.mail,
            refresh_token: refresh_token,
          };

          setFormData((prevData) => ({
            ...prevData,
            mail_accounts: mailAccounts,
          }));
        } else if (activity == "calendar") {
          onEmailSelect(userinfo?.mail, refresh_token);
        }
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    localStorage.setItem("authFlowType", authFlowType);
    return () => {
      window.removeEventListener("message", handleOAuthCallback);
    };
  }, []);

  return (
    <>
      {activity == "calendar" && (
        <Typography
          // className="greybtn"
          onClick={handleOutlookLogin}
          // sx={{
          //   width: "100%",
          //   py: "10px",
          //   fontSize: { xs: "12px", md: "20px" },
          //   borderRadius: "8px",
          // }}
          // size="large"
          // variant="contained"
          // startIcon={
          //   <img
          //     src={GoogleIcon}
          //     alt="Google Icon"
          //     style={{ width: "28px", height: "28px" }}
          //   />
          // }
          sx={{
            fontSize: "14px",
            color: "#F26F21",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          Add
        </Typography>
      )}

      {activity == "mail" && (
        <MicrosoftIcon
          onClick={handleOutlookLogin}
          sx={{ cursor: "pointer", ml: 2 }}
        />
      )}
      {activity == "signin" && (
        <Box sx={{ "& button": { my: 1 } }}>
          <Button
            className="greybtn"
            onClick={handleOutlookLogin}
            sx={{
              width: "100%",
              py: "10px",
              fontSize: { xs: "12px", md: "20px" },
              borderRadius: "8px",
            }}
            size="large"
            variant="contained"
            // startIcon={
            //   <img
            //     src={GoogleIcon}
            //     alt="Google Icon"
            //     style={{ width: "28px", height: "28px" }}
            //   />
            // }
          >
            Signin with Microsoft
          </Button>
        </Box>
      )}
      {activity == "signup" && (
        <Box sx={{ "& button": { my: 1 } }}>
          <Toaster />
          <Button
            className="greybtn"
            onClick={handleOutlookLogin}
            sx={{
              width: "100%",
              py: "10px",
              fontSize: { xs: "12px", md: "20px" },
              borderRadius: "8px",
            }}
            size="large"
            variant="contained"
            // startIcon={
            //   <img
            //     src={GoogleIcon}
            //     alt="Google Icon"
            //     style={{ width: "28px", height: "28px" }}
            //   />
            // }
          >
            Register with Microsoft
          </Button>

          <AccountVarification
            open={showAccountVarificationModal}
            handleClose={() => setShowAccountVarificationModal(false)}
          />
        </Box>
      )}
    </>
  );
}
