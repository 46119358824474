import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import AuthLayout from "../../Layouts/AuthLayout";
import ImgWithPopup from "../../Components/ImgWithPopup";
import SiteLogo from "../../Components/Logo";
import SigninAvatar from "../../Assets/Images/Sigininavatar.png";
import Password from "../../Components/Password";
import CheckboxLabel from "../../Components/Checkbox";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import ForgetPassword from "./ForgetPassword";
import NewPasswordSuccessful from "./NewPasswordSuccessful";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { postRequest } from "../../Requests/Requests";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "19px",
  padding: theme.spacing(1),
}));

export default function ResetPassword() {
  const [t, i18n] = useTranslation("global");
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const NewPassword = () => {
    setShowNewPasswordModal(true);
  };

  const navigate = useNavigate();

  let { id, token } = useParams();
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, seterrors] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!regex.test(passwordValue)) {
      seterrors("Password must meet criteria!");
      return;
    }

    if (passwordValue != confirmPasswordValue) {
      seterrors("Confirm password must match!");
      return;
    }

    setLoading(true);

    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("token", token);
    formdata.append("password", passwordValue);

    seterrors(null);
    postRequest("/reset/password/confirm", formdata)
      .then((result) => {
        if (result?.data?.message == "password updated") {
          // navigate("/Signin");
          setLoading(false);
          NewPassword();
        } else if (result?.error) {
          toast.error(result?.error?.error);
          seterrors(result?.error?.error);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const theme = useTheme();
  return (
    <AuthLayout>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <Container zIndex sx={{ py: { lg: 5 } }}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ maxHeight: "100%", minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <SiteLogo />
            <Grid sx={{ p: 0, display: { xs: "none", md: "block" } }}>
              <ImgWithPopup
                charcterStyle={{ maxWidth: "100%" }}
                style={{ bottom: "-8vh" }}
                img={SigninAvatar}
                msg="reset_pass.msg"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xlg={6}
            sx={{ my: { lg: "auto" }, position: "relative" }}
          >
            <Item
              sx={{
                // py: 3,
                px: { lg: 4, xs: 2 },
                boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: { md: 700, xs: 600 },
                  fontSize: { md: "38px", xs: "22px" },
                  my: 3,
                }}
              >
                {t("reset_pass.title")}
              </Typography>

              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%", // Set the width of the form
                }}
              >
                <Password
                  id="pasword"
                  name="password"
                  label={t("form.pass")}
                  onPasswordChange={(passwordValue) =>
                    setPasswordValue(passwordValue)
                  }
                />
                <Password
                  id="confirm_pasword"
                  name="onfirm_pasword"
                  label={t("form.confirmpass")}
                  onPasswordChange={(confirmPasswordValue) =>
                    setConfirmPasswordValue(confirmPasswordValue)
                  }
                />
                <Button
                  btnname="button.continue"
                  type="submit"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    py: "10px",
                    my: 3,
                    fontSize: { lg: "22px", xs: "12px" },
                  }}
                />
                {errors && (
                  <Typography sx={{ color: "red" }}>{errors}</Typography>
                )}
              </form>
            </Item>
          </Grid>
        </Grid>
        <NewPasswordSuccessful
          open={showNewPasswordModal}
          handleClose={() => setShowNewPasswordModal(false)}
        />
      </Container>
    </AuthLayout>
  );
}
