import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import {
  Box,
  Grid,
  Typography,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SettingSidebar from "../../Components/SettingSidebar";
import { Link, useLocation } from "react-router-dom";
import Button from "../../Components/Button";
import Subscriptionimg from "../../Assets/Images/subscriptionimgupdatedone.png";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookie from "js-cookie";
import { setUserData, updateUser } from "../../Redux/Slices/userSlice";
import toast from "react-hot-toast";
import UnsubscribeModal from "../Subscription/UnsubscribeModal";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const Subscription = () => {
  const [t, i18n] = useTranslation("global");
  const subscription = useSelector((state) => state.user.subscription);
  const user = useSelector((state) => state.user.user);

  const [loading, setloading] = useState(false);

  const [open, setopen] = useState(false);

  const dispatch = useDispatch();

  let unsubscribe = () => {
    setloading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", user?.email);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    fetch(`${apiEndpoint}/subscription/unsubscribe`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        // console.log(data);
        if (data.status == 1) {
          setloading(false);
          // dispatch(updateUser(data.user));
          dispatch(
            setUserData({
              user: data.user,
              subscription: null,
              calendar_accounts: null,
            })
          );
          // navigate("/Dashboard");
          // toast.success(data?.message);
        } else {
          setloading(false);
          // toast.error(data?.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  let handleClose = (result) => {
    setopen(false);
    if (result) {
      unsubscribe();
    }
  };

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            sx={{ display: { xs: "none", lg: "block", position: "relative" } }}
          >
            <SettingSidebar />
            {!subscription && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                  zIndex: 1,
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={9} sx={{}}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                color: "black",
                visibility: "hidden",
              }}
            >
              Settings
            </Typography>
            <Grid
              item
              xs={11}
              sx={{
                justifyContent: "center",
                mx: "auto",
                boxShadow: { lg: 1 },
                p: { lg: 4 },
                borderRadius: 2,
                // border: "solid 2px #000000",
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "24px", xs: "18px" },
                  fontWeight: 800,
                  color: "#545454",
                  py: { lg: 2 },
                }}
              >
                {t("subscription.title")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "24px", xs: "18px" },
                  fontWeight: 800,
                  color: "black",
                  textAlign: { lg: "center" },
                  mt: { lg: 3 },
                }}
              >
                {t("subscription.upgrade_plan")}
              </Typography>

              {subscription && (
                <>
                  <Typography
                    sx={{
                      fontSize: { lg: "18px", xs: "12px" },
                      fontWeight: 800,
                      color: "black",
                      textAlign: { lg: "center" },
                    }}
                  >
                    {t("subscription.enjoy")}{" "}
                    <span style={{ color: "#F26F21" }}>
                      {" "}
                      {subscription?.plan == "free" && (
                        <>{t("subscription.free_plan")}</>
                      )}
                      {subscription?.plan == "monthly" && (
                        <>{t("subscription.monthly_plan")}</>
                      )}
                      {subscription?.plan == "yearly" && (
                        <>{t("subscription.yearly_plan")}</>
                      )}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { lg: "18px", xs: "12px" },
                      fontWeight: 800,
                      color: "black",
                      textAlign: { lg: "center" },
                    }}
                  >
                    {t("subscription.plan_expires")} {subscription?.expiry}
                  </Typography>
                </>
              )}
              <Box sx={{ width: "100%", mt: { lg: 3, xs: 2 } }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} lg={7}>
                    <Box
                      sx={{
                        // backgroundColor: "#83C066",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 3,
                      }}
                    >
                      <img
                        src={Subscriptionimg}
                        className="img-mobile img-desktop"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <List sx={{ px: "auto" }}>
                      <ListItem>
                        <ListItemIcon sx={{ px: 1 }}>
                          <CheckIcon style={{ color: "#F26F21" }} />
                        </ListItemIcon>
                        <ListItemText primary="Lorem Ipsume" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ px: 1 }}>
                          <CheckIcon style={{ color: "#F26F21" }} />
                        </ListItemIcon>
                        <ListItemText primary="Lorem Ipsume" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ px: 1 }}>
                          <CheckIcon style={{ color: "#F26F21" }} />
                        </ListItemIcon>
                        <ListItemText primary="Lorem Ipsume" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ px: 1 }}>
                          <CheckIcon style={{ color: "#F26F21" }} />
                        </ListItemIcon>
                        <ListItemText primary="Lorem Ipsume" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon sx={{ px: 1 }}>
                          <CheckIcon style={{ color: "#F26F21" }} />
                        </ListItemIcon>
                        <ListItemText primary="Lorem Ipsume" />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "100%", mt: { lg: 3 } }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item lg={7} xs={6}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: { md: 900, xs: 500 },
                          fontSize: { md: "35px", xs: "22px" },
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        $9.99{" "}
                        <span style={{ fontSize: "14px" }}>
                          USD/{t("subscription.month")}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: { md: 600, xs: 500 },
                          fontSize: { md: "22px", xs: "18px" },
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {t("subscription.monthly")}
                      </Typography>
                      <Grid sx={{ display: "flex", justifyContent: "center" }}>
                        {subscription && subscription?.plan == "monthly" ? (
                          <Button
                            onClick={() => setopen(true)}
                            btnname="subscription.cancel"
                            type="submit"
                            variant="contained"
                            className="Orangebtn"
                            sx={{
                              py: "5px",
                              px: "25px",
                              fontSize: { lg: "22px", xs: "12px" },
                              textTransform: "none",
                            }}
                          />
                        ) : (
                          <Link
                            to={"/subscriptionaccount/monthly"}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              btnname="button.subscribe"
                              type="submit"
                              variant="contained"
                              className="Orangebtn"
                              sx={{
                                py: "5px",
                                px: "25px",
                                fontSize: { lg: "22px", xs: "12px" },
                                textTransform: "none",
                              }}
                            />
                          </Link>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: { md: 900, xs: 500 },
                          fontSize: { md: "35px", xs: "22px" },
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        $120.0{" "}
                        <span style={{ fontSize: "14px" }}>
                          USD/ {t("subscription.year")}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: { md: 600, xs: 500 },
                          fontSize: { md: "22px", xs: "18px" },
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        {t("subscription.yearly")}
                      </Typography>
                      <Grid sx={{ display: "flex", justifyContent: "center" }}>
                        {subscription && subscription?.plan == "yearly" ? (
                          <Button
                            onClick={() => setopen(true)}
                            btnname="subscription.cancel"
                            type="submit"
                            variant="contained"
                            className="Orangebtn"
                            sx={{
                              py: "5px",
                              px: "25px",
                              fontSize: { lg: "22px", xs: "12px" },
                              textTransform: "none",
                            }}
                          />
                        ) : (
                          <Link
                            to={"/subscriptionaccount/yearly"}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              btnname={
                                subscription?.plan == "monthly"
                                  ? "upgrade"
                                  : "button.subscribe"
                              }
                              type="submit"
                              variant="contained"
                              className="Orangebtn"
                              sx={{
                                py: "5px",
                                px: "25px",
                                fontSize: { lg: "22px", xs: "12px" },
                                textTransform: "none",
                              }}
                            />
                          </Link>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <UnsubscribeModal open={open} handleClose={handleClose} />
    </DashboardLayout>
  );
};

export default Subscription;
