
import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import VisibilityOff from "../Assets/Icons/visibility.svg";
import Visibility from "../Assets/Icons/Visibilityoff.svg";
import { useTranslation } from "react-i18next";
export default function Password({label, onPasswordChange, id, name}) {
  const [t, i18n] = useTranslation("global");
  const handleChange = (event) => {
    const passwordValue = event.target.value;
    // Call the callback function provided by the parent component
    if (onPasswordChange) {
      onPasswordChange(passwordValue);
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [validationError, setValidationError] = React.useState(null);

  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = (event) => {
    setIsInputFocused(false);
    validatePassword(event.target.value);
  };

  const validatePassword = (password) => {
    if (!password) {
      setValidationError("Password is required."); 
      return;
    }

    if (!regex.test(password)) {
      setValidationError("Password must be at least 8 characters, contain at least one number, and one special symbol one Capital and on Small letter.");
    } else {
      setValidationError(null);
    }
  };

  return (
    <div>
      <FormControl
        sx={{ my: 1, width: "25ch", width: "100%" }}
        variant="outlined"
        error={Boolean(validationError)}
      >
        <InputLabel htmlFor="outlined-adornment-password">{t(label)}</InputLabel>
        <OutlinedInput
onChange={handleChange}

        sx={{borderRadius:"8px"}}
          id={id}
          name={name}
          className="passwordtype"
          type={showPassword ? "text" : "password"}
          placeholder="********"
          required
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          label={label}
          endAdornment={
            isInputFocused && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <img src={VisibilityOff} alt="Visibility Off" />
                  ) : (
                    <img src={Visibility} alt="Visibility" />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }
        />
        {validationError && (
          <Box component="span" sx={{ color: 'red' }}>
            {validationError}
          </Box>
        )}
      </FormControl>
    </div>
  );
}
