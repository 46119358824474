import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Snackbar,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { Box } from "@mui/system";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const incomingNotificationType = [
  { label: "Invitation", value: "invitation" },
  { label: "Confirmation", value: "confirmation" },
];

const incomingNotificationSource = [
  { label: "SMS", value: "message" },
  { label: "Email", value: "email" },
];

const ScrapFieldOriginTypesMessage = [
  { label: "Message/sms", value: "message" },
  { label: "Browser", value: "browser" },
];

const ScrapFieldOriginTypesEmail = [
  { label: "Email content", value: "emailbody" },
  { label: "Email Attachment", value: "emailattachment" },
  { label: "Browser", value: "browser" },
];

const extractions = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Fee", value: "fee" },
  { label: "Address", value: "address" },
  { label: "Order Number", value: "orderno" },
  { label: "Zip", value: "zip" },
  { label: "Url", value: "url" },
];

const requiredFieldsIM = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Fee", value: "fee" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const requiredFieldsCM = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const requiredFieldsIE = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Fee", value: "fee" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const requiredFieldsCE = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const ScrapFieldOriginForm = ({ onClose }) => {
  const [scrapFieldOriginId, setScrapFieldOriginId] = useState(null);
  const [signingAgencyId, setSigningAgencyId] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [signingAgencies, setSigningAgencies] = useState([]);
  const [scrapFieldOrigins, setScrapFieldOrigins] = useState([]); // State to hold ScrapFieldOrigins
  const [filter, setFilter] = useState("");
  const [updated, setUpdated] = useState(false);

  const [iNT, setINT] = useState("");
  const [iNS, setINS] = useState("");

  const [extractionField, setExtractionField] = useState("");
  const [fieldorigin, setfieldorigin] = useState("");

  useEffect(() => {
    axios
      .get(`${apiEndpoint}/signing/agencies`)
      .then((response) => {
        setSigningAgencies(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching signing agencies:", error);
      });
  }, []);
  useEffect(() => {
    if (signingAgencyId) {
      axios
        .get(`${apiEndpoint}/scrap-fields-sources/agency/${signingAgencyId}`)
        .then((response) => {
          setScrapFieldOrigins(response.data);
        })
        .catch((error) => {
          console.error("Error fetching scrap field origins:", error);
          setError(true);
          setMessage("Failed to fetch scrap field origins.");
        });
    } else {
      setScrapFieldOrigins([]); // Clear scrap field origins if no agency is selected
    }
  }, [signingAgencyId, updated]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      field_name: extractionField,
      field_from: fieldorigin,
      signingagencyid: signingAgencyId,
      flowtype: iNT + iNS,
    };

    const apiCall = scrapFieldOriginId
      ? axios.put(
          `${apiEndpoint}/scrap-fields-sources/${scrapFieldOriginId}`,
          data
        )
      : axios.post(`${apiEndpoint}/scrap-fields-sources`, data);

    apiCall
      .then((response) => {
        setSuccess(true);
        setMessage(
          `ScrapFieldOrigin ${
            scrapFieldOriginId ? "updated" : "created"
          } successfully!`
        );
        setUpdated(!updated);
        resetForm();
      })
      .catch((error) => {
        console.error("Error saving scrap field origin:", error);
        setError(true);
        setMessage("Failed to save scrap field origin.");
      });
  };

  const resetForm = () => {
    setScrapFieldOriginId(null);
    setExtractionField("");
    setfieldorigin("");
    // setINT("");
    // setINS("");
  };

  const handleDelete = (id) => {
    axios
      .delete(`${apiEndpoint}/scrap-fields-sources/${id}`)
      .then(() => {
        setSuccess(true);
        setMessage("ScrapFieldOrigin deleted successfully!");
        setUpdated(!updated);
      })
      .catch((error) => {
        console.error("Error deleting scrap field origin:", error);
        setError(true);
        setMessage("Failed to delete scrap field origin.");
      });
  };

  const handleEdit = (origin) => {
    setExtractionField(origin.field_name);
    setfieldorigin(origin.field_from);
    setSigningAgencyId(origin.signingagencyid);
    setScrapFieldOriginId(origin.id);
    setINT(
      origin.flowtype.includes("invitation") ? "invitation" : "confirmation"
    );
    setINS(origin.flowtype.includes("message") ? "message" : "email");
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant="h6">
        {scrapFieldOriginId
          ? "Edit Scrap Field Source"
          : "Create Scrap Field Source"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid xs={12}>
          <TextField
            label="Select Signing Agency"
            select
            fullWidth
            margin="normal"
            value={signingAgencyId}
            onChange={(e) => setSigningAgencyId(e.target.value)}
            required
          >
            {signingAgencies.map((agency) => (
              <MenuItem key={agency.id} value={agency.id}>
                {agency.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Select Notification Type"
              select
              fullWidth
              margin="normal"
              value={iNT}
              onChange={(e) => setINT(e.target.value)}
              required
            >
              {incomingNotificationType.map((noti, index) => (
                <MenuItem key={index} value={noti.value}>
                  {noti.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Select Notification Source"
              select
              fullWidth
              margin="normal"
              value={iNS}
              onChange={(e) => setINS(e.target.value)}
              required
            >
              {incomingNotificationSource.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Select Field"
              select
              fullWidth
              margin="normal"
              required
              value={extractionField}
              name="field"
              onChange={(e) => setExtractionField(e.target.value)}
              sx={{ minWidth: "100px" }}
            >
              {extractions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="From where will you get that info (Field Source)"
              select
              fullWidth
              margin="normal"
              value={fieldorigin}
              onChange={(e) => setfieldorigin(e.target.value)}
            >
              {iNS == "message"
                ? ScrapFieldOriginTypesMessage.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))
                : ScrapFieldOriginTypesEmail.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
            </TextField>
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
        >
          {scrapFieldOriginId ? "Update" : "Create"}
        </Button>
        {String(iNT + iNS) == "invitationmessage" && (
          <>
            <Typography sx={{ my: 1 }}>
              Your Invitation message setup is missing important fields which
              are required by gosignings
            </Typography>
            <Box sx={{ my: 2, display: "flex" }}>
              {requiredFieldsIM.map((item) => {
                if (
                  !scrapFieldOrigins.some(
                    (f) =>
                      f.field_name == item.value &&
                      f.flowtype == String(iNT + iNS)
                  )
                ) {
                  return (
                    <>
                      <Typography sx={{ m: 1, color: "red" }}>
                        {item.value}
                      </Typography>
                    </>
                  );
                }
              })}
            </Box>
          </>
        )}

        {String(iNT + iNS) == "confirmationmessage" && (
          <>
            <Typography sx={{ my: 1 }}>
              Your Confirmation message setup is missing important fields which
              are required by gosignings
            </Typography>
            <Box sx={{ my: 2, display: "flex" }}>
              {requiredFieldsCM.map((item) => {
                if (
                  !scrapFieldOrigins.some(
                    (f) =>
                      f.field_name == item.value &&
                      f.flowtype == String(iNT + iNS)
                  )
                ) {
                  return (
                    <>
                      <Typography sx={{ m: 1, color: "red" }}>
                        {item.value}
                      </Typography>
                    </>
                  );
                }
              })}
            </Box>
          </>
        )}

        {String(iNT + iNS) == "invitationemail" && (
          <>
            <Typography sx={{ my: 1 }}>
              Your Invitation Email setup is missing important fields which are
              required by gosignings
            </Typography>
            <Box sx={{ my: 2, display: "flex" }}>
              {requiredFieldsIE.map((item) => {
                if (
                  !scrapFieldOrigins.some(
                    (f) =>
                      f.field_name == item.value &&
                      f.flowtype == String(iNT + iNS)
                  )
                ) {
                  return (
                    <>
                      <Typography sx={{ m: 1, color: "red" }}>
                        {item.value}
                      </Typography>
                    </>
                  );
                }
              })}
            </Box>
          </>
        )}

        {String(iNT + iNS) == "confirmationemail" && (
          <>
            <Typography sx={{ my: 1 }}>
              Your Confirmation Email setup is missing important fields which
              are required by gosignings
            </Typography>
            <Box sx={{ my: 2, display: "flex" }}>
              {requiredFieldsCE.map((item) => {
                if (
                  !scrapFieldOrigins.some(
                    (f) =>
                      f.field_name == item.value &&
                      f.flowtype == String(iNT + iNS)
                  )
                ) {
                  return (
                    <>
                      <Typography sx={{ m: 1, color: "red" }}>
                        {item.value}
                      </Typography>
                    </>
                  );
                }
              })}
            </Box>
          </>
        )}

        {scrapFieldOriginId && (
          <Button
            onClick={() => {
              setScrapFieldOriginId(null);
              resetForm();
            }}
            variant="contained"
            color="secondary"
            style={{ marginTop: 20, marginLeft: 10 }}
          >
            Cancel Update
          </Button>
        )}
      </form>

      {scrapFieldOrigins.length > 0 && (
        <TableContainer style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel>Field</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Field Source</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>For</TableSortLabel>
                </TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scrapFieldOrigins.map((origin) => {
                {
                  return String(iNT + iNS) == origin.flowtype ? (
                    <TableRow key={origin.id}>
                      <TableCell sx={{ color: "blue" }}>
                        {origin.field_name}
                      </TableCell>
                      {origin.field_from && origin.field_from == "browser" ? (
                        <TableCell>
                          {
                            ScrapFieldOriginTypesMessage.filter(
                              (item) => item.value === origin.field_from
                            )[0]?.label
                          }
                        </TableCell>
                      ) : origin.field_from &&
                        (origin.field_from == "emailattachment" ||
                          origin.field_from == "emailbody") ? (
                        <TableCell>
                          {
                            ScrapFieldOriginTypesEmail.filter(
                              (item) => item.value === origin.field_from
                            )[0]?.label
                          }
                        </TableCell>
                      ) : (
                        <TableCell>
                          {
                            ScrapFieldOriginTypesMessage.filter(
                              (item) => item.value === origin.field_from
                            )[0]?.label
                          }
                        </TableCell>
                      )}

                      <TableCell sx={{ color: "blue" }}>
                        {origin.flowtype}
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleEdit(origin)}
                          style={{ marginRight: 5 }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => handleDelete(origin.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={success || error}
        autoHideDuration={6000}
        onClose={() => {
          setSuccess(false);
          setError(false);
        }}
      >
        <Alert severity={success ? "success" : "error"}>{message}</Alert>
      </Snackbar>
    </Paper>
  );
};

export default ScrapFieldOriginForm;
