import React, { useState } from "react";
import { Typography } from "@mui/material";
import RecivedListBox from "../../../Components/RecivedListBox";
import { useTranslation } from "react-i18next";

const RecivedList = ({ Received }) => {
  const [t, i18n] = useTranslation("global");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  // Logic to paginate the Received items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Received?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Typography
        sx={{ fontSize: { md: "22px", xs: "18px", color: "#8C8C8C" } }}
      >
        {t("recievedlist.recieved_welcome")}
        <br />
        <br />
        {t("recievedlist.recieved_welcome1")}
      </Typography>

      {/* Display current page of Received items */}
      {currentItems &&
        currentItems.map((item, index) => (
          <RecivedListBox
            key={index}
            recieveddate={item.created_at}
            recievedtime={item.closing_time}
            orderdate={item.closing_date}
            location={item.closing_city}
            status={item.closing_status}
          />
        ))}

      {/* Pagination controls */}
      <div style={{ marginTop: "20px" }}>
        {Received &&
          Array.from(
            { length: Math.ceil(Received.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                style={{
                  margin: "0 5px",
                  padding: "5px 10px",
                  cursor: "pointer",
                  backgroundColor: currentPage === index + 1 ? "#ccc" : "#fff",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              >
                {index + 1}
              </button>
            )
          )}
      </div>
    </div>
  );
};

export default RecivedList;
