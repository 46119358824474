// Import necessary hooks from react-router-dom
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "../../Components/Payment";

// Load Stripe
const stripe = loadStripe(
  "pk_test_51PJTS4Rthqguo38pGbQMNI7xPeIkYCvyhHgjq5xP1ZFhkXACsE3MCy5WbwUzcpwBXbaFzh9pMYMTh4YVfCFLHq0M00O2IipQHq"
);

export default function SubscriptionAccount() {
  const { plan } = useParams();

  return (
    <Elements stripe={stripe}>
      <Payment plan={plan} />
    </Elements>
  );
}
