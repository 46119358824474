import React, { useState, useEffect } from "react";
import { TextField, Button, Box, MenuItem, Typography } from "@mui/material";
import axios from "axios";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const SigningAgencyForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    phone: "",
    support_email: "",
    support_phone: "",
    invitation_email: "",
    invitation_message: "",
    confirmation_email: "",
    confirmation_message: "",
  });

  const [agencies, setAgencies] = useState([]); // List of agencies from DB
  const [selectedAgencyId, setSelectedAgencyId] = useState(""); // Currently selected agency ID (for editing)

  // Fetch signing agencies when the component mounts
  useEffect(() => {
    axios
      .get(apiEndpoint + "/signing/agencies")
      .then((response) => {
        // console.log(response.data)
        setAgencies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching agencies:", error);
      });
  }, []);

  // When a user selects an agency to edit, populate form with agency's details
  const handleAgencySelect = (e) => {
    const agencyId = e.target.value;
    setSelectedAgencyId(agencyId);

    if (agencyId) {
      // Fetch the details of the selected agency and populate the form
      const selectedAgency = agencies.find((agency) => agency.id === agencyId);
      setFormData({
        name: selectedAgency.name,
        address: selectedAgency.address,
        phone: selectedAgency.phone,
        support_email: selectedAgency.support_email,
        support_phone: selectedAgency.support_phone,
        invitation_email: selectedAgency.invitation_email || "",
        invitation_message: selectedAgency.invitation_message || "",
        confirmation_email: selectedAgency.confirmation_email || "",
        confirmation_message: selectedAgency.confirmation_message || "",
      });
    } else {
      // Reset form for new entry
      setFormData({
        name: "",
        address: "",
        phone: "",
        support_email: "",
        support_phone: "",
        invitation_email: "",
        invitation_message: "",
        confirmation_email: "",
        confirmation_message: "",
      });
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Submit form data to create or update a signing agency
  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedAgencyId) {
      // Update existing agency
      axios
        .put(`${apiEndpoint}/signing/agencies/${selectedAgencyId}`, formData)
        .then((response) => {
          console.log("Agency updated successfully:", response.data);
          alert("Agency updated successfully");
        })
        .catch((error) => {
          console.error("Error updating agency:", error);
        });
    } else {
      // Create new agency
      axios
        .post(apiEndpoint + "/signing/agencies", formData)
        .then((response) => {
          console.log("Agency created successfully:", response.data);
          alert("Agency created successfully");
        })
        .catch((error) => {
          console.error("Error creating agency:", error);
        });
    }
  };

  // Delete the selected signing agency
  const handleDelete = () => {
    if (!selectedAgencyId) return;

    if (window.confirm("Are you sure you want to delete this agency?")) {
      axios
        .delete(`${apiEndpoint}/signing/agencies/${selectedAgencyId}`)
        .then((response) => {
          console.log("Agency deleted successfully:", response.data);
          alert("Agency deleted successfully");
          // Reset form and remove deleted agency from the dropdown
          setSelectedAgencyId("");
          setFormData({
            name: "",
            address: "",
            phone: "",
            support_email: "",
            support_phone: "",
            invitation_email: "",
            invitation_message: "",
            confirmation_email: "",
            confirmation_message: "",
          });
          setAgencies(
            agencies.filter((agency) => agency.id !== selectedAgencyId)
          );
        })
        .catch((error) => {
          console.error("Error deleting agency:", error);
        });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ maxWidth: 600, margin: "auto", p: 2 }}
    >
      <Typography>Signing Agency Detail</Typography>
      {/* Dropdown to select an agency for editing */}
      <TextField
        select
        label="Select Agency to Edit (Optional)"
        fullWidth
        margin="normal"
        value={selectedAgencyId}
        onChange={handleAgencySelect}
      >
        <MenuItem value="">
          <em>New Agency</em>
        </MenuItem>
        {agencies &&
          agencies.map((agency) => (
            <MenuItem key={agency.id} value={agency.id}>
              {agency.name}
            </MenuItem>
          ))}
      </TextField>

      {/* Form Fields */}
      <TextField
        label="Name"
        name="name"
        fullWidth
        margin="normal"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <TextField
        label="Address"
        name="address"
        fullWidth
        margin="normal"
        value={formData.address}
        onChange={handleChange}
        required
      />
      <TextField
        label="Phone"
        name="phone"
        fullWidth
        margin="normal"
        value={formData.phone}
        onChange={handleChange}
        required
      />
      <TextField
        label="Support Email"
        name="support_email"
        type="email"
        fullWidth
        margin="normal"
        value={formData.support_email}
        onChange={handleChange}
        required
      />
      <TextField
        label="Support Phone"
        name="support_phone"
        fullWidth
        margin="normal"
        value={formData.support_phone}
        onChange={handleChange}
        required
      />
      <TextField
        label="Invitation Email"
        name="invitation_email"
        type="email"
        fullWidth
        margin="normal"
        value={formData.invitation_email}
        onChange={handleChange}
      />
      <TextField
        label="Invitation Message (Phone)"
        name="invitation_message"
        fullWidth
        margin="normal"
        value={formData.invitation_message}
        onChange={handleChange}
      />
      <TextField
        label="Confirmation Email"
        name="confirmation_email"
        type="email"
        fullWidth
        margin="normal"
        value={formData.confirmation_email}
        onChange={handleChange}
      />
      <TextField
        label="Confirmation Message (Phone)"
        name="confirmation_message"
        fullWidth
        margin="normal"
        value={formData.confirmation_message}
        onChange={handleChange}
      />

      {/* Update or Create Button */}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        {selectedAgencyId ? "Update Agency" : "Create Agency"}
      </Button>

      {/* Delete Button, shown only if an agency is selected */}
      {selectedAgencyId && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleDelete}
        >
          Delete Agency
        </Button>
      )}
    </Box>
  );
};

export default SigningAgencyForm;
