import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCalendarEmails } from "../Redux/Slices/selectedCalendarEmails"; // Importing setColors action from colorsSlice
import CheckboxLabel from "./Checkbox";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ColorPicker from "../Components/ColorPicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import { setEvents } from "../Redux/Slices/events"; // Correct path to eventsSlice
import Cookie from "js-cookie";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { updateCalenderEvents } from "../Redux/Slices/calendarSlice";

const options = ["Choose Color"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function CalendarDropdown({ sx }) {
  let calenderEvents = useSelector((state) => state.calenderEvents);
  let emails = useSelector((state) => state.user.calendar_accounts.accounts);

  const dispatch = useDispatch();
  const events = useSelector((state) => state.events);

  const [selectedEmails, setSelectedEmails] = useState(emails ?? []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [selectedVariantColor, setSelectedVariantColor] = useState({});
  const [selectedVariantId, setSelectedVariantId] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState(emails ?? []);

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!calenderEvents.data) {
      getAcessToken();
    }
  }, []);

  const getAcessToken = async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", user?.email);

    let email_accounts = selectedEmails.map((item) => item.account_email);
    form_data.append("email_accounts", JSON.stringify(email_accounts));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };

    fetch(`${apiEndpoint}/calender/email/access_token/refresh`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(async (result) => {
        console.log(result);
        dispatch(updateCalenderEvents([...result]));
      })
      .catch((error) => {
        console.error("Error fetching access token:", error); // Error log
      });
  };

  // console.log(calenderEvents.data);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorPickerOpen = (emailId) => {
    handleClose();
    setSelectedVariantId(emailId);
    setColorPickerOpen(true);
  };

  const handleColorPickerClose = () => {
    setColorPickerOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedVariants(event.target.value);
  };

  const filteredVariants = selectedVariants
    ? selectedVariants?.filter((email) => email.name !== "My Calendars")
    : [];

  const handleEmailSelection = async (email) => {
    setSelectedEmails((pre) => {
      if (pre?.includes(email)) {
        return pre?.filter((c) => c !== email);
      } else {
        return [...pre, email];
      }
    });
  };

  useEffect(() => {
    // console.log(selectedEmails);
    dispatch(updateCalendarEmails(selectedEmails));
  }, [selectedEmails]);

  return (
    <Box sx={{ ...sx }}>
      <Box>
        <FormControl sx={{ my: 1, width: "100%" }}>
          <Select
            id="demo-multiple-checkbox"
            multiple
            value={selectedVariants}
            onChange={handleSelectChange}
            input={<OutlinedInput />}
            renderValue={(selected) =>
              selected.map((x) => x.account_email).join(", ")
            }
            MenuProps={{
              disableScrollLock: true,
            }}
          >
            {emails &&
              emails?.map((email) => (
                <MenuItem key={email.id} value={email}>
                  <Checkbox
                    checked={selectedVariants.some(
                      (item) => item.account_email === email.account_email
                    )}
                    sx={{
                      color: "#F6B980",
                      "&.Mui-checked": {
                        color: "#F6B980",
                      },
                    }}
                  />
                  <ListItemText primary={email.account_email} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Grid className="choose">
        {/* <CheckboxLabel
          label="Pending Orders"
          color="yellow"
          // onClick={() => handleColordefault("#FFF6D9", "Pending Orders")}
        />
        <CheckboxLabel
          label="Confirmed Orders"
          color="green"
          // onClick={() => handleColordefault("#EAF4E5", "Confirmed Orders")}
        /> */}

        {filteredVariants.map((email) => (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CheckboxLabel
              key={email.id}
              checked={selectedEmails.some(
                (item) => item.account_email === email.account_email
              )}
              label={email.account_email}
              color={selectedVariantColor[email.id] || "primary"}
              onClick={() => handleEmailSelection(email)}
            />
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => handleColorPickerOpen(email.id)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Grid>
        ))}
      </Grid>
      {colorPickerOpen && (
        <ColorPicker
          open={colorPickerOpen}
          handleClose={handleColorPickerClose}
          onColorSelected={(color) => {
            setSelectedVariantColor({
              ...selectedVariantColor,
              [selectedVariantId]: color,
            });
          }}
        />
      )}
    </Box>
  );
}
