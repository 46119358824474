import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import USA from "../Assets/Images/usaflag.png";
import SPN from "../Assets/Images/spnflag.png";
import { useTranslation } from "react-i18next";
import { changeLanguage } from '../Redux/Slices/languageSlice'; 

const SelectLanguage = ({ dropdownDirection, color = '#000000' }) => {  // Default color is black if no color prop is passed
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector(state => state.language.language);
  const options = [
    { value: "eng", label: "En", imgSrc: USA, languageCode: "en" },
    { value: "spn", label: "ES", imgSrc: SPN, languageCode: "sp" },
  ];
  const dropdownRef = useRef(null);

  const toggleOpen = () => setIsOpen(!isOpen);

  const onOptionClicked = (value, languageCode) => () => {
    dispatch(changeLanguage(value)); 
    i18n.changeLanguage(languageCode);
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const dropdownMenuClass = dropdownDirection === 'down' ? 'dropdown-menu-down' : 'dropdown-menu-up';

  return (
    <div className="dropdown">
      <button onClick={toggleOpen} className="dropdown-button" style={{ color: color }}>
        <img
          src={options.find((o) => o.value === language).imgSrc}
          alt={options.find((o) => o.value === language).label}
          style={{ width: "20px", height: "20px", marginRight: "5px", verticalAlign: "middle" }}
        />
        {options.find((o) => o.value === language).label}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
          className="bi bi-caret-down"
          style={{ marginLeft: "5px", verticalAlign: "middle" }}
        >
          <path d="M8 10.707l-5.5-5.5L2.793 4.793 8 10l5.207-5.207L13.5 5.207 8 10.707z"/>
        </svg>
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={dropdownMenuClass}>
          {options.map((option) => (
            <div
              key={option.value}
              className="dropdown-item"
              onClick={onOptionClicked(option.value, option.languageCode)}
            >
              <img
                src={option.imgSrc}
                alt={option.label}
                style={{ width: "20px", height: "20px", marginRight: "10px", verticalAlign: "middle"  }}
              />
              <span style={{ verticalAlign: "middle", color: color }}>{option.label}</span> {/* Apply color prop here */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLanguage;
