import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import SatelliteOutlinedIcon from "@mui/icons-material/SatelliteOutlined";
import { Box, Grid, Typography } from "@mui/material";
import RecivedList from "./RecivedList";
import DeclineList from "./DeclineList";
import PendingList from "./PendingList";
import ConfirmedList from "./ConfirmedList";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const ListTab = () => {
  const [t, i18n] = useTranslation("global");
  const [value, setValue] = React.useState("1");
  const orders = useSelector((state) => state.orders.data);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 0,
          flexWrap: "wrap",
        }}
      >
        <TabList
          className="b"
          textColor="#F26F21"
          indicatorColor="#F26F21"
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          <Tab
            icon={<ExpandCircleDownOutlinedIcon />}
            iconPosition="start"
            label={t("dashboard.recieved")}
            value="1"
            sx={{
              fontSize: { lg: "16px", xs: "12px" },
              textTransform: "capitalize",
              fontWeight: 500,
              color: "#545454", // Default text color
              "&:hover": {
                color: "#F26F21",
                fontWeight: 500, // Text color on hover
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color on hover
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color on hover
                },
              },
              "&.Mui-selected": {
                color: "#F26F21",
                fontWeight: 500, // Text color when active
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color when active
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color when active
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                left: "50%",
                bottom: "0",
                transform: "translateX(-50%)",
                width: "100%",
                height: "2px",
                backgroundColor: "", // Set the underline color to green
              },
            }}
          />
          <Tab
            icon={<CancelOutlinedIcon sx={{ borderRadius: "20px" }} />}
            iconPosition="start"
            label={t("dashboard.declined")}
            value="2"
            sx={{
              fontSize: { lg: "16px", xs: "12px" },
              textTransform: "capitalize",
              fontWeight: 500,
              color: "#545454", // Default text color
              "&:hover": {
                color: "#F26F21",
                fontWeight: 500, // Text color on hover
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color on hover
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color on hover
                },
              },
              "&.Mui-selected": {
                color: "#F26F21",
                fontWeight: 500, // Text color when active
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color when active
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color when active
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                left: "50%",
                bottom: "0",
                transform: "translateX(-50%)",
                width: "100%",
                height: "2px",
                backgroundColor: "", // Set the underline color to green
              },
            }}
          />
          <Tab
            icon={<HourglassEmptyIcon />}
            iconPosition="start"
            label={t("dashboard.pending")}
            value="3"
            sx={{
              fontSize: { lg: "16px", xs: "12px" },
              textTransform: "capitalize",
              fontWeight: 500,
              color: "#545454", // Default text color
              "&:hover": {
                color: "#F26F21",
                fontWeight: 500, // Text color on hover
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color on hover
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color on hover
                },
              },
              "&.Mui-selected": {
                color: "#F26F21",
                fontWeight: 500, // Text color when active
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color when active
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color when active
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                left: "50%",
                bottom: "0",
                transform: "translateX(-50%)",
                width: "100%",
                height: "2px",
                backgroundColor: "", // Set the underline color to green
              },
            }}
          />
          <Tab
            icon={<CheckCircleOutlineSharpIcon />}
            iconPosition="start"
            label={t("dashboard.confirmed")}
            value="4"
            sx={{
              fontSize: { lg: "16px", xs: "12px" },
              textTransform: "capitalize",
              fontWeight: 500,
              color: "#545454", // Default text color
              "&:hover": {
                color: "#F26F21",
                fontWeight: 500, // Text color on hover
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color on hover
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color on hover
                },
              },
              "&.Mui-selected": {
                color: "#F26F21",
                fontWeight: 500, // Text color when active
                "& .MuiSvgIcon-root": {
                  stroke: "#F26F21", // Icon color when active
                },
                "&::after": {
                  backgroundColor: "#F26F21", // Underline color when active
                },
              },
              "&::after": {
                content: '""',
                position: "absolute",
                left: "50%",
                bottom: "0",
                transform: "translateX(-50%)",
                width: "100%",
                height: "2px",
                backgroundColor: "", // Set the underline color to green
              },
            }}
          />
        </TabList>
      </Box>
      <TabPanel value="1">
        <RecivedList Received={orders?.received} />
      </TabPanel>
      <TabPanel value="2">
        <DeclineList Declined={orders?.declined} />{" "}
      </TabPanel>
      <TabPanel value="3">
        <PendingList Pending={orders?.pending} />
      </TabPanel>
      <TabPanel value="4">
        <ConfirmedList Confirmed={orders?.confirmed} />
      </TabPanel>
    </TabContext>
  );
};

export default ListTab;
