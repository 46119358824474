import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import SigninAvatar from "../../Assets/Images/Sigininavatar.png";
import Paper from "@mui/material/Paper";
import { Container, Typography } from "@mui/material";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import RightImgWithPopup from "../../Components/RightImgWithPopup";
import NewPassMobImg from "../../Assets/Images/Newpassmobimg.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function NewPasswordSuccessful({ open, handleClose }) {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
      }}
    >
      <Button
        sx={{
          position: "absolute",
          top: "8%",
          right: "5%",
          color: "#fff",
          fontSize: { lg: "22px", xs: "12px" },
          "&:hover": { boxShadow: "none", color: "red" },
        }}
        onClick={handleClose}
        btnname="x"
      />
      {/* </Button> */}
      <Container zIndex sx={{ display: { md: "block", xs: "none" } }}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ maxHeight: "100%", minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            xlg={7}
            sx={{ mt: { lg: "auto" }, mb: 15, position: "relative" }}
          >
            <Link to="/signin" style={{textDecoration:"none"}}>
            <Button
              btnname="reset_pass.backtoLoginpage"
              type="submit"
              variant="contained"
              className="Orangebtn"
              sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" }, my: 1 }}
            /></Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{
              p: 0,
              display: { xs: "none", md: "block" },
              position: "relative",
              left: "-16%",
              bottom: 0,
            }}
          >
            <RightImgWithPopup
              charcterStyle={{ maxWidth: "90%", transform: "scaleX(-1)", position:"relative", left:"45%" }}
              style={{ bottom: "-10vh" }}
              img={SigninAvatar}
              msg="reset_pass.pass_sucess_msg"
            />
          </Grid>
        </Grid>
      </Container>

      <Container zIndex sx={{ display: { md: "none", xs: "block" }, my:"auto",  }}>
        <Grid
          container
          columnSpacing={{}} 
          sx={{py:"auto", height:"100vh"}}
        >
          <Grid
            item
            xs={12}
            sx={{
              my:"auto"
            }}
          >
            
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                px: "auto",
              }}
            >
              {" "}
              <img src={NewPassMobImg} />{" "}
            </Grid>
            <Link to="/signin" style={{textDecoration:"none"}}>
            <Button
              btnname="reset_pass.backtoLoginpage"
              type="submit"
              variant="contained"
              className="Orangebtn"
              sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" }, my: 2 }}
            /></Link>
          </Grid>
        </Grid>
      </Container>
    </ModalLayout>
  );
}
