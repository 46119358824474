import { Box, Container, Grid, Button, Typography } from "@mui/material";
import React from "react";
import Logo from "../Components/Logo";
import AuthLayout from "../Layouts/AuthLayout";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Buttonn from "../Components/Button";
import AboutIcon from "../Assets/Icons/abouyticon.svg";
import PaymentIcon from "../Assets/Icons/paymenticon.svg";
import StartedIcon from "../Assets/Icons/startedicon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectLanguage from "../Components/LanguageSelect";
const Item = styled(Paper)(({ theme }) => ({
  
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const HelpSupport = () => {
  const [t, i18n] = useTranslation("global");
  const BackClick = () => {
    window.history.back();
  };
  return (
    <AuthLayout sx={{ minHeight: "100vh", maxHeight: "100%" }}>
      <Grid sx={{ maxWidth: "none", px: 3 }}>
        <Logo />
        <Container sx={{ position: "relative" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { lg: "40px", xs: "34px" },
              fontWeight: 800,
            }}
          >
            Help Center
          </Typography>
          <Grid container lg={4} sx={{ mx: "auto" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { lg: "18px", xs: "16px" },
                fontWeight: 400,
              }}
            >
              orem ipsum dolor sit amet, consectetur adipiscing elit. Lobortis
              arcu enim urna adipiscing prae
            </Typography>
          </Grid>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { lg: "40px", xs: "34px" },
              fontWeight: 800,
              mt: 5,
            }}
          >
            Browse questions by category
          </Typography>
          <Grid container lg={4} sx={{ mx: "auto" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { lg: "18px", xs: "16px" },
                fontWeight: 400,
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit lobortis
              arcu enim urna adipiscing praesent velit
            </Typography>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ my: 4 }}
            >
              <Grid item xs={12} lg={4}>
                <Button
                  sx={{
                    width: "100%",
                    py: "30px",
                    borderRadius: "20px",
                    fontWeight: 700,
                    fontSize: {
                      xs: "18px",
                      md: "24px",
                    },
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#FADCC1", // Set the same background color on hover
                      color: "black",
                      boxShadow: "none", // Set the same text color on hover
                    },
                  }}
                  size="large"
                  variant="contained"
                  lg={12}
                  startIcon={
                    <img
                      src={AboutIcon}
                      alt="AboutIcon"
                      style={{ width: "28px", height: "28px" }}
                    />
                  }
                >
                  About GoSignings!
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  sx={{
                    width: "100%",
                    py: "30px",
                    borderRadius: "20px",
                    fontWeight: 700,
                    fontSize: {
                      xs: "18px",
                      md: "24px",
                    },
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FADCC1", // Set the same background color on hover
                      color: "black", // Set the same text color on hover
                    },
                  }}
                  size="large"
                  variant="contained"
                  lg={12}
                  startIcon={
                    <img
                      src={StartedIcon}
                      alt="AboutIcon"
                      style={{ width: "28px", height: "28px" }}
                    />
                  }
                >
                  Getting started
                </Button>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  sx={{
                    width: "100%",
                    py: "30px",
                    borderRadius: "20px",
                    fontWeight: 700,
                    fontSize: {
                      xs: "18px",
                      md: "24px",
                    },
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#FADCC1", // Set the same background color on hover
                      color: "black",
                      boxShadow: "none", // Set the same text color on hover
                    },
                  }}
                  size="large"
                  variant="contained"
                  lg={12}
                  startIcon={
                    <img
                      src={PaymentIcon}
                      alt="AboutIcon"
                      style={{ width: "28px", height: "28px" }}
                    />
                  }
                >
                  Payments system
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { lg: "18px", xs: "16px" },
              fontWeight: 400,
              mb: 4,
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit lobortis
            arcu enim urna adipiscing praesent velit Lorem ipsum dolor sit amet,
            consectetur adipiscing elit lobortis arcu enim urna adipiscing
            praesent velit Lorem ipsum dolor sit amet, consectetur adipiscing
            elit lobortis arcu enim urna adipiscing praesent velit Lorem ipsum
            dolor sit amet, consectetur adipiscing elit lobortis arcu enim urna
            adipiscing praesent velit Lorem ipsum dolor sit amet, consectetur
            adipiscing elit lobortis arcu enim urna adipiscing praesent velit
            Lorem ipsum dolor sit amet, consectetur adipiscing elit lobortis
            arcu enim urna adipiscing praesent velit Lorem ipsum dolor sit amet,
            consectetur adipiscing elit lobortis arcu enim urna adipiscing
            praesent velit
          </Typography>
          <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
            <Buttonn
              btnname="button.back"
              variant="contained"
              onClick={BackClick}
              className="greybtn"
              sx={{
                px: { lg: 7, xs: 3 },
                py: 1.2,
                fontSize: { lg: "16px", xs: "12px" },
                mx: 1,
                borderRadius: "8px",
                my: 4,
              }}
            />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center", // Center the Typography components vertically
              }}
            >
              <Box sx={{
                  }}>
              <SelectLanguage /></Box>
              <Link to="/contactus" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    color: "#F26F21",
                    fontSize: { lg: "18px", xs: "12px" },
                    mx: 4,
                  }}
                >
                  {t("contact.contact")}
                </Typography>
              </Link>
              {/* <Typography
                sx={{
                  color: "#8C8C8C",
                  fontSize: { lg: "18px", xs: "12px" },
                  mx: 4,
                }}
              >
                {t("contact.help")}
              </Typography> */}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </AuthLayout>
  );
};

export default HelpSupport;
