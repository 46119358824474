import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  steppers: {
    stepper_1: false,
    stepper_2: false,
    stepper_3: false,
    stepper_4: false,
    stepper_5: false,
  },
  currentStepper: 0,
};

const stepperInfoSlice = createSlice({
  name: "stepperInfo",
  initialState,
  reducers: {
    // Set the entire steppers object at once
    setSteppers: (state, action) => {
      state.steppers = action.payload;
    },
    updateStepper1: (state, action) => {
      state.steppers.stepper_1 = action.payload;
    },
    updateStepper2: (state, action) => {
      state.steppers.stepper_2 = action.payload;
    },
    updateStepper3: (state, action) => {
      state.steppers.stepper_3 = action.payload;
    },
    updateStepper4: (state, action) => {
      state.steppers.stepper_4 = action.payload;
    },
    updateStepper5: (state, action) => {
      state.steppers.stepper_5 = action.payload;
    },
    setCurrentStepper: (state, action) => {
      state.currentStepper = action.payload;
    },
  },
});

export const {
  setSteppers,
  updateStepper1,
  updateStepper2,
  updateStepper3,
  updateStepper4,
  updateStepper5,
  setCurrentStepper,
} = stepperInfoSlice.actions;

export default stepperInfoSlice.reducer;
