import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Container, Typography } from "@mui/material";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import AccountVarificationimg from "../../Assets/Images/Sigininavatar.png";
import ModalLayout from "../../Layouts/ModalLayout";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
export default function PreferenceModal({ open, handleClose, onNext }) {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  const user=useSelector((state)=>state?.user?.user);
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
      }}
    >
      <Button
        sx={{
          position: "absolute",
          top: "8%",
          right: "5%",
          color: "#fff",
          fontSize: { lg: "22px", xs: "12px" },
          "&:hover": { boxShadow: "none", color: "red" },
        }}
        onClick={handleClose}
        btnname="x"
      />
      {/* </Button> */}
      <Container zIndex sx={{ py: { lg: 5 } }}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Typography
          sx={{
            fontSize: { lg: "32px", xs: "18px" },
            color: "#F26F21",
            textAlign: "center",
          }}
        >
          {t("prefrence_modal.setup")}
        </Typography>

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} md={4} lg={4}>
            <Grid sx={{ display: { xs: "none", md: "block" } }}>
            <Box
          display="flex"
          justifyContent="center"
        >
              <img
                src={AccountVarificationimg}
                alt="img"
                style={{
                  maxWidth: "100%",
                  height: "80vh",
                }}/>
                </Box>
              
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xlg={8} sx={{}}>
            <Grid
              sx={{
                px: { lg: 7, xl: 20 },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: { md: 700, xs: 600 },
                  fontSize: { md: "32px", xs: "18px" },
                  color: "#6A9B41",
                }}
              >
                {t("prefrence_modal.hi")} {user?.first_name}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  mt: 2,
                  fontWeight: { lg: 500, xs: 500 },
                  color: "white",
                }}
              >
                {t("prefrence_modal.text1")}
                <br />
                {t("prefrence_modal.text2")}
                <br />
                {t("prefrence_modal.text3")}
              </Typography>

              <Grid lg={8} xl={12}>
                <Button
                  btnname="button.continue"
                  type="submit"
                  variant="contained"
                  onClick={onNext}
                  className="Orangebtn"
                  sx={{
                    py: "10px",
                    fontSize: { lg: "22px", xs: "12px" },
                    my: 3,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ModalLayout>
  );
}
