import { Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react'

import TextImageviseversa from '../Components/TextImageviseversa.jsx'
import Logo from '../Components/Logo'

import Core1 from '../Assets/Images/Core1.png'
import Core2 from '../Assets/Images/Core2.png'
import Core3 from '../Assets/Images/Core3.png'

import backgroundImage from '../Assets/Images/corebg.png'

const CoreFeatures = () => {

    return (
        <div id="core-feature">
            <Box sx={{
                // marginTop: { xs: '16px' },
                background: '#ebffda',
                height: 'auto',
                backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover', // Ensures the image covers the whole container
                        backgroundPosition: 'center', // Centers the image
                        backgroundRepeat: 'no-repeat', // Prevents repeating
                        opacity: '1',
            }}>
                <Container sx={{padding: { xs: '20px', md: '20px 20px 70px 20px' }}}>
                    <Grid container spacing={2} >
                        {/* First Row: Single Column */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Box sx={{ padding: { xs: '20px 0px', md: '20px 20px' } }}>
                                <Logo width='244px'/>
                            </Box>
                        </Grid>

                        {/* Second Row: Single Column */}
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: { md: 'center', xs: 'left' }, padding: '10px 0px' }}>
                                <Typography sx={{ color: '#000000', fontSize: { xs: '36px', md: '40px' }, marginTop: { xs: '-25px', md: 'auot' }, fontWeight: '600' }}>
                                    <span style={{ color: '#F26F21' }}>GoSignings</span> Core Features</Typography>
                            </Box>
                        </Grid>

                        {/* Third Row: Two Columns */}

                        <Grid item xs={12} md={12} sx={{paddingTop: {md: '40px !important', xs: '0px'}}}>
                            <Box sx={{ padding: { md: '0px 20px'} }}>
                                <TextImageviseversa
                                    image={Core1}
                                    heading='Automate & Close More'
                                    text='GoSignings automatically handles your job invitations, accepting or rejecting them based on your customized preferences.'
                                    reverse={false}
                                    padtop={{ xs: '14px'}}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '0px 20px' } }}>
                                <TextImageviseversa
                                    image={Core2}
                                    heading='Effortless Scheduling'
                                    text='You Can See Your Jobs Location in GoSigning Map & Our AI finds the perfect slots for new jobs, considering your availability and travel time.'
                                    reverse={true}
                                    padtop={{ xs: '14px'}}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '0px 20px' }, paddingBottom: {md: '0px', xs: '50px'},}}>
                                <TextImageviseversa
                                    image={Core3}
                                    heading='Schedule Conflicts, Loss Of Income'
                                    text='GoSignings smartly manages your schedule, ensuring your job and personal calendars Stay in Sync with each other. Easily manage your notary jobs without any Difficulty.'
                                    reverse={false}
                                    padtop={{ xs: '14px'}}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default CoreFeatures; 