import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const TextImageviseversa = ({ padtop = { xs: '48px'}, image, heading, text, reverse }) => {
  return (
    <Box
      sx={{
        padding: { md: '0px 20px' },

        marginBottom: {xs: '10px', md: '20px'},
      }}
    >
      <Grid container spacing={6} direction={reverse ? 'row-reverse' : 'row'}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
          <Typography
            variant="h4"
            sx={{ color: '#000000', fontFamily: 'Inter', fontWeight: 600, fontSize: '36px' }}
          >
            {heading}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: '#000000', fontFamily: 'Inter', marginTop: '16px', fontSize: {xs: '21px', md: '24px'}, lineHeight: '131%' }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{paddingTop: {xs: `${padtop.xs} !important`, md: '48px'}}}>
          <Box
            component="img"
            src={image}
            alt={heading}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
              zIndex: 2, // Higher z-index to appear in front of elements with a lower z-index
              position: 'relative' // Ensure position is set to make z-index effective
            }}
          />
        </Grid>

      </Grid>
    </Box>
  );
};

export default TextImageviseversa;
