import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
function createData(
  id,
  payment,
  purchase,
  card,
  planType,
  planDuration,
  amount
) {
  return {
    id,
    payment,
    purchase,
    card,
    planType,
    planDuration,
    amount,
  };
}

export default function BillingTable({ billingdata, loading }) {
  // console.log(billingdata);
  const [t, i18n] = useTranslation("global");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dense, setDense] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", my: 4 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "20px" },
            fontWeight: 800,
            px: 3,
            pt: 3,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("billing.customer")}
        </Typography>
        <TableContainer>
          <Table
            sx={{}}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ textAlign: "center" }}
                  >
                    {t("billing.id")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ textAlign: "center" }}
                  >
                    {t("billing.payment")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ textAlign: "center" }}
                  >
                    {t("billing.date")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ textAlign: "center" }}
                  >
                    {t("billing.cardno")}
                  </Typography>
                </TableCell>

                <TableCell align="right">
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ textAlign: "center" }}
                  >
                    {t("billing.duration")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ textAlign: "center" }}
                  >
                    {t("billing.amount")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <>
                  <p style={{ textAlign: "center" }}>Loading....</p>
                </>
              )}
              {billingdata &&
                !loading &&
                billingdata
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell align="center">
                        {row.payment_method == null ? "-" : row.payment_method}
                      </TableCell>
                      <TableCell align="center">
                        {row.purchase_date == null ? "-" : row.purchase_date}
                      </TableCell>
                      <TableCell align="center">
                        {row.card_number == null ? "-" : row.card_number}
                      </TableCell>
                      <TableCell align="center">
                        {row.plan.name == null ? "-" : row.plan.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.plan.price == null ? "-" : row.plan.price}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={billingdata?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
