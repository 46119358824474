import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import PopoverHover from "./Popover";
import { Grid } from "@mui/material";

export default function RowRadioButtonsGroup({
  label,
  labelConfigs,
  selectedValue,
  setSelectedValue,
  poptext,
  name,
  id,
}) {
  const [t, i18n] = useTranslation("global");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => {
    return {
      checked: selectedValue === item.value,
      onChange: handleChange,
      value: item.value,
      name: { name },
      inputProps: { "aria-label": item.label },
    };
  };

  return (
    <FormControl>
      <Grid sx={{ display: "flex" }}>
        <FormLabel
          id={id}
          sx={{
            fontSize: { lg: "25px", xs: "14px" },
            fontWeight: { lg: 600, xs: 500 },
            color: "black",
          }}
        >
          {t(label)}
        </FormLabel>
        <Grid className="radiopopover">
          {" "}
          <PopoverHover text={t(poptext)} />
        </Grid>
      </Grid>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={selectedValue}
      >
        {labelConfigs.map((config, index) => (
          <FormControlLabel
            key={index}
            {...config}
            {...controlProps(config)}
            control={
              <Radio
                sx={{
                  color: config.color || "#F6B980",
                  "&.Mui-checked": {
                    color: config.checkedColor || "#F6B980",
                  },
                }}
              />
            }
            label={t(config.label)} // Translate radio button label
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
