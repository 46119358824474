import { createSlice } from "@reduxjs/toolkit";

const preferenceSlice = createSlice({
  name: "preferences",
  initialState: {
    data: null,
    stepper1: null,
    stepper2: null,
    stepper3: null,
    stepper4: null,
    stepper5: null,
    timeZones: null,
  },
  reducers: {
    updatePreferences: (state, action) => {
      state.data = action.payload;
    },
    updateStepper1: (state, action) => {
      state.stepper1 = action.payload;
    },
    updateStepper2: (state, action) => {
      state.stepper2 = action.payload;
    },
    updateStepper3: (state, action) => {
      state.stepper3 = action.payload;
    },
    updateStepper4: (state, action) => {
      state.stepper4 = action.payload;
    },
    updateStepper5: (state, action) => {
      state.stepper5 = action.payload;
    },
    updateTimeZones: (state, action) => {
      state.timeZones = action.payload;
    },
  },
});

export const {
  updatePreferences,
  updateStepper1,
  updateStepper2,
  updateStepper3,
  updateStepper4,
  updateStepper5,
  updateTimeZones,
} = preferenceSlice.actions;
export default preferenceSlice.reducer;
