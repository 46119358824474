import { Box, display, height } from "@mui/system";
import Logo from "../Assets/Images/bllogo.png";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Assuming you're using cookies to manage the login state

export default function SiteLogo({ width = "140px" }) {  // Default to 140px if width prop is not passed
  const navigate = useNavigate();

  const handleLogoClick = () => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      navigate("/dashboard"); // Redirect to dashboard if logged in
    } else {
      navigate("/Signup"); // Redirect to landing page or signup page if not logged in
    }
  };

  return (
    <>
      <Box
        sx={{  display: { lg: "block", xs: "none" }, // Responsive display: block on large screens, none on small screens
        height: '80px',
      }}
        
        style={{   }} // Add cursor pointer to indicate clickable element
      >
        <Box sx={{ 
        display: 'flex',
        height: '80px',
        alignItems: 'center', 
        justifyContent: 'center',}}>
        <img
          src={Logo}
          alt="logo"
          style={{
            position: "relative",
            top: "3%",
            width: "218px",
            height: "99px",
            maxWidth: "100%",
            height: "auto",
            objectFit: "cover",cursor: "pointer",
          }}
          onClick={handleLogoClick}
        />
        </Box>
      </Box>
      <Box
        sx={{ display: { lg: "none", xs: "block" } }}
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickable element
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            position: "relative",
            top: "0",
            width: width,  // Use the dynamic width prop here
            height: "46px",
            maxWidth: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
      </Box>
    </>
  );
}
