import * as React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import Button from "../Components/Button";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function ConfirmedModal({ open, handleClose, parentNotify }) {
  const [t, i18n] = useTranslation("global");
  
  const [opened, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosed = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const handleConfirmed = async() => {
    await parentNotify(); // Notify the parent component
    handleClose(); // Close the modal in the parent component
  };
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
        display:"flex",
        alignItems:"center"
      }}
    >
      <Grid lg={8} xs={10} sx={{m:"auto", }} >
        <Typography
          sx={{
            fontSize: { lg: "32px", xs: "18px" },
            fontWeight: 600,
            color: "white",
            justifyContent:"center",
            textAlign:"center"
          }}
        >
          {t("confirm_modal.usure")}{" "}
          <span style={{ color: "#6A9B41" }}>  {t("confirm_modal.confirmorder")}</span>
        </Typography>
        
        <Grid
          sx={{
            my: 3,
            display: "flex",
            justifyContent:"center"
          }}
        >
          <Button
            btnname="button.no"
            type="submit"
            onClick={handleClose}
            variant="contained"
            className=""
            sx={{
              borderRadius: "8px",
              px: { lg: 7, xs: 3 },
              py: 1.2,
              fontSize: { lg: "16px", xs: "12px" },
              mx: 1,
              color: "white",
              transition: "background-color 0.5s ease, color 0.7s ease",
              backgroundColor: "#83C066",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", backgroundColor: "#83C066" },
            }}
          />
          <Button
            btnname="button.yes"
            type="submit"
            onClick={handleConfirmed}
            variant="contained"
            className="Orangebtn"
            sx={{
              px: { lg: 7, xs: 3 },
              py: 1.2,
              fontSize: { lg: "16px", xs: "12px" },
              mx: 1,
            }}
          />
        </Grid>
      </Grid>
    </ModalLayout>
  );
}
