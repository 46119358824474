import { Typography } from '@mui/material';
import React from 'react';

const HeadingText = ({ heading, text, highlightedText }) => {
  return (
    <div>

      <Typography variant="h4" sx={{fontFamily: 'inter', fontSize: {md: '40px', xs: '36px'}, fontWeight: 'bolder', lineHeight: '130%', color: '#000000', textAlign: {md: 'center', xs: 'left'}, marginTop: {xs: '20px', md: '10px'}}}>
        {heading.split(highlightedText).map((part, index, array) => (
          <span key={index} style={{ color: index === array.length - 1 ? 'black' : 'inherit' }}>
            {part}
            {index !== array.length - 1 && (
              <span style={{ color: 'orange' }}>{highlightedText}</span>
            )}
          </span>
        ))}
      </Typography>
      <Typography variant="h6" sx={{fontFamily: 'inter', fontSize: '24px', fontWeight: '100', lineHeight: '150%', color: '#000000', textAlign: {md: 'center', xs: 'left'}, margin: '20px 0px'}}>
        {text}
      </Typography>
    </div>
  );
};

export default HeadingText;
