import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import DashboardLayout from "../Layouts/DashboardLayout";
import PayPalIcon from "../Assets/Images/paypal.png";
import CreditcardIcon from "../Assets/Images/creditcard.png";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import SettingSidebar from "../Components/SettingSidebar";
// import Button from "../Components/Button";
import CustomField from "../Components/CustomField";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CardNumberElement,
  useElements,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import PaypalCheckout from "./PaypalCheckout";
import Paypal from "./Paypal";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../Redux/Slices/userSlice";
import toast from "react-hot-toast";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const theme = createTheme();
// const stripe = require("stripe")(process.env.REACT_APP_STRIPE_SECRET);

const Payment = ({ plan }) => {
  const [t, i18n] = useTranslation("global");
  const BackClick = () => {
    window.history.back();
  };

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const [selectedOption, setSelectedOption] = useState("creditcard");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const subscription = useSelector((state) => state.user.subscription);
  const user = useSelector((state) => state.user?.user);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setError("Invalid stripe instance");
      return;
    }

    setError(null);
    setloading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );

    const cardElement = elements.getElement(CardNumberElement);

    const { token, error: Tokenerror } = await stripe.createToken(cardElement);
    if (Tokenerror) {
      console.error(Tokenerror);
      setError(Tokenerror.message);
      return;
    }

    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", user?.email);
    form_data.append("token", token?.id);
    form_data.append("subscription_plan_name", plan);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    await fetch(`${apiEndpoint}/subscription/update/stripe`, requestOptions)
      .then((response) => response.json())
      .then(async (data) => {
        setloading(false);

        if (true) {
          const form_data = new FormData();
          form_data.append("email", user?.email);
          form_data.append("subscription_plan_name", plan);
          form_data.append("payment_method", data?.paymentMethod.type);
          form_data.append("card_number", data?.paymentMethod.card.last4);
          form_data.append("payment_id", data?.paymentMethod?.id);
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: form_data,
          };
          setloading(true);
          await fetch(
            `${apiEndpoint}/subscription/update/stripe/confirm`,
            requestOptions
          )
            .then((response) => response.json())
            .then(async (data) => {
              console.log(data);
              if (data.status == 1) {
                setloading(false);
                dispatch(updateUser(data.user));
                navigate("/subscription");
                toast.success(data?.message);
              } else {
                setloading(false);
                toast.error(data?.error);
              }
            })
            .catch((err) => {
              console.log(err);
              setloading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  let currentDate = new Date();

  // Add 30 days to the current date
  let futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 30);
  let formattedFutureDate = futureDate.toISOString().split("T")[0];

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            sx={{ display: { xs: "none", lg: "block", position: "relative" } }}
          >
            <SettingSidebar />
            {!subscription && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                  zIndex: 1,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={9}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                color: "black",
                visibility: "hidden",
              }}
            >
              Settings
            </Typography>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                mx: "auto",
                boxShadow: { lg: 1 },
                p: { lg: 4 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "24px", xs: "18px" },
                  fontWeight: 800,
                  color: "#545454",

                  mt: { lg: 5 },
                }}
              >
                <ArrowBackIosNewIcon onClick={BackClick} />
                {t("subscription.title")}
              </Typography>
              {plan == subscription?.plan && (
                <Typography style={{ color: "red" }}>
                  You are already subscribed to this plan
                </Typography>
              )}

              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ mt: { lg: 5 } }}
                >
                  <Grid item xs={12} lg={6} sx={{ p: 0.5 }}>
                    <Grid
                      sx={{
                        border: { lg: "1px solid #DADADA" },
                        borderRadius: "5px",
                        p: 3,
                        minHeight: { lg: "450px" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { lg: "24px", xs: "18px" },
                          fontWeight: 800,
                          color: "black",
                        }}
                      >
                        {t("subscription.payment")}
                      </Typography>

                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="payment-method"
                          name="payment-method"
                          value={selectedOption}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="creditcard"
                            control={
                              <Radio
                                sx={{
                                  color: "#F6B980",
                                  "&.Mui-checked": {
                                    color: "#F6B980",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography component="div">
                                <img
                                  src={CreditcardIcon}
                                  alt="Credit Card"
                                  style={{ marginRight: "2px" }}
                                />
                                Credit Card
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="paypal"
                            control={
                              <Radio
                                sx={{
                                  color: "#F6B980",
                                  "&.Mui-checked": {
                                    color: "#F6B980",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography component="div">
                                <img
                                  src={PayPalIcon}
                                  alt="PayPal"
                                  style={{ marginRight: "2px" }}
                                />
                                PayPal
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      {selectedOption === "creditcard" && (
                        <form onSubmit={handleSubmit}>
                          <Box
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #DADADA",
                              p: 2,
                              my: 1,
                            }}
                          >
                            <CardNumberElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "#424770",
                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                              }}
                            />
                          </Box>

                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid #DADADA",
                                  p: 2,
                                  my: 1,
                                }}
                              >
                                <CardExpiryElement
                                  onChange={(e) => console.log(e)}
                                  options={{
                                    style: {
                                      base: {
                                        fontSize: "16px",
                                        color: "#424770",
                                        "::placeholder": {
                                          color: "#aab7c4",
                                        },
                                      },
                                      invalid: {
                                        color: "#9e2146",
                                      },
                                    },
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid #DADADA",
                                  p: 2,
                                  my: 1,
                                }}
                              >
                                <CardCvcElement
                                  options={{
                                    style: {
                                      base: {
                                        fontSize: "16px",
                                        color: "#424770",
                                        "::placeholder": {
                                          color: "#aab7c4",
                                        },
                                      },
                                      invalid: {
                                        color: "#9e2146",
                                      },
                                    },
                                  }}
                                />
                              </Box>
                            </Grid>
                            {error && (
                              <Typography
                                sx={{ color: "red", textAlign: "center", m: 2 }}
                              >
                                {error}
                              </Typography>
                            )}
                            <Box
                              sx={{
                                justifyContent: "center",
                                textAlign: "center",
                                mx: "auto",
                              }}
                            ></Box>
                          </Grid>
                          <Button
                            disabled={plan == subscription?.plan || !stripe}
                            btnname="button.purchase"
                            type="submit"
                            variant="contained"
                            className="Orangebtn"
                            fullWidth
                            sx={{
                              py: "10px",
                              fontSize: { lg: "16px", xs: "12px" },
                              mt: 7,
                              textTransform: "none",
                            }}
                          >
                            {!loading && t("button.purchase")}
                            {loading && "..."}
                          </Button>
                        </form>
                      )}
                      {selectedOption === "paypal" && (
                        <Paypal
                          plan={plan}
                          loading={loading}
                          setloading={setloading}
                        />
                      )}

                      {/* <PaypalCheckout /> */}
                      {/* <Paypal /> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6} sx={{ p: 0.5 }}>
                    <Grid
                      sx={{
                        border: { lg: "1px solid #DADADA" },
                        borderRadius: "5px",
                        p: 3,
                        minHeight: { lg: "440px" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { lg: "24px", xs: "18px" },
                          fontWeight: 800,
                          color: "black",
                        }}
                      >
                        {t("subscription.summary")}
                      </Typography>

                      {plan == "monthly" && (
                        <Box sx={{ py: 3 }}>
                          {/* Yearly Subscription */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 3,
                            }}
                          >
                            <Box sx={{ flex: "0 0 66.666%" }}>
                              <Typography
                                sx={{
                                  fontSize: { lg: "16px", xs: "18px" },
                                  fontWeight: 800,
                                  color: "black",
                                }}
                              >
                                {t("subscription.monthly_sub")}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                sx={{ m: 0 }}
                              >
                                $9.99 X 1 month
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flex: "0 0 33.333%",
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#6A9B41",
                              }}
                            >
                              $9.99USD
                            </Box>
                          </Box>

                          {/* Total Amount */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 3,
                            }}
                          >
                            <Box sx={{ flex: "0 0 66.666%" }}>
                              <Typography
                                sx={{
                                  fontSize: { lg: "16px", xs: "18px" },
                                  fontWeight: 800,
                                  color: "black",
                                }}
                              >
                                {t("subscription.total")}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flex: "0 0 33.333%",
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#6A9B41",
                              }}
                            >
                              $9.99 USD
                            </Box>
                          </Box>

                          {/* Additional information */}
                          <Typography
                            sx={{
                              fontSize: { lg: "16px", xs: "14px" },
                              color: "black",
                              textAlign: { xs: "center", lg: "left" },
                            }}
                          >
                            {t("subscription.charge_amount_before")}
                            {formattedFutureDate}
                            {t("subscription.charge_amount_after")}
                          </Typography>
                        </Box>
                      )}
                      {plan == "yearly" && (
                        <Box sx={{ py: 3 }}>
                          {/* Yearly Subscription */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 3,
                            }}
                          >
                            <Box sx={{ flex: "0 0 66.666%" }}>
                              <Typography
                                sx={{
                                  fontSize: { lg: "16px", xs: "18px" },
                                  fontWeight: 800,
                                  color: "black",
                                }}
                              >
                                {t("subscription.yearly_sub")}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                sx={{ m: 0 }}
                              >
                                $10 x 12 months
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flex: "0 0 33.333%",
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#6A9B41",
                              }}
                            >
                              $120 USD
                            </Box>
                          </Box>

                          {/* Total Amount */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 3,
                            }}
                          >
                            <Box sx={{ flex: "0 0 66.666%" }}>
                              <Typography
                                sx={{
                                  fontSize: { lg: "16px", xs: "18px" },
                                  fontWeight: 800,
                                  color: "black",
                                }}
                              >
                                {t("subscription.total")}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                flex: "0 0 33.333%",
                                display: "flex",
                                justifyContent: "flex-end",
                                color: "#6A9B41",
                              }}
                            >
                              $120 USD
                            </Box>
                          </Box>

                          {/* Additional information */}
                          <Typography
                            sx={{
                              fontSize: { lg: "16px", xs: "14px" },
                              color: "black",
                              textAlign: { xs: "center", lg: "left" },
                            }}
                          >
                            {t("subscription.charge_amount_before")}
                            {formattedFutureDate}
                            {t("subscription.charge_amount_after")}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};
export default Payment;
