import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardLayout from "../../Layouts/DashboardLayout";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Typography,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InboxMenu from "../../Assets/Images/inboxmenu.png";
import SimpleCheckbox from "../../Components/SimpleCheckBox";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookie from "js-cookie";
import toast from "react-hot-toast";
import { json } from "react-router-dom";
import ConfirmedModal from "../../Components/ConfirmedModal";
import ConfirmDeleteMessageModal from "../../Components/ConfirmDeleteMessageModal";
const options = ["inbox.deletechat"];
const additionalOptions = [
  "dashboard.recieved",
  "dashboard.declined",
  "dashboard.pending",
  "dashboard.confirmed",
];
const deleteChatAfterOptions = ["inbox.7days", "inbox.30days", "inbox.90days"];

const ITEM_HEIGHT = 48;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function Inbox() {
  const [t, i18n] = useTranslation("global");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleListItemClicked = (index) => {};
  const [loading, setloading] = useState(false);
  const [errors, seterrors] = useState(null);
  const [messages, setmessages] = useState([]);

  const user = useSelector((state) => state.user.user);

  const reduxmessages = useSelector((state) => state.messages);
  const [chatmessages, setchatmessages] = useState([]);

  useEffect(() => {
    if (messages.length == 0) {
      setloading(true);
      setmessages([]);
      seterrors(null);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
      };

      fetch(`${apiEndpoint}/closing/messages/${user?.id}`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          for (let key in result.messages) {
            if (result.messages.hasOwnProperty(key)) {
              setmessages((messages) => {
                const keyExists = messages.some(
                  (message) => message.index === key
                );
                if (!keyExists) {
                  return [
                    ...messages,
                    {
                      index: key,
                      additionalText:
                        result?.messages[key][result?.messages[key].length - 1]
                          .text,
                      time: result?.messages[key][
                        result?.messages[key].length - 1
                      ].created_at,
                      listofmessages: result?.messages[key],
                    },
                  ];
                }
                return messages;
              });
            }
          }
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    }
  }, []);

  const handleListItemClick = (item) => {
    setSelectedUser(item);
    setSelectedIndex(item);
    setchatmessages(item.listofmessages);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [deleteChatAfterAnchorEl, setDeleteChatAfterAnchorEl] =
    React.useState(null);

  const handleClick1 = (event) => {
    event.preventDefault();
    setAnchorEl1(event.currentTarget);
  };

  const handleClick2 = (event) => {
    event.preventDefault();
    setAnchorEl2(event.currentTarget);
  };

  const handleClickDeleteChatAfter = (event) => {
    event.preventDefault();
    setDeleteChatAfterAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleCloseDeleteChatAfter = () => {
    setDeleteChatAfterAnchorEl(null);
  };
  const [chat, setChat] = useState(false);
  const [loadingmessage, setloadingmessage] = useState(false);
  const [currentmessage, setcurrentmessage] = useState(null);
  const [currentcompany, setcurrentcompany] = useState(null);

  const [checkedmessages, setcheckedmessages] = useState([]);
  const [checkallmessages, setcheckallmessages] = useState(false);

  const sendtopersonal = (item) => {
    setcurrentmessage(item?.id);
    setloadingmessage(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", user?.email);
    form_data.append("id", item?.id);
    form_data.append("user_id", user?.id);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    fetch(
      `${apiEndpoint}/opportunitymessage/sendtopersonalnumber`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        // console.log(result);
        toast.success("message sent to personal number");
        setloadingmessage(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("some error occured");
        setloadingmessage(false);
      });
  };

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const deletemessage = (item) => {
    // console.log(item);
    setcurrentmessage(item?.id);
    setloadingmessage(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", user?.email);
    form_data.append("id", item?.id);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    fetch(`${apiEndpoint}/opportunitymessage/delete`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        setmessages(
          messages.map((item) => {
            if (item.index == currentcompany) {
              item.listofmessages = item.listofmessages.filter(
                (mess) => mess.id != result.message.id
              );
              setchatmessages(item.listofmessages);
              return item;
            }
            return item;
          })
        );
        toast.success("message deleted");

        setloadingmessage(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("some error occured");
        setloadingmessage(false);
      });
  };

  const deleteSelectedChats = () => {
    // console.log("delete many");
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", user?.email);
    form_data.append("user_id", user?.id);
    form_data.append("companies", JSON.stringify(checkedmessages));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    fetch(`${apiEndpoint}/closing/deleteMany/messages`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        // console.log(result);
        setAnchorEl1(false);
        if (result?.messages?.length > 0) {
          for (let key in result.messages) {
            if (result.messages.hasOwnProperty(key)) {
              setmessages((messages) => [
                ...messages,
                {
                  index: key,
                  additionalText:
                    result?.messages[key][result?.messages[key].length - 1]
                      .text,
                  time: result?.messages[key][result?.messages[key].length - 1]
                    .created_at,
                  listofmessages: result?.messages[key],
                },
              ]);
            }
          }
        } else {
          setmessages([]);
          setchatmessages([]);
          setchatmessages(false);
          setSelectedUser(null);
        }
        toast.success("Chat Deleted");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1, minWidth: "100%" }}>
        <Grid container spacing={2} sx={{ flexGrow: 1, minWidth: "100%" }}>
          {!chat && (
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                boxShadow: 1,
                display: { lg: "none", xs: "block" },
                minWidth: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "32px", xs: "18px" },
                  fontWeight: 800,
                  color: "black",
                  my: 2,
                }}
              >
                {t("inbox.inbox")}
              </Typography>
              <Box sx={{ display: "flex", minWidth: "100%" }}>
                <Box sx={{ flexGrow: 1 }}>
                  <SimpleCheckbox></SimpleCheckbox>
                </Box>
                {/* for searchbar */}
                <Box sx={{ flexGrow: 1 }}>
                  <Search
                    sx={{ border: "1px solid #999", borderRadius: "6px" }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder={t("inbox.search")}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </Box>
                {/* code for first threedots */}

                {/* <Box sx={{}}>
                  <IconButton
                    aria-label="more"
                    id="long-button2"
                    aria-controls={anchorEl2 ? "long-menu2" : undefined}
                    aria-expanded={anchorEl2 ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick2}
                  >
                    <img src={InboxMenu} />
                  </IconButton>
                  <Menu
                    id="long-menu2"
                    MenuListProps={{
                      "aria-labelledby": "long-button2",
                    }}
                    anchorEl={anchorEl2}
                    open={Boolean(anchorEl2)}
                    onClose={handleClose2}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                    disableScrollLock
                  >
                    {additionalOptions.map((option) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={handleClose2}
                      >
                        {t(option)}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box> */}
                {/* code for second threedots */}
                <Box sx={{}}>
                  <IconButton
                    aria-label="more"
                    id="long-button1"
                    aria-controls={anchorEl1 ? "long-menu1" : undefined}
                    aria-expanded={anchorEl1 ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick1}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu1"
                    MenuListProps={{
                      "aria-labelledby": "long-button1",
                    }}
                    anchorEl={anchorEl1}
                    open={Boolean(anchorEl1)}
                    onClose={handleClose1}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                      },
                    }}
                    disableScrollLock
                  >
                    {options.map((option) => (
                      <MenuItem key={option} onClick={deleteSelectedChats}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Menu
                  id="delete-chat-after-menu"
                  anchorEl={deleteChatAfterAnchorEl}
                  open={Boolean(deleteChatAfterAnchorEl)}
                  onClose={handleCloseDeleteChatAfter}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  getContentAnchorEl={null}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                  disableScrollLock
                >
                  {deleteChatAfterOptions.map((option) => (
                    <MenuItem key={option} onClick={handleCloseDeleteChatAfter}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {loading && (
                <>
                  <Typography sx={{ margin: 2 }}>Loading...</Typography>
                </>
              )}
              {!loading && (
                <>
                  <List
                    sx={{
                      height: { lg: "59vh", xs: "70vh" },
                      overflow: "auto",
                      "&::-webkit-scrollbar": {
                        width: "8px", // Width of the scrollbar
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#F4933A", // Color of the thumb
                        borderRadius: "4px", // Rounded corners for the thumb
                        height: "30px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#D6EBCC", // Color of the track
                      },
                    }}
                  >
                    {messages?.length > 0 &&
                      messages?.map((item, index) => (
                        <ListItem
                          button
                          key={item.id}
                          onClick={() => {
                            handleListItemClick(item);
                            setChat(true);
                            setcurrentcompany(item.index);
                          }}
                          sx={{
                            borderRadius: "2px 6px 6px 2px",
                            px: 0,
                          }}
                        >
                          <SimpleCheckbox
                            checked={checkedmessages.some(
                              (msg) => msg == item.index
                            )}
                            onChange={() =>
                              setcheckedmessages((mgs) => {
                                if (mgs.includes(item.index)) {
                                  return mgs.filter((id) => id !== item.index);
                                } else {
                                  return [...mgs, item.index];
                                }
                              })
                            }
                          />
                          <ListItemText
                            primary={
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <strong>{item.index}</strong>
                                <Typography sx={{ fontSize: "12px", mr: 3 }}>
                                  {item.time}
                                </Typography>
                              </Grid>
                            }
                            secondary={item.additionalText}
                          />
                        </ListItem>
                      ))}
                  </List>
                </>
              )}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            lg={4}
            sx={{ boxShadow: 1, display: { lg: "block", xs: "none" } }}
          >
            <Typography
              sx={{
                fontSize: { lg: "32px", xs: "18px" },
                fontWeight: 800,
                color: "black",
                my: 2,
              }}
            >
              {t("inbox.inbox")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <SimpleCheckbox
                onChange={(e) => {
                  if (e.target.checked) {
                    messages.map((msg) => {
                      if (!checkedmessages.includes(msg.index)) {
                        setcheckedmessages((mgs) => [...mgs, msg.index]);
                      }
                    });
                  } else {
                    setcheckedmessages([]);
                  }
                  setcheckallmessages(e.target.checked);
                }}
              ></SimpleCheckbox>
              {/* for searchbar */}
              <Box sx={{ flexGrow: 1 }}>
                <Search sx={{ border: "1px solid #999", borderRadius: "6px" }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t("inbox.search")}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </Box>
              {/* code for first threedots */}

              <Box sx={{}}>
                <IconButton
                  aria-label="more"
                  id="long-button2"
                  aria-controls={anchorEl2 ? "long-menu2" : undefined}
                  aria-expanded={anchorEl2 ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick2}
                >
                  <img src={InboxMenu} />
                </IconButton>
                <Menu
                  id="long-menu2"
                  MenuListProps={{
                    "aria-labelledby": "long-button2",
                  }}
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  onClose={handleClose2}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                  disableScrollLock
                >
                  {additionalOptions.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={handleClose2}
                    >
                      {t(option)}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/* code for second threedots */}
              <Box sx={{}}>
                <IconButton
                  aria-label="more"
                  id="long-button1"
                  aria-controls={anchorEl1 ? "long-menu1" : undefined}
                  aria-expanded={anchorEl1 ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick1}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu1"
                  MenuListProps={{
                    "aria-labelledby": "long-button1",
                  }}
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleClose1}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                  disableScrollLock
                >
                  {options.map((option) => (
                    <MenuItem key={option} onClick={deleteSelectedChats}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              {/* <Menu
                id="delete-chat-after-menu"
                anchorEl={deleteChatAfterAnchorEl}
                open={Boolean(deleteChatAfterAnchorEl)}
                onClose={handleCloseDeleteChatAfter}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                getContentAnchorEl={null}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
                disableScrollLock
              >
                {deleteChatAfterOptions.map((option) => (
                  <MenuItem key={option} onClick={handleCloseDeleteChatAfter}>
                    {t(option)}
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>

            {loading && (
              <>
                <Typography sx={{ margin: 2 }}>Loading...</Typography>
              </>
            )}

            {!loading && (
              <List
                sx={{
                  height: { lg: "59vh", xs: "70vh" },
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px", // Width of the scrollbar
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#F4933A", // Color of the thumb
                    borderRadius: "4px", // Rounded corners for the thumb
                    height: "30px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#D6EBCC", // Color of the track
                  },
                }}
              >
                {messages?.length > 0 &&
                  messages?.map((item) => (
                    <ListItem
                      button
                      key={item.id}
                      onClick={() => {
                        handleListItemClick(item);
                        setcurrentcompany(item.index);
                      }}
                      sx={{
                        borderRadius: "2px 6px 6px 2px",
                        px: 0,
                        borderLeft:
                          selectedIndex === item ? "4px solid #F26F21" : "none",
                      }}
                    >
                      <SimpleCheckbox
                        checked={checkedmessages.some(
                          (msg) => msg == item.index
                        )}
                        onChange={() =>
                          setcheckedmessages((mgs) => {
                            if (mgs.includes(item.index)) {
                              return mgs.filter((id) => id !== item.index);
                            } else {
                              return [...mgs, item.index];
                            }
                          })
                        }
                      />
                      <ListItemText
                        primary={
                          <Grid
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <strong>{item.index}</strong>
                            <Typography sx={{ fontSize: "12px", mr: 3 }}>
                              {item.time}
                            </Typography>
                          </Grid>
                        }
                        secondary={item.additionalText}
                      />
                    </ListItem>
                  ))}
              </List>
            )}
          </Grid>
          {/* for mobile */}
          {!loading && selectedUser && (
            <>
              {chat && (
                <Grid
                  sx={{ display: { lg: "none", xs: "block" } }}
                  item
                  xs={12}
                  lg={8}
                >
                  {loadingmessage && ( // Conditional rendering of loader
                    <Box
                      sx={{
                        zIndex: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        backgroundColor: "black",
                        transform: "translate(-50%, -50%)",
                        top: "50%",
                        left: "50%",
                        opacity: 0.8,
                        width: { lg: "100%", xs: "100%" },
                        height: { lg: "100%", xs: "100%" },
                      }}
                    >
                      <CircularProgress sx={{ color: "#F26F21" }} size={80} />
                    </Box>
                  )}

                  <Typography
                    className="user"
                    sx={{
                      backgroundColor: "#F8F9FA",
                      px: 3,
                      py: 2,
                      border: "1px solid black",
                    }}
                  >
                    <ArrowBackIosNewIcon
                      sx={{ mx: 1 }}
                      onClick={() => setChat(false)}
                    />
                    <strong>{selectedUser.index}</strong>
                  </Typography>
                  <Grid
                    xs={12}
                    lg={12}
                    sx={{
                      height: { lg: "59vh", xs: "70vh" },
                      overflow: "auto",
                      "&::-webkit-scrollbar": {
                        width: "8px", // Width of the scrollbar
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#F4933A", // Color of the thumb
                        borderRadius: "4px", // Rounded corners for the thumb
                        height: "30px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#D6EBCC", // Color of the track
                      },
                    }}
                  >
                    <Grid xs={10} lg={7} sx={{ px: 2 }}>
                      {chatmessages?.length > 0 &&
                        chatmessages?.map((item, index) => {
                          return (
                            <>
                              <Typography
                                sx={{
                                  p: 3,
                                  boxShadow: 1,
                                  my: 2,
                                  fontSize: { lg: "16px", xs: "12px" },
                                  borderRadius: 1,
                                  position: "relative", // Ensure the parent has relative positioning
                                }}
                              >
                                {loadingmessage &&
                                  currentmessage == item?.id && ( // Conditional rendering of loader
                                    <Box
                                      sx={{
                                        zIndex: 3,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "absolute", // Absolute positioning to overlap text
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0.8,
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.7)", // Optional: Semi-transparent background to highlight loader
                                      }}
                                    >
                                      <CircularProgress
                                        sx={{ color: "#F26F21" }}
                                        size={40}
                                      />
                                    </Box>
                                  )}
                                {item?.text}
                              </Typography>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {item?.created_at}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deletemessage(item)}
                                  >
                                    {t("inbox.delete")}
                                  </Typography>
                                  <Typography
                                    onClick={() => sendtopersonal(item)}
                                    sx={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                      ml: 2,
                                    }}
                                  >
                                    {t("inbox.sentopersonalnumber")}
                                  </Typography>
                                </Box>
                              </Box>
                            </>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid
                sx={{ display: { lg: "block", xs: "none" } }}
                item
                xs={12}
                lg={8}
              >
                <Typography
                  className="user"
                  sx={{
                    backgroundColor: "#F8F9FA",
                    px: 3,
                    py: 2,
                    border: "1px solid black",
                  }}
                >
                  <strong>{selectedUser.index}</strong>
                </Typography>

                <Grid
                  xs={12}
                  lg={12}
                  sx={{
                    height: { lg: "59vh", xs: "70vh" },
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px", // Width of the scrollbar
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#F4933A", // Color of the thumb
                      borderRadius: "4px", // Rounded corners for the thumb
                      height: "30px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#D6EBCC", // Color of the track
                    },
                  }}
                >
                  <Grid xs={10} lg={10} sx={{ p: 3 }}>
                    {chatmessages?.length > 0 &&
                      chatmessages?.map((item, index) => {
                        return (
                          <>
                            <Typography
                              sx={{
                                p: 3,
                                boxShadow: 1,
                                my: 2,
                                fontSize: { lg: "16px", xs: "12px" },
                                borderRadius: 1,
                                position: "relative", // Ensure the parent has relative positioning
                              }}
                            >
                              {loadingmessage &&
                                currentmessage == item?.id && ( // Conditional rendering of loader
                                  <Box
                                    sx={{
                                      zIndex: 3,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      position: "absolute", // Absolute positioning to overlap text
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      opacity: 0.8,
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.7)", // Optional: Semi-transparent background to highlight loader
                                    }}
                                  >
                                    <CircularProgress
                                      sx={{ color: "#F26F21" }}
                                      size={40}
                                    />
                                  </Box>
                                )}
                              {item?.text}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Typography sx={{ fontSize: "12px" }}>
                                  {item?.created_at}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setConfirmDeleteModal(true);
                                    setMessageToDelete(item);
                                  }}
                                >
                                  {t("inbox.delete")}
                                </Typography>
                                <Typography
                                  onClick={() => sendtopersonal(item)}
                                  sx={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    ml: 2,
                                  }}
                                >
                                  {t("inbox.sentopersonalnumber")}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {confirmDeleteModal && (
          <ConfirmDeleteMessageModal
            open={confirmDeleteModal}
            handleClose={setConfirmDeleteModal}
            parentNotify={() => {
              if (messageToDelete) {
                deletemessage(messageToDelete);
              }
            }}
          ></ConfirmDeleteMessageModal>
        )}
      </Box>
    </DashboardLayout>
  );
}