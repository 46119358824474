import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import GoogleIcon from "@mui/icons-material/Google";

const REACT_APP_GOSIGNING_FRONTEND = process.env.REACT_APP_GOSIGNING_FRONTEND;
// const REACT_APP_GOSIGNING_FRONTEND = 'https://test.gosignings.com';

export default function GoogleCalendar({
  sx,
  onEmailSelect,
  activity,
  setFormData,
  formData,
  index,
}) {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  const [selectedEmails, setSelectedEmails] = useState([]);
  const maxEmails = 1;

  const handleAuthorization = () => {
    const redirectUri = encodeURIComponent(REACT_APP_GOSIGNING_FRONTEND);
    const clientId = encodeURIComponent(
      "291894858714-fd6brvd4u32ke1atopchjo24a1pe4mu6.apps.googleusercontent.com"
    );
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/calendar " +
        "https://www.googleapis.com/auth/userinfo.email " +
        "https://www.googleapis.com/auth/userinfo.profile " +
        "https://www.googleapis.com/auth/gmail.readonly " +
        "https://www.googleapis.com/auth/gmail.modify"
    );

    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&prompt=${prompt}&response_type=${responseType}&client_id=${clientId}&scope=${scope}&access_type=${accessType}`;

    // Open a new window for Google authentication
    const authWindow = window.open(authUrl, "_blank", "width=500,height=600");

    // Polling to check if the URL in the popup window has changed
    const interval = setInterval(() => {
      try {
        if (authWindow.location.href.includes(REACT_APP_GOSIGNING_FRONTEND)) {
          clearInterval(interval);
          const urlParams = new URLSearchParams(authWindow.location.search);
          const authorizationCode = urlParams.get("code");
          // console.log("Authorization Code:", authorizationCode);
          exchangeAuthorizationCodeForTokens(authorizationCode);
          authWindow.close();
        }
      } catch (error) {
        // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
      }
    }, 1000);
  };

  const exchangeAuthorizationCodeForTokens = async (authorizationCode) => {
    const redirectUri = REACT_APP_GOSIGNING_FRONTEND;
    const clientId =
      "291894858714-fd6brvd4u32ke1atopchjo24a1pe4mu6.apps.googleusercontent.com";
    const clientSecret = "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"; // Replace with your actual client secret
    const tokenUrl = "https://oauth2.googleapis.com/token";

    const body = new URLSearchParams({
      code: authorizationCode,
      redirect_uri: redirectUri,
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: "authorization_code",
    });

    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body.toString(),
      });

      const data = await response.json();
      fetchUserData(data.access_token, data.refresh_token);
    } catch (error) {
      console.error("Error exchanging authorization code for tokens:", error);
    }
  };

  const fetchUserData = async (accessToken, refresh_token) => {
    const userInfoUrl =
      "https://www.googleapis.com/oauth2/v1/userinfo?alt=json";

    try {
      const response = await fetch(userInfoUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const userData = await response.json();
      // console.log("User Data:", userData);
      if (selectedEmails.length < maxEmails) {
        // Check if the email already exists in the selectedEmails array
        if (!selectedEmails.includes(userData.email)) {
          if (activity == "mail") {
            let mailAccounts = [...formData.mail_accounts];
            mailAccounts[index] = {
              ...mailAccounts[index],
              account_type: "google",
              account_email: userData.email,
              refresh_token: refresh_token,
            };

            setFormData((prevData) => ({
              ...prevData,
              mail_accounts: mailAccounts,
            }));
          } else if (activity == "calendar") {
            setSelectedEmails((prevEmails) => [...prevEmails, userData.email]); // Add the selected email to the array
            onEmailSelect(userData.email, refresh_token); // Pass the selected emails to the parent component
          }
        } else {
          // console.log("Email already selected.");
        }
      }
    } catch (error) {
      // console.error("Error fetching user data:", error);
    }
  };

  const handleLogin = async () => {
    try {
      handleAuthorization();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const canAddMoreEmails = selectedEmails.length < maxEmails;

  return (
    <>
      {activity == "calendar" && (
        <Box
          sx={{
            "& button": {
              my: 1,
              backgroundColor: canAddMoreEmails ? "#F26F21" : "grey",
              pointerEvents: canAddMoreEmails ? "auto" : "none",
            },
          }}
        >
          <div>
            <Typography
              className="google"
              onClick={canAddMoreEmails ? handleLogin : null}
              sx={{
                fontSize: "14px",
                color: canAddMoreEmails ? "#F26F21" : "#8C8C8C",
                display: "flex",
                alignItems: "center",
                mr: 3,
                ...sx,
                cursor: canAddMoreEmails ? "pointer" : "not-allowed",
              }}
            >
              Add
            </Typography>
          </div>
        </Box>
      )}
      {activity == "mail" && (
        <GoogleIcon onClick={handleLogin} sx={{ cursor: "pointer", ml: 2 }} />
      )}
    </>
  );
}
