import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import SettingSidebar from "../../Components/SettingSidebar";
import CustomField from "../../Components/CustomField";
import Buttons from "../../Components/Button";
import photo from "../../Assets/Images/photoplaceholder.png";
import BillingTable from "../../Components/BillingTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const BillingHistory = () => {
  const [t, i18n] = useTranslation("global");
  const BackClick = () => {
    window.history.back();
  };

  const [loading, setloading] = useState(false);

  const [billingdata, setbillingdata] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setloading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", Cookie.get("user_email"));
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/subscription/history`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setloading(false);
          navigate("/unauthorized", { state: { propValue: "Unauthorized" } });
        }
      })
      .then((result) => {
        setbillingdata(result);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
        console.error(error);
      });
  }, []);

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ display: { xs: "none", lg: "block" } }}>
            <SettingSidebar />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                color: "black",
                visibility: "hidden",
              }}
            >
              Settings
            </Typography>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                mx: "auto",
                boxShadow: { lg: 1 },
                p: { lg: 4 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "32px", xs: "18px" },
                  fontWeight: 800,
                  color: "#545454",
                }}
              >
                <ArrowBackIosNewIcon onClick={BackClick} />
                {t("billing.title")}
              </Typography>
              <BillingTable loading={loading} billingdata={billingdata} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default BillingHistory;
