import * as React from "react";
import ModalLayout from "../Layouts/ModalLayout";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import Button from "../Components/Button";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export default function DecliendModal({
  open,
  handleClose,
  parentNotify,
  setDeclineReason,
  declineReason,
}) {
  const [t, i18n] = useTranslation("global");
  const [opened, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    reason: "",
  });
  const [isFormValid, setIsFormValid] = React.useState(false);

  React.useEffect(() => {
    // Check if all required fields are filled
    setIsFormValid(declineReason?.trim() !== "");
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleDecline();
  };

  const handleDecline = async () => {
    await parentNotify(); // Notify the parent component
    handleClose(); // Close the modal in the parent component
  };

  const theme = useTheme();
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid lg={8} xs={10} sx={{ m: "auto" }}>
        <form onSubmit={handleSubmit}>
          <Typography
            sx={{
              fontSize: { lg: "32px", xs: "18px" },
              fontWeight: 600,
              color: "white",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            {t("decline_modal.givereason")}{" "}
            <span style={{ color: "#FE1D1D" }}>
              {t("decline_modal.declineorder")}{" "}
            </span>
          </Typography>
          <TextField
            fullWidth
            required
            name="reason"
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
            label={t("rejection.reason")}
            placeholder={t("rejection.reason_placeholder")}
            multiline
            rows={4}
            variant="outlined"
            sx={{
              borderRadius: "11px",
              my: 3,
              backgroundColor: "#303030",
              border: "1px solid #8C8C8C",
              color: "white", // This sets the color of the label text
              "& .MuiInputBase-input": {
                color: "white", // This sets the input text color
              },
              "& .MuiInputBase-input::placeholder": {
                color: "#545454", // This sets the placeholder color
                opacity: 1, // Ensures the placeholder is fully opaque
              },
            }}
          />

          <Grid
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              btnname="button.cancel"
              type="button"
              onClick={handleClose}
              variant="contained"
              className=""
              sx={{
                borderRadius: "8px",
                px: { lg: 7, xs: 3 },
                py: 1.2,
                fontSize: { lg: "16px", xs: "12px" },
                mx: 1,
                color: "white",
                transition: "background-color 0.5s ease, color 0.7s ease",
                backgroundColor: "#83C066",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#83C066",
                },
              }}
            />
            <Button
              btnname="button.decline"
              type="submit"
              variant="contained"
              className="Orangebtn"
              disabled={!isFormValid}
              sx={{
                px: { lg: 7, xs: 3 },
                py: 1.2,
                fontSize: { lg: "16px", xs: "12px" },
                mx: 1,
              }}
            />
          </Grid>
        </form>
      </Grid>
    </ModalLayout>
  );
}
