import React, { useEffect, useState } from "react";
import { postRequest, getRequest } from "../../Requests/Requests";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import SigningAgencyForm from "./SigningAgencyForm";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RegexGenerator from "./RegexGenerator";
import ScrapingFieldsForm from "./ScrapingFieldsForm ";
import IdentificationForm from "./IdentificationForm";
import ScrapFieldOriginForm from "./ScrapFieldOriginForm";
import MuiAlert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const actions = [
  { label: "Visit Link", value: "visitLink" },
  { label: "Click", value: "click" },
  { label: "Type Input", value: "typeinput" },
  // { label: "Check Checkbox", value: "checkcheckbox" },
  // { label: "UnCheck Checkbox", value: "uncheckcheckbox" },
  // { label: "Check Radio Button", value: "checkradiobutton" },
  // { label: "UnCheck Radio Button", value: "uncheckradiobutton" },
  { label: "Scrap Fields", value: "scraptextfields" },
  { label: "Wait and Analyze", value: "waitandanalyze" },
  { label: "Close Browser", value: "closebrowser" },
];
const selectors = [
  { label: "Id", value: "id" },
  { label: "Class", value: "class" },
  // { label: "Tag Name", value: "tagName" },
];
const FlowTypes = [
  { label: "Invitation Message", value: "invitationmessage" },
  { label: "Confirmation message", value: "confirmationmessage" },
  { label: "Invitation Email", value: "invitationemail" },
  { label: "Confirmation Email", value: "confirmationemail" },
  // { label: "Browser", value: "browser" },
];
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const adminToken = process.env.REACT_APP_ADMIN_TOKEN;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BrowserAutomation() {
  const [authorized, setauthorized] = useState(false);
  const { token } = useParams();
  const handleHash = async () => {
    const encoder = new TextEncoder();
    const data = encoder.encode(token);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    setauthorized(hashHex == adminToken);
  };
  useEffect(() => {
    handleHash();
  }, []);

  const [agencies, setAgencies] = useState([]);
  const [actionSequence, setActionSequence] = useState([]);
  const [message, setMessage] = useState("");

  const [selectedAgency, setselectedAgency] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectorType, setSelectorType] = useState(null);
  const [selectorValue, setSelectorValue] = useState(null);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [scrapFieldOrigins, setScrapFieldOrigins] = useState([]);
  const [flowType, setFlowType] = useState("invitationmessage");

  const [scrapFieldsModalOpen, setScrapFieldsModalOpen] = useState(false);
  const [scrapFieldsSelected, setScrapFieldsSelected] = useState([]);
  const [tempScrapFields, setTempScrapFields] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllSigningAgencies();
  }, []);

  const getAllSigningAgencies = async () => {
    try {
      setLoading(true);
      getRequest("/get/signing/Agencies/all")
        .then((data) => setAgencies(data?.agencies))
        .catch((e) => console.error(e));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (actionSequence.length > 0) {
      actionSequence.map((item) => {
        if (item.type == "scraptextfields") {
          const values = Array.isArray(item?.value)
            ? item.value
            : typeof item?.value === "string"
            ? item.value.split("#")
            : [];
          setScrapFieldsSelected((prevSelected) => {
            const uniqueValues = values.filter(
              (val) => !prevSelected.includes(val)
            );
            return [...prevSelected, ...uniqueValues];
          });
        }
      });
    }
  }, [actionSequence]);

  useEffect(() => {
    getScrapFields();
  }, [selectedAgency, flowType]);

  const getScrapFields = () => {
    if (selectedAgency) {
      setLoading(true);
      axios
        .get(
          `${apiEndpoint}/scrap-fields-sources/browser/${selectedAgency}/${flowType}`
        )
        .then((response) => {
          setLoading(false);
          setScrapFieldOrigins(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching scrap field origins:", error);
          setError(true);
          setMessage("Failed to fetch scrap field origins.");
        });

      getActionSequencesByAgencyId(selectedAgency);
      setLoading(false);
    } else {
      setScrapFieldOrigins([]); // Clear scrap field origins if no agency is selected
    }
  };

  const getActionSequencesByAgencyId = async (agencyId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiEndpoint}/browser-automation-steps/agency/${agencyId}/${flowType}`
      );
      setLoading(false);
      setActionSequence(response.data);
    } catch (error) {
      setLoading(false);
      console.error(
        "Error fetching action sequences:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  let actionButtonClick = async (item) => {
    if (!selectedAgency) {
      setError("select signing agency!");
      return;
    }
    if (
      actionSequence.length > 0 &&
      actionSequence[actionSequence.length - 1]?.type == "closebrowser"
    ) {
      alert("Can't add more, You've ended sequence");
      return;
    } else if (
      item.value == "closebrowser" ||
      item.value == "waitandanalyze" ||
      item.value == "visitLink"
    ) {
      const seq = await addActionSequenceDB({
        type: item.value,
        selector: null,
        value: null,
        url: null,
        sequence_no: actionSequence.length,
        agency_id: selectedAgency,
        flowType,
        acceptReject: null,
      });
      if (seq) {
        setActionSequence([...actionSequence, seq]);
      }

      return;
    } else if (item.value == "scraptextfields") {
      setScrapFieldsModalOpen(true);
      setSelectedAction(item.value);
      return;
    }
    setOpen(true);
    setSelectedAction(item.value);
  };

  /////////////////////db crud////////////////////

  const updateActionSequenceByIdDB = async (id, updatedData) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${apiEndpoint}/browser-automation-steps/${id}`,
        updatedData
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(
        "Error updating action sequence:",
        error.response ? error.response.data : error.message
      );
      throw error; // Re-throw the error for further handling if needed
    }
  };

  const addActionSequenceDB = async (seq) => {
    try {
      setLoading(true);
      const result = await axios.post(
        `${apiEndpoint}/browser-automation-steps`,
        {
          actionSequence: seq,
        }
      );
      setLoading(false);
      return result.data;
    } catch (e) {
      setLoading(false);
      console.error(e);
    } finally {
    }
  };

  const deleteActionSequenceDB = async (seq) => {
    try {
      setLoading(true);
      const result = await axios.delete(
        `${apiEndpoint}/browser-automation-steps/${seq.id}`
      );
      setLoading(false);
      return result.data;
    } catch (e) {
      setLoading(false);
      console.error(e);
      return null;
    }
  };

  const remainingScrapFields = scrapFieldOrigins.filter((item) => {
    return !scrapFieldsSelected.some((fi) => Number(fi) === item.id);
  });

  return (
    <>
      {authorized ? (
        <>
          {loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                position: "fixed",
                width: "100vw",
                zIndex: 99,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
                <Typography sx={{ mt: 2 }}>Processing...</Typography>
              </Box>
            </Box>
          )}
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={8}>
              <Typography sx={{ my: 1 }}>
                Step to adding dynamic signing agency flow
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>add/update/delete signing agency</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SigningAgencyForm />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={8}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>
                    add/update/setup identifications for email and messages
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <IdentificationForm />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={8}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>
                    Define where you will get required fields from
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ScrapFieldOriginForm />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={8}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>add/update scrap fields</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ScrapingFieldsForm />
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={8}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>setup browser automation flow</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid p={2}>
                    <Grid sx={{ display: "flex" }}>
                      <Grid item xs={6}>
                        <TextField
                          label="Select Flow Type"
                          select
                          fullWidth
                          margin="normal"
                          name="flowType"
                          value={selectedAgency}
                          onChange={(e) => {
                            setselectedAgency(e.target.value);
                            setError(null);
                            if (actionSequence.length > 0) {
                              const confirmation = window.confirm(
                                "Do you want to change? current sequence will be erased"
                              );
                              if (confirmation) {
                                setActionSequence([]);
                              }
                            }
                          }}
                          required
                        >
                          {agencies &&
                            agencies.length > 0 &&
                            agencies.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Select Flow Type"
                          select
                          fullWidth
                          margin="normal"
                          name="flowType"
                          value={flowType}
                          onChange={(e) => {
                            setFlowType(e.target.value);
                            setActionSequence([]);
                          }}
                          required
                        >
                          {FlowTypes.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Box my={1}>
                      {error && <Typography color={"red"}>{error}</Typography>}
                    </Box>

                    <Box my={2}>
                      {actions.map((item) => {
                        return (
                          <Button
                            sx={{ margin: 1 }}
                            onClick={() => actionButtonClick(item)}
                            variant="contained"
                          >
                            {item.label}
                          </Button>
                        );
                      })}
                    </Box>

                    {(flowType == "invitationmessage" ||
                      flowType == "invitationemail") &&
                      remainingScrapFields.length > 0 && (
                        <>
                          <Typography sx={{ my: 1 }}>
                            You've defined of scrapping following fields from
                            browser please specifify them in browser automation
                            sequence
                          </Typography>
                          <Box sx={{ my: 2, display: "flex" }}>
                            {remainingScrapFields.map((item) => {
                              return (
                                <>
                                  <Typography sx={{ m: 1, color: "red" }}>
                                    {item.field_name}
                                  </Typography>
                                </>
                              );
                            })}
                          </Box>
                        </>
                      )}

                    <Box my={2} display={"flex"} flexWrap={"wrap"}>
                      {actionSequence &&
                        actionSequence.map((item, index) => {
                          return (
                            <>
                              <Box my={1}>
                                <>
                                  <Box>
                                    <Box sx={{ display: "flex" }}>
                                      {item.type !== "visitLink" && (
                                        <Box>
                                          <Typography>
                                            {
                                              actions.filter(
                                                (act) => act.value == item.type
                                              )[0]?.label
                                            }
                                          </Typography>
                                          {item.selector !== null && (
                                            <Typography>
                                              {" "}
                                              {item.selector} = {item.value}
                                            </Typography>
                                          )}
                                          {item.selector == null &&
                                            item.type == "scraptextfields" && (
                                              <>
                                                {(Array.isArray(item?.value)
                                                  ? item.value
                                                  : typeof item?.value ===
                                                    "string"
                                                  ? item.value.split("#")
                                                  : []
                                                ).map((si) => {
                                                  return (
                                                    <>
                                                      {" "}
                                                      <Box
                                                        sx={{ display: "flex" }}
                                                      >
                                                        <Typography
                                                          sx={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          {si
                                                            ? scrapFieldOrigins.filter(
                                                                (item) =>
                                                                  item.id == si
                                                              )[0]?.field_name
                                                            : ""}
                                                        </Typography>
                                                      </Box>
                                                    </>
                                                  );
                                                })}
                                              </>
                                            )}
                                        </Box>
                                      )}
                                      {item.type === "visitLink" && (
                                        <Box>
                                          <Typography>{item.type}</Typography>
                                          <Typography>{item.url}</Typography>
                                        </Box>
                                      )}

                                      {item.type !== "closebrowser" && (
                                        <Box
                                          sx={{
                                            p: 1,
                                            mx: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <ArrowCircleRightIcon />
                                        </Box>
                                      )}
                                    </Box>
                                    <Box>
                                      {index > 0 &&
                                        actionSequence
                                          .slice(0, index)
                                          .some(
                                            (action) =>
                                              action.type === "waitandanalyze"
                                          ) &&
                                        item.type != "closebrowser" && (
                                          <RadioGroup
                                            row
                                            value={item.acceptReject || ""}
                                            onChange={async (e) => {
                                              const result =
                                                await updateActionSequenceByIdDB(
                                                  item.id,
                                                  {
                                                    ...item,
                                                    acceptReject:
                                                      e.target.value,
                                                  }
                                                );

                                              if (result) {
                                                const updatedSequence =
                                                  actionSequence.map((action) =>
                                                    action === item
                                                      ? result
                                                      : action
                                                  );
                                                setActionSequence(
                                                  updatedSequence
                                                );
                                              }
                                            }}
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <FormControlLabel
                                              value="Accepted"
                                              control={<Radio />}
                                              label="Accepted"
                                            />
                                            <FormControlLabel
                                              value="Rejected"
                                              control={<Radio />}
                                              label="Rejected"
                                            />
                                          </RadioGroup>
                                        )}
                                    </Box>
                                    <Box>
                                      {index == actionSequence.length - 1 && (
                                        <DeleteIcon
                                          sx={{ cursor: "pointer" }}
                                          onClick={async () => {
                                            const seq =
                                              await deleteActionSequenceDB(
                                                item
                                              );
                                            if (seq) {
                                              if (
                                                seq.type == "scraptextfields"
                                              ) {
                                                setScrapFieldsSelected(
                                                  scrapFieldsSelected.filter(
                                                    (scrapItem) =>
                                                      !(
                                                        Array.isArray(
                                                          seq?.value
                                                        )
                                                          ? seq.value
                                                          : typeof seq?.value ===
                                                            "string"
                                                          ? seq.value.split("#")
                                                          : []
                                                      )?.some(
                                                        (i) => i == scrapItem
                                                      )
                                                  )
                                                );
                                              }
                                              setActionSequence((array) =>
                                                array.slice(0, -1)
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                </>
                              </Box>
                            </>
                          );
                        })}
                    </Box>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Select the css selector.
                          </Typography>
                          <Select
                            onChange={(e) => setSelectorType(e.target.value)}
                            sx={{ width: "100%" }}
                          >
                            {selectors.map((item) => {
                              return (
                                <MenuItem value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Type value
                          </Typography>
                          <Input
                            value={selectorValue}
                            onChange={(e) => {
                              setSelectorValue(e.target.value);
                            }}
                            sx={{ width: "100%" }}
                            type="text"
                          ></Input>
                        </Box>
                        <Box my={1}>
                          <Button
                            onClick={async () => {
                              const seq = await addActionSequenceDB({
                                type: selectedAction,
                                selector: selectorType,
                                value: selectorValue,
                                url: url,
                                sequence_no: actionSequence.length,
                                agency_id: selectedAgency,
                                flowType,
                                acceptReject: null,
                              });
                              if (seq) {
                                setOpen(false);
                                setError(null);
                                setActionSequence([...actionSequence, seq]);
                                setSelectedAction(null);
                                setSelectorType(null);
                                setSelectorValue(null);
                                setUrl(null);
                              }
                            }}
                            variant="contained"
                          >
                            {loading ? (
                              <CircularProgress
                                sx={{ color: "white", mx: 2 }}
                                size={20}
                              />
                            ) : (
                              <Typography>Save Step</Typography>
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Modal>

                    <Modal
                      open={scrapFieldsModalOpen}
                      onClose={() => setScrapFieldsModalOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Box>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Select from fields.
                          </Typography>
                          <Select
                            onChange={(e) => {
                              setTempScrapFields([
                                ...tempScrapFields,
                                ...e.target.value,
                              ]);
                            }}
                            multiple
                            value={tempScrapFields}
                            sx={{ width: "100%" }}
                          >
                            {remainingScrapFields.map((item) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.field_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Box>
                        <Box my={1}>
                          {tempScrapFields.length > 0 && (
                            <Button
                              onClick={async () => {
                                const seq = await addActionSequenceDB({
                                  type: selectedAction,
                                  selector: null,
                                  value: tempScrapFields,
                                  url: null,
                                  sequence_no: actionSequence.length,
                                  agency_id: selectedAgency,
                                  flowType,
                                  acceptReject: null,
                                });
                                if (seq) {
                                  setScrapFieldsModalOpen(false);
                                  setError(null);
                                  setActionSequence([...actionSequence, seq]);
                                  setScrapFieldsSelected([
                                    ...scrapFieldsSelected,
                                    ...tempScrapFields,
                                  ]);
                                  setTempScrapFields([]);
                                  setSelectedAction(null);
                                  setSelectorType(null);
                                  setSelectorValue(null);
                                  setUrl(null);
                                }
                              }}
                              variant="contained"
                            >
                              {loading ? (
                                <CircularProgress
                                  sx={{ color: "white", mx: 2 }}
                                  size={20}
                                />
                              ) : (
                                <Typography>Save Step</Typography>
                              )}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Modal>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <Typography>unauthorized access</Typography>
          </Grid>
        </>
      )}
    </>
  );
}
