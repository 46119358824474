import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import React from "react";
import Button from "./Button";
import PopoverHover from "./Popover";
import { useState, useEffect } from "react";
import RowRadioButtonsGroup from "./RowRadioButtonsGroup";
import ButtonGroup from "@mui/material/ButtonGroup";
import CustomSelect from "./CustomSelect";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { updateStepper1 } from "../Redux/Slices/preferenceSlice";
import toast from "react-hot-toast";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const Region = [
  { label: "None", value: "" },
  { label: "Ten", value: "10" },
  { label: "Twenty", value: "20" },
  { label: "Thirty", value: "30" },
];
const Number = [
  { label: "None", value: "" },
  { label: "23", value: "10" },
  { label: "54", value: "20" },
  { label: "65", value: "30" },
];
const UserPrefrenceStep1 = ({
  onBack,
  onNext,
  lg = 7,
  // formData,
  // onFormChange,
}) => {
  const [t, i18n] = useTranslation("global");
  const [voipMsgError, setVoipMsgError] = useState(false);
  const [deleteMsgError, setDeleteMsgError] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const reduxFormData = useSelector((state) => state.preferences.stepper1);

  const [formData, setFormData] = useState(
    reduxFormData ?? {
      select_number: "",
      voipmsg: "",
      delete_msg: "",
    }
  );

  const onFormChange = (formKey, fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (!reduxFormData) {
      setIsReady(formData.voipmsg && formData.delete_msg);
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", Cookie.get("user_email"));
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/get/stepper1`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          if (result.error) {
            setLoading(false);
          } else {
            setLoading(false);
            if (result?.data) {
              setFormData(result?.data);
              dispatch(updateStepper1({ ...result?.data }));
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, []);

  const handleNext = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("data", JSON.stringify(formData));
    form_data.append("email", Cookie.get("user_email"));
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/preferences/update/stepper2`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        if (result.error) {
          toast.error(result.error);
          setLoading(false);
        } else {
          setLoading(false);
          if (result?.data) {
            setFormData(result.data);
            dispatch(updateStepper1({ ...result?.data }));
            toast.success("Updated");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const [selectedValueState, setSelectedValueState] =
    React.useState("default_value");
  const [selectedmsgState, setSelectedmsgState] =
    React.useState("default_value");
  const VOIPmsg = [
    { value: "Get all Messages", label: t("prefrence.voipmsg1") },
    { value: "Get Selected Messages", label: t("prefrence.voipmsg2") },
  ];
  const deletemsg = [
    { value: "7 Days", label: t("inbox.7days") },
    { value: "30 Days", label: t("inbox.30days") },
    { value: "90 Days", label: t("inbox.90days") },
    { value: "none", label: t("prefrence.none") },
  ];
  return (
    <Container sx={{ position: "relative" }}>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "transparent",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <Grid container>
        <Grid
          item
          lg={lg}
          xs={12}
          sx={{
            backgroundColor: "white",
            mx: "auto",
            my: 2,
            p: { lg: 3, xs: 2 },
            borderRadius: "8px",
          }}
        >
          <form>
            <Typography
              sx={{
                mx: { lg: "auto", xs: 3 },
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.voipno")}
              <PopoverHover text="prefrence.voip_popover" />
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Typography
                    sx={{
                      mx: { lg: "auto", xs: 3 },
                      fontSize: { lg: "20px", xs: "14px" },
                      backgroundColor: "#ececec",
                      textAlign: "center",
                      borderRadius: "11px",
                      py: 1,
                    }}
                  >
                    {formData?.select_number}
                  </Typography>
                </Grid>
                <Grid container justifyContent="flex-end">
                  {" "}
                  {/* Adjusted Grid item */}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", my: 2 }}>
              <RowRadioButtonsGroup
                label={t("prefrence.recievedvoip")}
                labelConfigs={VOIPmsg}
                selectedValue={formData?.voipmsg}
                setSelectedValue={(value) => {
                  onFormChange("stepper2", "voipmsg", value);
                  setVoipMsgError(false);
                }}
                poptext={t("prefrence.voipmsg_popover")}
                name="voipmsg"
                id="voipmsg"
              />
            </Box>
            {voipMsgError && (
              <Typography sx={{ color: "red" }}>
                Please select a voip message
              </Typography>
            )}
            <Box sx={{ display: "flex", my: 2 }}>
              <RowRadioButtonsGroup
                label={t("prefrence.deletemsgafter")}
                labelConfigs={deletemsg}
                selectedValue={formData?.delete_msg}
                setSelectedValue={(value) => {
                  onFormChange("stepper2", "delete_msg", value);
                  setDeleteMsgError(false);
                }}
                poptext={t("prefrence.deletemsg_popover")}
                name="delete_msg"
                id="delete_msg"
              />
            </Box>
            {deleteMsgError && (
              <Typography sx={{ color: "red" }}>
                Please select a delete message option
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                btnname="update"
                // btnname="button.next"
                onClick={handleNext}
                type="button"
                disabled={!isReady}
                variant="contained"
                className="Orangebtn"
                sx={{
                  px: { lg: 7, xs: 3 },
                  py: 1.2,
                  fontSize: { lg: "16px", xs: "12px" },
                  mx: 1,
                  borderRadius: "8px",
                }}
              />
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserPrefrenceStep1;
