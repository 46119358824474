import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Grid,
} from "@mui/material";
import axios from "axios";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const extractions = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Fee", value: "fee" },
  { label: "Address", value: "address" },
  { label: "Order Number", value: "orderno" },
  { label: "Zip", value: "zip" },
  { label: "Url", value: "url" },
];
const usDateFormats = [
  {
    label: "Day Month DD",
    value: "D M d", // e.g., Mon Sep 25
    regex:
      "(Mon|Tue|Wed|Thu|Fri|Sat|Sun)\\s+(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\\s+(0?[1-9]|[12][0-9]|3[01])",
  },
  {
    label: "MM/DD",
    value: "m/d", // e.g., 09/25
    regex: "(0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|3[01])",
  },
  {
    label: "MM/DD/YYYY",
    value: "m/d/Y", // e.g., 09/25/2024
    regex: "(0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|3[01])\\/(\\d{4})",
  },
  {
    label: "MM-DD-YYYY",
    value: "m-d-Y", // e.g., 09-25-2024
    regex: "(0?[1-9]|1[0-2])\\-(0?[1-9]|[12][0-9]|3[01])\\-(\\d{4})",
  },
  {
    label: "MM/DD/YY",
    value: "m/d/y", // e.g., 09/25/24
    regex: "(0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|3[01])\\/(\\d{2})",
  },
  {
    label: "Month DD, YYYY",
    value: "F d, Y", // e.g., September 25, 2024
    regex:
      "(January|February|March|April|May|June|July|August|September|October|November|December) \\d{1,2}, \\d{4}",
  },
  {
    label: "MMM DD, YYYY",
    value: "M d, Y", // e.g., Sep 25, 2024
    regex: "(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \\d{1,2}, \\d{4}",
  },
  {
    label: "YYYY/MM/DD",
    value: "Y/m/d", // e.g., 2024/09/25
    regex: "(\\d{4})\\/(0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|3[01])",
  },
  {
    label: "YYYY-MM-DD",
    value: "Y-m-d", // e.g., 2024-09-25
    regex: "(\\d{4})\\-(0?[1-9]|1[0-2])\\-(0?[1-9]|[12][0-9]|3[01])",
  },
  {
    label: "MMDDYYYY",
    value: "mdY", // e.g., 09252024
    regex: "(0?[1-9]|1[0-2])(0?[1-9]|[12][0-9]|3[01])(\\d{4})",
  },
  {
    label: "DD/MM/YYYY",
    value: "d/m/Y", // e.g., 25/09/2024
    regex: "(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[0-2])\\/(\\d{4})",
  },
  {
    label: "DD-MM-YYYY",
    value: "d-m-Y", // e.g., 25-09-2024
    regex: "(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[0-2])\\-(\\d{4})",
  },
  {
    label: "Month D, YYYY",
    value: "F j, Y", // e.g., September 5, 2024
    regex:
      "(January|February|March|April|May|June|July|August|September|October|November|December) \\d{1}, \\d{4}",
  },
  {
    label: "MMM D, YYYY",
    value: "M j, Y", // e.g., Sep 5, 2024
    regex: "(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \\d{1}, \\d{4}",
  },
  {
    label: "YYYY.MM.DD",
    value: "Y.m.d", // e.g., 2024.09.25
    regex: "(\\d{4})\\.(0?[1-9]|1[0-2])\\.(0?[1-9]|[12][0-9]|3[01])",
  },
  {
    label: "YYYY_MM_DD",
    value: "Y_m_d", // e.g., 2024_09_25
    regex: "(\\d{4})_(0?[1-9]|1[0-2])_(0?[1-9]|[12][0-9]|3[01])",
  },
  {
    label: "Day MM/DD",
    value: "D m/d", // e.g., Mon 09/25
    regex:
      "(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (0?[1-9]|1[0-2])\\/(0?[1-9]|[12][0-9]|3[01])",
  },
];

const usTimeFormats = [
  {
    label: "12-Hour Format (HH:MM AM/PM)",
    value: "h:i A", // e.g., 01:30 PM
    regex: "(0?[1-9]|1[0-2]):([0-5][0-9]) (AM|PM)",
  },
  {
    label: "12-Hour Format (HH:MM:SS AM/PM)",
    value: "h:i:s A", // e.g., 01:30:45 PM
    regex: "(0?[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9]) (AM|PM)",
  },
  {
    label: "12-Hour Format (HH:MM am/pm)",
    value: "h:i a", // e.g., 01:30 pm
    regex: "(0?[1-9]|1[0-2]):([0-5][0-9]) (am|pm)",
  },
  {
    label: "12-Hour Format (HH:MM:SS am/pm)",
    value: "h:i:s a", // e.g., 01:30:45 pm
    regex: "(0?[1-9]|1[0-2]):([0-5][0-9]):([0-5][0-9]) (am|pm)",
  },
  {
    label: "24-Hour Format (HH:MM)",
    value: "H:i", // e.g., 13:45
    regex: "([01][0-9]|2[0-3]):([0-5][0-9])",
  },
  {
    label: "24-Hour Format (HH:MM:SS)",
    value: "H:i:s", // e.g., 13:45:30
    regex: "([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])",
  },
  {
    label: "12-Hour Format (HH am/pm)",
    value: "h A", // e.g., 01 PM
    regex: "(0?[1-9]|1[0-2]) (AM|PM)",
  },
];

const scrappingForTypes = [
  { label: "Invitation Message", value: "invitationmessage" },
  { label: "Confirmation message", value: "confirmationmessage" },
  { label: "Invitation Email", value: "invitationemail" },
  { label: "Confirmation Email", value: "confirmationemail" },
  // { label: "Browser", value: "browser" },
];

const requiredFieldsIM = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Fee", value: "fee" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const requiredFieldsCM = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const requiredFieldsIE = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Fee", value: "fee" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const requiredFieldsCE = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Address/zip", value: "address" },
  { label: "Url", value: "url" },
];

const ScrapingFieldForm = () => {
  const escapeSpecialChars = (str) => {
    let result = str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&").trim();
    return result.replace(/\s+/g, "\\s*");
  };

  const [signingAgencies, setSigningAgencies] = useState([]);
  const [selectedAgencyId, setSelectedAgencyId] = useState("");
  const [scrapingFields, setScrapingFields] = useState([]);
  const [formData, setFormData] = useState({
    field: "",
    regex: "",
    scrap_type: "",
    before: "",
    after: "",
    format: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentFieldId, setCurrentFieldId] = useState(null);
  const [updated, setupdated] = useState(false);
  const [scrap_type, setScrapType] = useState("");
  // Fetch signing agencies
  useEffect(() => {
    axios
      .get(`${apiEndpoint}/signing/agencies`)
      .then((response) => {
        setSigningAgencies(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching signing agencies:", error);
      });
  }, []);

  // Fetch existing scraping fields for the selected signing agency
  useEffect(() => {
    if (selectedAgencyId) {
      axios
        .get(
          `${apiEndpoint}/signing-agencies/${selectedAgencyId}/scraping-fields`
        )
        .then((response) => {
          setScrapingFields(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching scraping fields:", error);
        });
    } else {
      setScrapingFields([]);
    }
  }, [selectedAgencyId, updated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.scrap_type == "") {
      alert("must select what are you scrapping for!");
      return;
    }
    if (selectedAgencyId == "") {
      alert("Please select signing agency!");
      return;
    }
    if (formData.field == "") {
      alert("must select field");
      return;
    }
    const escapedKey = escapeSpecialChars(formData.before);
    const escapedvalue = escapeSpecialChars(formData.after);
    let regex = null;
    if (formData.field == "orderno") {
      regex = `/${escapedKey}\\s*([0-9]+)\\s*${escapedvalue}/`;
      console.log(regex);
    } else if (formData.field == "date") {
      const dateFromat = usDateFormats.filter(
        (df) => df.value == formData.format
      );
      regex = `/${escapedKey}\\s*(${dateFromat[0].regex})\\s*${escapedvalue}/`;
      console.log(regex);
    } else if (formData.field == "time") {
      const timeFromat = usTimeFormats.filter(
        (df) => df.value == formData.format
      );
      regex = `/${escapedKey}\\s*(${timeFromat[0].regex})\\s*${escapedvalue}/`;
      console.log(regex);
    } else if (formData.field == "zip") {
      regex = `/${escapedKey}\\s*(\\d+)\\s*${escapedvalue}/`;
      console.log(regex);
    } else if (formData.field == "address") {
      regex = `/${escapedKey}\\s*([\\s\\S]+)\\s*${escapedvalue}/`;
      console.log(regex);
    } else if (formData.field == "url") {
      regex = `/${escapedKey}\\s*(((https?|ftp):\\/\\/)?([a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)+)(:[0-9]+)?(\\/[^\\s]*)?)\\s*${escapedvalue}/`;
      console.log(regex);
    } else if (formData.field == "fee") {
      regex = `/${escapedKey}\\s*\\$([0-9]+)\\s*${escapedvalue}/`;
      console.log(regex);
    }
    const requestData = {
      ...formData,
      signing_agency_id: selectedAgencyId,
      regex: regex,
    };

    if (isEditing) {
      // Update scraping field
      axios
        .put(`${apiEndpoint}/scraping-fields/${currentFieldId}`, requestData)
        .then((response) => {
          console.log(response);
          const updatedFields = scrapingFields.map((field) =>
            field.id === currentFieldId ? response.data : field
          );
          setScrapingFields(updatedFields);
          resetForm();
          setupdated(!updated);
        })
        .catch((error) => {
          console.error(
            "There was an error updating the scraping field:",
            error
          );
        });
    } else {
      // Create new scraping field
      axios
        .post(`${apiEndpoint}/scraping-fields`, requestData)
        .then((response) => {
          setScrapingFields([...scrapingFields, response.data]);
          resetForm();
          setupdated(!updated);
        })
        .catch((error) => {
          console.error(
            "There was an error creating the scraping field:",
            error
          );
        });
    }
  };

  const handleEdit = (field) => {
    setFormData({
      field: field.field,
      regex: field.regex,
      scrap_type: field.scrap_type,
      before: field.before || "",
      after: field.after || "",
      format: field.format || "",
    });
    setCurrentFieldId(field.id);
    setIsEditing(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`${apiEndpoint}/scraping-fields/${id}`)
      .then((data) => {
        // console.log(data)
        setupdated(!updated);
        // setScrapingFields(scrapingFields.filter((field) => field.id !== id));
      })
      .catch((error) => {
        console.error("There was an error deleting the scraping field:", error);
      });
  };

  const resetForm = () => {
    setFormData({
      field: "",
      regex: "",
      scrap_type: formData.scrap_type,
      before: "",
      after: "",
      format: "",
    });
    setIsEditing(false);
    setCurrentFieldId(null);
  };

  return (
    <Box sx={{ maxWidth: "auto", margin: "auto", p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {isEditing ? "Edit Scraping Field" : "Add Scraping Field"}
      </Typography>
      {formData.scrap_type == "invitationmessage" && (
        <>
          <Typography sx={{ my: 1 }}>
            Your Invitation message scraping is missing important fields which
            are required by gosignings
          </Typography>
          <Box sx={{ my: 2, display: "flex" }}>
            {requiredFieldsIM.map((item) => {
              if (
                !scrapingFields.some(
                  (f) =>
                    f.field == item.value && f.scrap_type == formData.scrap_type
                )
              ) {
                return (
                  <>
                    <Typography sx={{ m: 1, color: "red" }}>
                      {item.value}
                    </Typography>
                  </>
                );
              }
            })}
          </Box>
        </>
      )}

      {formData.scrap_type == "confirmationmessage" && (
        <>
          <Typography sx={{ my: 1 }}>
            Your Confirmation message scraping is missing important fields which
            are required by gosignings
          </Typography>
          <Box sx={{ my: 2, display: "flex" }}>
            {requiredFieldsCM.map((item) => {
              if (
                !scrapingFields.some(
                  (f) =>
                    f.field == item.value && f.scrap_type == formData.scrap_type
                )
              ) {
                return (
                  <>
                    <Typography sx={{ m: 1, color: "red" }}>
                      {item.value}
                    </Typography>
                  </>
                );
              }
            })}
          </Box>
        </>
      )}

      {formData.scrap_type == "invitationemail" && (
        <>
          <Typography sx={{ my: 1 }}>
            Your Invitation Email scraping is missing important fields which are
            required by gosignings
          </Typography>
          <Box sx={{ my: 2, display: "flex" }}>
            {requiredFieldsIE.map((item) => {
              if (
                !scrapingFields.some(
                  (f) =>
                    f.field == item.value && f.scrap_type == formData.scrap_type
                )
              ) {
                return (
                  <>
                    <Typography sx={{ m: 1, color: "red" }}>
                      {item.value}
                    </Typography>
                  </>
                );
              }
            })}
          </Box>
        </>
      )}

      {formData.scrap_type == "confirmationemail" && (
        <>
          <Typography sx={{ my: 1 }}>
            Your Confirmation Email scraping is missing important fields which
            are required by gosignings
          </Typography>
          <Box sx={{ my: 2, display: "flex" }}>
            {requiredFieldsCE.map((item) => {
              if (
                !scrapingFields.some(
                  (f) =>
                    f.field == item.value && f.scrap_type == formData.scrap_type
                )
              ) {
                return (
                  <>
                    <Typography sx={{ m: 1, color: "red" }}>
                      {item.value}
                    </Typography>
                  </>
                );
              }
            })}
          </Box>
        </>
      )}

      <Grid container>
        <Grid item xs={6}>
          <TextField
            label="Select Signing Agency"
            select
            fullWidth
            margin="normal"
            value={selectedAgencyId}
            onChange={(e) => {
              setSelectedAgencyId(e.target.value);
              resetForm(); // Reset the form when selecting a new agency
            }}
            required
          >
            {signingAgencies.map((agency) => (
              <MenuItem key={agency.id} value={agency.id}>
                {agency.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="What are you scrapping for!"
            select
            fullWidth
            margin="normal"
            name="scrap_type"
            value={formData.scrap_type}
            onChange={handleChange}
            required
          >
            {scrappingForTypes.map((type, index) => (
              <MenuItem key={index} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <TextField
          label="Select Field"
          select
          fullWidth
          margin="normal"
          required
          value={formData.field}
          name="field"
          onChange={handleChange}
          sx={{ minWidth: "100px" }}
        >
          {extractions.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>

        {formData.field == "date" && (
          <TextField
            label="Select Date Format"
            select
            fullWidth
            margin="normal"
            required
            name="format"
            value={formData.format}
            onChange={handleChange}
            sx={{ minWidth: "100px" }}
          >
            {usDateFormats.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}

        {formData.field == "time" && (
          <TextField
            label="Select Time Format"
            select
            fullWidth
            name="format"
            margin="normal"
            required
            value={formData.format}
            onChange={handleChange}
          >
            {usTimeFormats.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}

        <TextField
          label="Preceding string"
          name="before"
          fullWidth
          margin="normal"
          value={formData.before}
          onChange={handleChange}
        />
        <TextField
          label="Succeeding string"
          name="after"
          fullWidth
          margin="normal"
          value={formData.after}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          {isEditing ? "Update" : "Create"}
        </Button>
      </Box>

      <Grid container sx={{ display: "flex" }}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            Existing Scraping Fields
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {/* <TextField
            label="Filter by Scrap Type"
            select
            fullWidth
            margin="normal"
            value={formData.scrap_type}
            onChange={(e) => {
              // setScrapType(e.target.value);
              handleChange(e);
            }}
          >
            <MenuItem value="invitationmessage">Invitation Message</MenuItem>
            <MenuItem value="confirmationmessage">
              Confirmation Message
            </MenuItem>
            <MenuItem value="invitationemail">Invitation Email</MenuItem>
            <MenuItem value="confirmationemail">Confirmation Email</MenuItem>
            <MenuItem value="webpage">Web Page</MenuItem>
          </TextField> */}
        </Grid>
      </Grid>

      {scrapingFields.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>
                  <span style={{ color: "red" }}>Before</span>
                  <span style={{ color: "green" }}>[value]</span>
                  <span style={{ color: "orange" }}>After</span>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scrapingFields.map((item) => {
                if (item.scrap_type == formData.scrap_type) {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item.field}</TableCell>
                      <TableCell>
                        <Typography variant="body2" sx={{ color: "red" }}>
                          {item.before}
                        </Typography>

                        {(item.field == "date" || item.field == "time") && (
                          <Typography variant="body2 " sx={{ color: "green" }}>
                            {item.format}
                          </Typography>
                        )}

                        <Typography variant="body2" sx={{ color: "orange" }}>
                          {item.after}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleEdit(item)}
                          variant="contained"
                          sx={{ mr: 1 }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => handleDelete(item.id)}
                          variant="contained"
                          color="error"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No scraping fields found.</Typography>
      )}
    </Box>
  );
};

export default ScrapingFieldForm;
