import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { CircularProgress, Container, Typography } from "@mui/material";
import AuthLayout from "../../Layouts/AuthLayout";
import SiteLogo from "../../Components/Logo";
import Logo from "../../Assets/Images/Logo.png";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import Emailimg from "../../Assets/Images/emailimg.png";
import WelcomeModal from "../Subscription/WelcomeModal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Cookie from "js-cookie";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "19px",
  padding: theme.spacing(1),
}));

export default function AccountConfirmationEmail() {
  const [t, i18n] = useTranslation("global");
  const [showWelcome, setShowWelcome] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setmessage] = useState(null);

  const Welcome = () => {
    setShowWelcome(true);
  };
  const navigate = useNavigate();
  const theme = useTheme();

  const user = useSelector((state) => state?.user?.user?.user);

  let resendEmailVerification = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    const token = Cookie.get("access_token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    // formdata.append("email", user?.email);

    formdata.append("email", Cookie.get("user_email"));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/email/resend-email-verification`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          return { error: { message: "Unauthorized" } };
        }
      })
      .then((result) => {
        setLoading(false);
        setmessage(result?.data?.message);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <Container zIndex sx={{ position: "relative" }}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
          {/* <SiteLogo /> */}
          <Box
            sx={{ display: { lg: "block", xs: "block" } }}>
            <img src={Logo} alt="logo1"
              style={{
                position: "relative", top: { lg: "-3%" }, width: "103px", height: "46px", maxWidth: '100%', // Ensures the image does not exceed the container's width
                height: 'auto', // Maintains the aspect ratio of the image
                objectFit: 'cover',
              }}
            /></Box>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        //   sx={{  }}
        >
          <Grid item xs={12} md={8} lg={8}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: "40px", xs: "24px" },
                fontWeight: { lg: 700, xs: 500 },
              }}
            >
              Hey {sessionStorage.getItem("user_name")}, <br />
              Welcome to GoSignings!
            </Typography>
            <Typography
              sx={{
                color: "black",
                my: 1,
                fontSize: { lg: "24px", xs: "12px" },
                fontWeight: { lg: 400, xs: 400 },
              }}
            >
              To get started, please verify your email. <br />
              follow the steps to get started!
            </Typography>

            <Grid xs={12} lg={5}>
              <Button
                btnname="Resend Email"
                onClick={resendEmailVerification}
                variant="outlined"
                sx={{
                  my: 3,
                  borderColor: "#F26F21",
                  borderWidth: "2px",
                  borderRadius: "30px",
                  px: { lg: 5, xs: 3 },
                  py: 1.5,
                  fontWeight: 700,
                  fontSize: { lg: "20px", xs: "12px" },
                  mx: 1,
                  color: "#F26F21",
                  transition: "background-color 0.5s ease, color 0.7s ease",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#F26F21",
                    color: "white",
                    borderColor: "#F26F21",
                  },
                }}
              />{" "}
            </Grid>
            {message && (
              <Typography sx={{ color: "red", mb: 2 }}>{message}</Typography>
            )}
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: "20px", xs: "12px" },
                fontWeight: { lg: 400, xs: 400 },
                position: "relative",
              }}
            >
              GoSignings! is brought to you by CentralMassSoftware Development,
              LLC.
              <br />
              Please visit our website at
              <span style={{ color: "#F26F21" }}>
                {" "}
                www.centralmasssoftware.com
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            xlg={4}
            sx={{ display: { md: "block", xs: "none" } }}
          >
            <img
              src={Emailimg}
              alt="Emailimg"
              style={{ maxWidth: "100%", height: "80vh" }}
            />
          </Grid>
        </Grid>

        {/* <WelcomeModal
          open={showWelcome}
          handleClose={() => setShowWelcome(false)}
        /> */}
      </Container>
    </AuthLayout>
  );
}
