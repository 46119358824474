import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "../../Components/Button";
import Typography from "@mui/material/Typography";
import ModalLayout from "../../Layouts/ModalLayout";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
// import html2pdf from "html2pdf.js";

export default function TermsAndConditions({ open, handleClose }) {
  const [t, i18n] = useTranslation("global");
  const ref = React.useRef();
  const options = {
    filename: "gosignings-terms-and-conditions.pdf",
    margin: 1,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  };

  // const downloadTermsAndCondition = async () => {
  //   const content = ref.current;
  //   html2pdf().set(options).from(content).save();
  // };
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "80%", xs: "70%" },
        p: { lg: 4, xs: 2 },
        height: { lg: "70%", xs: "80%" },
        mx: { sm: 5, lg: 0 },
        borderRadius: "8px",
        bgcolor: "background.paper",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: { lg: "32px", xs: "18px" },
            color: "#6A9B41",
            fontWeight: { lg: 600, xs: 400 },
          }}
        >
          {t("term.title")}
        </Typography>

        {/* <DownloadIcon
          onClick={downloadTermsAndCondition}
          sx={{ cursor: "pointer" }}
        /> */}
      </Box>
      <Grid
        ref={ref}
        id="keep-mounted-modal-description"
        sx={{
          overflow: "auto",
          height: { lg: "70%", xs: "75%" },
          mt: 3,
          "&::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#F4933A", // Color of the thumb
            borderRadius: "4px", // Rounded corners for the thumb
            height: "30px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6EBCC", // Color of the track
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          {t("term.term&condition")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.lastuppdate")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          {t("term.agreement")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.decs1")} <br />
          <br /> {t("term.decs2")}
          <br />
          <br />
          {t("term.decs3")}
          <br />
          <br />
          {t("term.decs4")}
          <br />
          <br />
          {t("term.decs5")}
          <br />
          <br />
          {t("term.decs6")}
          <br />
          <br />
          {t("term.decs7")}
          <br />
          <br />{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          {t("term.table")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          1. {t("term.1")}
          <br />
          2. {t("term.2")}
          <br />
          3.
          {t("term.3")}
          <br />
          4.{t("term.4")} <br />
          5. {t("term.5")}
          <br />
          6.{t("term.6")}
          <br />
          7. {t("term.7")}
          <br />
          8. {t("term.8")}
          <br />
          9.
          {t("term.9")}
          <br />
          10. {t("term.10")}
          <br />
          11.
          {t("term.11")}
          <br />
          12. {t("term.12")}
          <br />
          13. {t("term.13")}
          <br />
          14. {t("term.14")}
          <br />
          15. {t("term.15")}
          <br />
          16.
          {t("term.16")}
          <br />
          17. {t("term.17")}
          <br />
          18. {t("term.18")}
          <br />
          19. {t("term.19")}
          <br />
          20. {t("term.20")}
          <br />
          21. {t("term.21")}
          <br />
          22. {t("term.22")}
          <br />
          23. {t("term.23")}
          <br />
          24. {t("term.24")}
          <br />
          25. {t("term.25")}
          <br />
          26. {t("term.26")}
          <br />
          27. {t("term.27")}
          <br />
          <br />
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          1. {t("term.1")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text1")}
          <br />
          <br />
          {t("term.text2")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          2. {t("term.2")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.intellectual_property")}
          <br />
          <br />
          {t("term.text3")}
          <br />
          <br />
          {t("term.text4")}
          <br />
          <br />
          {t("term.text5")}
          <br />
          <br />
          {t("term.text6")}
          <br />
          <br />
          {t("term.text7")}
          <br />
          {t("term.text8")}
          <br />
          {t("term.text9")}
          <br />
          {t("term.text10")}
          <br />
          <br />
          {t("term.text11")}
          <br />
          <br />
          {t("term.text12")}
          <br />
          <br />
          {t("term.text13")}
          <br />
          <br />
          {t("term.text14")}
          <br />
          <br />
          {t("term.text15")}

          <br />
          <br />
          {t("term.text16")}
          <br />
          <br />
          {t("term.text17")}
          <br />
          <br />
          {t("term.text18")}
          <br />
          {t("term.text19")}
          <br />
          {t("term.text20")}
          <br />
          {t("term.text21")}
          <br />
          {t("term.text22")}
          <br />
          {t("term.text23")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          3. {t("term.3")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text24")}
          <br />
          <br />
          {t("term.text25")}
          <br />
        </Typography>

        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          4. {t("term.4")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text26")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          5. {t("term.5")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text27")}
          <br />
          <br />- &nbsp;{t("term.visa")}
          <br />- &nbsp;{t("term.mastercard")},
          <br />- &nbsp;{t("term.american")},
          <br />- &nbsp;{t("term.discover")},
          <br />- &nbsp;{t("term.paypal")},
          <br />
          <br />
          {t("term.text28")},
          <br />
          <br />
          {t("term.text29")},
          <br />
          <br />
          {t("term.text30")},
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          6. {t("term.6")},
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text31")},
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          7. {t("term.7")},
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text32")},
          <br />
          <br />
          {t("term.text33")},
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          8. {t("term.8")},
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text34")},
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          9. {t("term.9")},
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text35")}

          <br />
          <br />
          {t("term.text36")}

          <br />
          {t("term.text37")}
          <br />
          {t("term.text38")}
          <br />
          {t("term.text39")}
          <br />
          {t("term.text40")}
          <br />
          {t("term.text41")}
          <br />
          {t("term.text42")}
          <br />
          {t("term.text43")}
          <br />
          {t("term.text44")}
          <br />
          {t("term.text45")}
          <br />
          {t("term.text46")}
          <br />
          {t("term.text47")}
          <br />
          {t("term.text48")}
          <br />
          {t("term.text49")}
          <br />
          {t("term.text50")}
          <br />
          {t("term.text51")}
          <br />
          {t("term.text52")}
          <br />
          {t("term.text53")}
          <br />
          {t("term.text54")}
          <br />
          {t("term.text55")}
          <br />
          {t("term.text56")}
          <br />
          {t("term.text57")}
          <br />
          {t("term.text58")}
          <br />
          {t("term.text59")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          10. {t("term.10")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text60")}
          <br />
          &nbsp; &nbsp;
          <br />
          {t("term.text61")}
          <br />
          {t("term.text62")}
          <br />
          {t("term.text63")}
          <br />
          {t("term.text64")}
          <br />
          {t("term.text65")}
          <br />
          {t("term.text66")}
          <br />
          {t("term.text67")}
          <br />
          {t("term.text68")}
          <br />
          {t("term.text69")}
          <br />
          {t("term.text70")}
          <br />
          {t("term.text71")}
          <br />
          {t("term.text72")}
          <br />
          {t("term.text73")}
          <br />
          {t("term.text74")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          11. {t("term.11")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text75")}

          <br />
          <br />
          {t("term.text76")}
          <br />
          <br />
          {t("term.text77")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          12. {t("term.12")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.licence")}
          <br />
          <br />
          {t("term.text78")}

          <br />
          <br />
          {t("term.text79")}
          <br />
          <br />
          {t("term.text80")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          13. {t("term.13")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.licence")}
          <br />
          <br />
          {t("term.text81")}

          <br />
          <br />
          {t("term.text82")}

          <br />
          <br />
          {t("term.text83")}
          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          14. {t("term.14")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text84")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          15. {t("term.15")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text85")}

          <br />
          <br />
          {t("term.text86")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          16. {t("term.16")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text87")}

          <br />
          <br />
          {t("term.text88")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          17. {t("term.17")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text89")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          18. {t("term.18")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text90")}

          <br />
          <br />
          {t("term.text91")}

          <br />
          <br />
          {t("term.text92")}

          <br />
          <br />
          {t("term.text93")}

          <br />
          <br />
          {t("term.text94")}

          <br />
          <br />
          {t("term.text95")}

          <br />
          <br />
          {t("term.restriction")}
          <br />
          <br />
          {t("term.text96")}

          <br />
          <br />
          {t("term.text97")}

          <br />
          <br />
          {t("term.text98")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          19. {t("term.19")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text99")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          20. {t("term.20")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text100")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          21. {t("term.21")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text101")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          22. {t("term.22")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text102")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          23. {t("term.23")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text103")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          24. {t("term.24")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text104")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          25. {t("term.25")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text105")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          26. {t("term.26")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text106")}

          <br />
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "12px" },
            color: "Black",
            my: 2,
            fontWeight: { lg: 500, xs: 500 },
          }}
        >
          27. {t("term.27")}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "18px", xs: "10px" },
            color: "Black",
            fontWeight: { lg: 400, xs: 400 },
          }}
        >
          {t("term.text107")}
          <br />
          <br />
          {t("term.text108")}
          <br />
          {t("term.text109")}
          <br />
          {t("term.text110")}
          <br />
          {t("term.us")}
          <br />
          {t("term.no")}: 508-353-8580
          <br />
          Fax: 508-519-2564
          <br />
          legal@centralmasssoftware.com <br />
        </Typography>
      </Grid>
      <Grid
        sx={{
          float: "right",
          my: 3,
          display: "flex",
        }}
      >
        <Button
          btnname="button.cancel"
          type="submit"
          onClick={handleClose}
          variant="contained"
          className=""
          sx={{
            borderRadius: "8px",
            px: { lg: 7, xs: 3 },
            py: 1.2,
            fontSize: { lg: "16px", xs: "12px" },
            mx: 1,
            color: "black",
            transition: "background-color 0.5s ease, color 0.7s ease",
            backgroundColor: "white",
            boxShadow: "none",
            "&:hover": { boxShadow: "none", backgroundColor: "#ECECEC" },
          }}
        />
        <Button
          btnname="button.agree"
          onClick={handleClose}
          type="submit"
          variant="contained"
          className="Orangebtn"
          sx={{
            px: { lg: 7, xs: 3 },
            py: 1.2,
            fontSize: { lg: "16px", xs: "12px" },
            mx: 1,
          }}
        />
      </Grid>
    </ModalLayout>
  );
}
