import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingSlider from "./LandingSlider";

const CarouselComponent = ({ LandingSliderData }) => {
  const settings = {
    dots: false,
    infinite: false, // Default for desktop
    speed: 500,
    slidesToShow: 4, // Default for desktop
    slidesToScroll: 1,
    autoplay: false, // Default for desktop
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 992, // Adjusted Tablet breakpoint for larger tablets
        settings: {
          slidesToShow: 2, // Show 2 slides on tablet
          slidesToScroll: 1,
          infinite: true, // Enable infinite scrolling on tablet
          autoplay: true, // Enable autoplay on tablet
        },
      },
      {
        breakpoint: 600, // Mobile breakpoint
        settings: {
          infinite: true, // Enable infinite scrolling on mobile
          autoplay: true, // Enable autoplay on mobile
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  
  
  return (
    <Slider {...settings}>
      {LandingSliderData.map((x, index) => (
        <div key={index}>
          <LandingSlider
            // icon={x.icon}
            heading={x.heading}
            paragraph={x.paragraph}
            backgroundColor={x.backgroundColor}
          />
        </div>
      ))}
    </Slider>
  );
};
export default CarouselComponent;