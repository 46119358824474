import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCalendarEmails: [],
};

const selectedCalendarEmailsSlice = createSlice({
  name: "selectedCalendarEmails",
  initialState,
  reducers: {
    updateCalendarEmails: (state, action) => {
      state.selectedCalendarEmails = action.payload;
    },
  },
});

export const { updateCalendarEmails } = selectedCalendarEmailsSlice.actions;

export default selectedCalendarEmailsSlice.reducer;
