import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: {
    count: 0,
    data: [],
  },
};

const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    incrementNotifications: (state, action) => {
      state.notifications.count += 1;
      state.notifications.data.push(action.payload);
    },
    updateNotifications: (state, action) => {
      state.notifications.data = action.payload;
    },
    updateNotificationscount: (state, action) => {
      state.notifications.count = action.payload;
    },
  },
});

export const {
  incrementNotifications,
  updateNotifications,
  updateNotificationscount,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
