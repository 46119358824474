import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: "80%",
  //   height: "60vh",
  //   bgcolor: "background.paper",
  boxShadow: 24,
  zIndex: 3,
};
const backdropStyle = {
  zIndex: 1,
  backgroundColor: "black", // Set the background color of the backdrop to black
  opacity: 0.9, // Optional: Add opacity if you want a semi-transparent backdrop
};

export default function ModalLayout({ open, handleClose, children, sx }) {
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{ style: backdropStyle }}
      >
        <Box sx={{ ...style, ...sx }}>{children}</Box>
      </Modal>
    </div>
  );
}
