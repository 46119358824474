import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import SigninAvatar from "../Assets/Images/Sigininavatar.png";
import MsgBgImg from "../Assets/Images/rightmsgbg.png";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
const styles = {
  msgBgImg: {
    maxWidth: "19rem",
    maxHeight: "100vh",
    width: "18rem",
    height: "14rem",
    position: "relative",
    // bottom: "-15vh",
    // left: "10%",
  },
};

const Item = styled(Paper)(({ theme }) => ({}));

export default function RightImgWithPopup({ img, msg, style, charcterStyle }) {
  const [t, i18n] = useTranslation("global");
  // const theme=useTheme()
  const theme = useTheme({
    breakpoints: {
      values: {
        xl: 1736,
      },
    },
  });
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl")); 
  return (
    <Box sx={{ position: "absolute", bottom: 0}}>
      <Grid>
        <Grid
          item
          sx={{ p: 5}}
          style={{
            ...styles.msgBgImg,...style,
            backgroundImage: `url(${MsgBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <Box
            sx={{
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              // fontSize: { lg: "20px" },
            }}
          >
            {t(msg)}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          
        </Grid>
        <Grid item xs={8}>
          <img
            src={img}
            alt="Avatar"
            style={{
              ...charcterStyle,
              // maxWidth: "117%",
              height: isXlScreen ? "40rem" : "auto",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
