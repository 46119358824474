import {
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Logo from "../Components/Logo";
import AuthLayout from "../Layouts/AuthLayout";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import PhoneIcon from "../Assets/Images/callicon.png";
import EmailIcon from "../Assets/Images/emailicon.png";
import LocationIcon from "../Assets/Images/locationicon.png";
import FacebookIcon from "../Assets/Icons/fbicon.svg";
import TwitterIcon from "../Assets/Icons/twitericon.svg";
import InstagramIcon from "../Assets/Icons/instaIcon.svg";
import { useState } from "react";
import CustomField from "../Components/CustomField";
import Button from "../Components/Button";
import CustomSelect from "../Components/CustomSelect";
import ContactUsModal from "../Components/ContactUsModal";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import SelectLanguage from "../Components/LanguageSelect";
import Cookie from "js-cookie";
import toast from "react-hot-toast";

// import ContactUsModal from "../Components/ContactUsModal";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const SelectSubject = [
  {
    label: "contact.select1",
    value: "General Inquiry",
  },
  {
    label: "contact.select2",
    value: "I cannot sign-up",
  },
  { label: "contact.select3", value: "I cannot sign-in" },
  {
    label: "contact.select4",
    value: "I cannot reset my login credentials",
  },
];

const ContactUs = () => {
  // useEffect(() => {
  //   document.title = `Contact Us - GoSigning`;
  // }, []);
  const [loading, setLoading] = useState(false);

  const [t, i18n] = useTranslation("global");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [pno, setPno] = useState("");
  const [subject, setsubject] = useState(null);
  const [message, setmessage] = useState(null);

  const BackClick = () => {
    window.history.back();
  };
  const [showContactUs, setShowContactUs] = useState(false);
  const ContactUs = () => {
    setShowContactUs(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

   
    const textregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
    if (!textregex.test(fname)) {
      console.error("text doesn't meet the criteria.");
      return;
    }

    const lnameregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
    if (!lnameregex.test(lname)) {
      console.error("text doesn't meet the criteria.");
      return;
    }
    // const pnoregex =
    //   /^\+?[0-9]{1,3}?[-.●]?([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
    // if (!pnoregex.test(pno)) {
    //   console.error("text doesn't meet the criteria.");
    //   return;
    // }
    // console.log("2");
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      console.error("text doesn't meet the criteria.");
      return;
    }

    if (!subject || !message) {
      return;
    }

    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("fname", fname);
    form_data.append("lname", lname);
    form_data.append("email", email);
    form_data.append("pno", pno);
    form_data.append("subject", subject);
    form_data.append("message", message);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/add/case/zoho`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        console.log(result);
        if (result.error) {
          toast.error(result?.error);
          setLoading(false);
        } else {
          setLoading(false);
          ContactUs();
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters
    input = input.replace(/\D/g, "");

    if (input.length > 0) {
      input = "(" + input;
    }
    if (input.length > 4) {
      input = input.slice(0, 4) + ") " + input.slice(4);
    }
    if (input.length > 9) {
      input = input.slice(0, 9) + "-" + input.slice(9);
    }
    if (input.length > 14) {
      input = input.slice(0, 14); // Limit to (123) 456-7890 format
    }
    return input;
  };

  return (
    <AuthLayout sx={{ minHeight: "100vh", maxHeight: "100%" }}>
      <Grid sx={{ maxWidth: "none", px: 3 }}>
        {loading && ( // Conditional rendering of loader
          <Box
            sx={{
              zIndex: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              backgroundColor: "transparent",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
              opacity: 0.8,
              width: { lg: "100%", xs: "100%" },
              height: { lg: "100%", xs: "100%" },
            }}
          >
            <CircularProgress sx={{ color: "#F26F21" }} size={80} />
          </Box>
        )}
        <Logo />

        <Container sx={{ position: "relative" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              maxHeight: "100%",
              minHeight: "100vh",
              boxShadow: {
                xs: "0px 0px 60px 30px rgba(0, 0, 0, 0.03)",
                md: "none",
              },
            }}
          >
            <Grid
              item
              zIndex="12"
              xs={12}
              md={5}
              lg={5}
              sx={{
                p: 5,
                backgroundColor: "#D6EBCC",
                borderRadius: { lg: "12px", xs: "0px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "40px", xs: "24px" },
                  fontWeight: { lg: 700, xs: 600 },
                  color: "#F4933A",
                  px: 2,
                  pt: 2,
                }}
              >
                {t("contact.title")}
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    color: "#545454",
                    fontWeight: 400,
                  }}
                >
                  {t("contact.description")}
                </span>
              </Typography>

              <List
                sx={{
                  pr: { lg: 7, xs: 0 },
                  display: { md: "block", xs: "none" },
                }}
              >
                <ListItem>
                  <ListItemIcon>
                    <img src={PhoneIcon} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "#6A9B41",
                      fontSize: { lg: "18px", xs: "12px" },
                    }}
                  >
                    (508) 353-8580
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <img src={EmailIcon} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "#6A9B41",
                      fontSize: { lg: "18px", xs: "12px" },
                    }}
                  >
                    {" "}
                    info@centralmasssoftware.com
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <img src={LocationIcon} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "#6A9B41",
                      fontSize: { lg: "18px", xs: "12px" },
                    }}
                  >
                    CentralMassSoftware Development, Inc <br />
                    PO Box 723 <br /> Sutton, MA 01590
                  </Typography>
                </ListItem>
              </List>
              <Grid
                sx={{ display: "flex", display: { md: "block", xs: "none" } }}
              >
                <a href="https://x.com/GoSignings" target="_blank" rel="noopener noreferrer">
                  <img
                    style={{ margin: "0 8px", cursor: "pointer" }}
                    src={TwitterIcon}
                    alt="Twitter"
                  />
                </a>
                <a href="https://www.instagram.com/gosignings/" target="_blank" rel="noopener noreferrer">
                  <img
                    style={{ margin: "0 8px", cursor: "pointer" }}
                    src={InstagramIcon}
                    alt="Instagram"
                  />
                </a>

                <a href="https://www.facebook.com/profile.php" target="_blank" rel="noopener noreferrer">
                  <img
                    style={{ margin: "0 8px", cursor: "pointer" }}
                    src={FacebookIcon}
                    alt="Facebook"
                  />
                </a>

              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              lg={7}
              sx={{
                p: 2,
                backgroundColor: "white",
                borderRadius: { lg: "0px 11px 11px 0px", xs: 0 },
                ml: { lg: "-1%", xs: 0 },
                display: "flex", // Add this line
                flexDirection: "column", // Add this line
                justifyContent: "center", // Center content horizontally
                alignItems: "center",
              }}
            >
              <form style={{ margin: " 5%" }} onSubmit={handleSubmit}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
                  <Grid item xs={6}>
                    <CustomField
                      label={t("form.fname")}
                      placeholder=""
                      type="text"
                      id="first_name"
                      name="first_name"
                      onChange={(fname) => setFname(fname)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomField
                      label={t("form.lname")}
                      placeholder=""
                      type="text"
                      id="last_name"
                      name="last_name"
                      onChange={(lname) => setLname(lname)}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
                  <Grid item lg={6} xs={12}>
                    <CustomField
                      label={t("form.email")}
                      placeholder="abc132@email.com"
                      type="email"
                      id="email"
                      name="email"
                      onChange={(email) => setEmail(email)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomField
                      label={t("form.pno")}
                      placeholder={t("form.pnoplaceholder")}
                      type="tel"
                      onChange={(inputValue) => {
                        inputValue = formatPhoneNumber(inputValue);
                        setPno(inputValue);
                      }}
                      id="pno"
                      name="pno"
                      value={pno}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomSelect
                    label={t("contact.select")}
                    menuItems={SelectSubject}
                    value={subject}
                    required={true}
                    onChange={(e) => setsubject(e.target.value)}
                    id="select_subject"
                  />
                </Grid>
                <Grid item xs={12} sx={{ my: 1 }}>
                  <TextField
                    sx={{
                      borderRadius: "8px",
                      "& fieldset": {
                        borderRadius: "8px",
                      },
                    }}
                    id="outlined-multiline-static"
                    label={t("contact.msg")}
                    placeholder={t("contact.msg_placeholder")}
                    multiline
                    onChange={(e) => setmessage(e.target.value)}
                    value={message}
                    rows={4}
                    fullWidth
                    required
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    pt: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    btnname="button.back"
                    variant="contained"
                    onClick={BackClick}
                    className="greybtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                  <Button
                    btnname="button.submit"
                    type="submit"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </form>
            </Grid>
            <Grid
              item
              zIndex="12"
              xs={12}
              md={5}
              lg={5}
              sx={{
                p: 5,
                backgroundColor: "#D6EBCC",
                borderRadius: { lg: "12px", xs: "0px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                display: { md: "none", xs: "block" },
              }}
            >
              <List
                sx={{
                  pr: { lg: 7, xs: 0 },
                  display: { md: "none", xs: "block" },
                }}
              >
                <ListItem>
                  <ListItemIcon>
                    <img src={PhoneIcon} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "#6A9B41",
                      fontSize: { lg: "18px", xs: "12px" },
                    }}
                  >
                    (508) 353-8580
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <img src={EmailIcon} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "#6A9B41",
                      fontSize: { lg: "18px", xs: "12px" },
                    }}
                  >
                    {" "}
                    info@centralmasssoftware.com
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <img src={LocationIcon} />
                  </ListItemIcon>
                  <Typography
                    sx={{
                      color: "#6A9B41",
                      fontSize: { lg: "18px", xs: "12px" },
                    }}
                  >
                    GoSignings!, PO Box 732, Sutton, MA 01590
                  </Typography>
                </ListItem>
              </List>
              <Grid
                sx={{ display: "flex", display: { md: "none", xs: "block" } }}
              >
                <img
                  style={{ margin: "0 8px" }}
                  src={TwitterIcon}
                  alt="Twitter"
                />
                <img
                  style={{ margin: "0 8px" }}
                  src={InstagramIcon}
                  alt="Instagram"
                />
                <img
                  style={{ margin: "0 8px" }}
                  src={FacebookIcon}
                  alt="Facebook"
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
            mx: { lg: 7, xs: 2 },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mt: 2,
            }}
          >
            <SelectLanguage />
          </Box>
          <Typography
            sx={{
              color: "#8C8C8C",
              fontSize: { lg: "18px", xs: "12px" },
              m: 3,
            }}
          >
            {t("contact.contact")}
          </Typography>
          <Link to="/helpcenter" style={{ textDecoration: "none" }}>
            <Typography
              sx={{
                color: "#F26F21",
                fontSize: { lg: "18px", xs: "12px" },
                m: 3,
              }}
            >
              {t("contact.help")}
            </Typography>
          </Link>
        </Grid>
        <ContactUsModal
          open={showContactUs}
          handleClose={() => setShowContactUs(false)}
        />
      </Grid>
    </AuthLayout>
  );
};

export default ContactUs;
