import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";

const NumberingSlider = ({ slideData }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // 6 slides for desktop
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 992, // tablet breakpoint
        settings: {
          slidesToShow: 3, // Show 3 slides on tablet
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 2, // Show 1 slide on mobile
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {slideData.map((slide, index) => (
        <div key={index}>
          <CustomSlide number={slide.number} heading={slide.heading} isLast={index === slideData.length - 1} />
        </div>
      ))}
    </Slider>
  );
};

const CustomSlide = ({ number, heading, isLast }) => {
  return (
    <Box sx={{ textAlign: "center", position: "relative", margin: '20px 20px' }}>
      <Box
        sx={{
          width: { xs: "30%", md: "60px" },
          height: { xs: "45%", md: "60px" },
          borderRadius: "50%",
          backgroundColor: "#ef6e21",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: { xs: "22px", md: "24px" },
            fontWeight: "bold",
          }}
        >
          {number}
        </Typography>

        {/* Connector line for all except the last slide */}
        {!isLast && (
          <Box
            sx={{
              position: "absolute",
              top: "50%", // Center the line vertically relative to the circle
              left: "100%", // Start the line from the right edge of the circle
              transform: "translate(0, -50%)", // Adjust vertical centering
              height: "1px",
              backgroundColor: "#2A3D1D",
              width: { xs: "170px", md: "190px", sm: "280px" }, // Length of the connecting line between circles
              zIndex: -1,
            }}
          />
        )}
      </Box>

      <Typography
        sx={{
          marginTop: "10px",
          color: "#000000",
          fontSize: { xs: "12px", md: "16px" },
          textAlign: "center", // Center the heading text
        }}
      >
        {heading}
      </Typography>

    </Box>
  );
};

export default NumberingSlider;
