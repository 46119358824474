import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import ConfirmedListBox from "../../../Components/ConfirmedListBox";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import Map from "../../../Components/map";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const ConfirmedList = ({ Confirmed }) => {
  const [t, i18n] = useTranslation("global");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page
  const [searchTerm, setSearchTerm] = useState("");

  // Filtered and paginated list of confirmed items
  const filteredConfirmed = Confirmed?.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredConfirmed?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} order={{ xs: 2, lg: 1 }}>
          {currentItems &&
            currentItems.map((item, index) => (
              <ConfirmedListBox
                key={index}
                order={item}
                recieveddate={item.created_at}
                recievedtime={item.closing_time}
                orderdate={item.closing_date}
                location={item.closing_city}
                status={item.closing_status}
              />
            ))}

          {/* Pagination controls */}
          <div style={{ marginTop: "20px" }}>
            {filteredConfirmed &&
              Array.from(
                { length: Math.ceil(filteredConfirmed.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                      margin: "0 5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      backgroundColor:
                        currentPage === index + 1 ? "#ccc" : "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    {index + 1}
                  </button>
                )
              )}
          </div>
        </Grid>
        <Grid item xs={12} lg={4} order={{ xs: 1, lg: 2 }}>
          {currentItems && currentItems?.length > 0 && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Search sx={{ border: "1px solid #999", borderRadius: "6px" }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t("recievedlist.search")}
                    inputProps={{ "aria-label": "search" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Search>
              </Box>
              <Map />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfirmedList;
