import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ContactModalImg from "../Assets/Images/ContactModalimg.png";
import ImgWithPopup from "../Components/ImgWithPopup";
import { Container, Typography } from "@mui/material";
import Button from "../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import ModalLayout from "../Layouts/ModalLayout";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";


export default function ContactUsModal({ open, handleClose }) {
  const theme = useTheme();
  const BackClick =()=>{
    window.history.back()
  }
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
      }}
    >
      <Button
        sx={{
          position: "absolute",
          top: "8%",
          right: "5%",
          color: "#fff",
          fontSize: { lg: "22px", xs: "12px" },
          "&:hover": { boxShadow: "none", color: "red" },
        }}
        onClick={handleClose}
        btnname="x"
      />
      {/* </Button> */}
      <Container zIndex sx={{}}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ maxHeight: "100%", minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{ p: 0, display: { xs: "none", md: "block" } }}
          >
            <ImgWithPopup
              charcterStyle={{ maxWidth: "100%", height:"100vh" }}
              style={{ bottom: "-10vh",}}
              img={ContactModalImg}
              msg="Thank you for 
              contacting us"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xlg={6}
            sx={{ my: "auto", position: "relative" }}
          >
            <Grid
              sx={{
                boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: { md: 600, xs: 500 },
                  fontSize: { md: "32px", xs: "18px" },
                  color: "#6A9B41",
                }}
              >
                Your message has been delivered
              </Typography>
              
              
                <Typography sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  fontWeight: { lg: 500, xs: 500 },
                  color: "white", my:1
                }}>We will respond to you through email </Typography>

              <Grid >
                {/* <Link to={"/Dashboard"}
                          style={{ textDecoration: "none" }}> */}
                <Button
                  btnname="Continue"
                  type="submit"
                  variant="contained"
                  onClick={BackClick}
                  className="Orangebtn"
                  sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" }, my:7 }}
                />
                {/* </Link> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ModalLayout>
  );
}
