import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    subscription: null,
    user: null,
    calendar_accounts: null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.subscription = action.payload.subscription;
      state.user = action.payload.user;
      state.calendar_accounts = action.payload.calendar_accounts;
    },
    updateUser: (state, action) => {
      // console.log(action.payload);
      state.user = action.payload;
    },
  },
});

export const { setUserData, updateUser } = userSlice.actions;
export default userSlice.reducer;
