import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  styled,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ImgWithPopup from "../../Components/ImgWithPopup";
import Stepper1Avatar from "../../Assets/Images/Stepper step 1img.png";
import CheckIcon from "@mui/icons-material/Check";
import PreferenceModal from "../Subscription/PreferenceModal";
import WelcomeModal from "./WelcomeModal";
import Button from "../../Components/Button";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "19px",
  padding: theme.spacing(1),
}));

const StepperStep1 = ({ onNext }) => {
  const [t, i18n] = useTranslation("global");
  const [showPreference, setShowPreference] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    const hasSeenWelcomeModal = localStorage.getItem("hasSeenWelcomeModal");

    if (!hasSeenWelcomeModal) {
      const timer = setTimeout(() => {
        setShowWelcomeModal(true);
        localStorage.setItem("hasSeenWelcomeModal", "true");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const Preference = () => {
    setShowPreference(true);
  };

  return (
    <Container zIndex sx={{ maxHeight: "100%", minHeight: "100vh" }}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ maxHeight: "100%", minHeight: "100vh" }}
      >
        <Typography
          sx={{
            mx: { lg: "auto", xs: 3 },
            textAlign: "center",
            fontSize: { lg: "40px", xs: "18px" },
            fontWeight: { lg: 700, xs: 600 },
          }}
        >
          {user?.first_name} {t("prefrence.voipwelcome")}
        </Typography>
        <Grid item xs={12} md={7} lg={7}>
          <Grid sx={{ p: 0, display: { xs: "none", md: "block" } }}>
            <Box display="flex" justifyContent="center">
              <img
                src={Stepper1Avatar}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          xlg={4}
          sx={{ my: { lg: "auto" }, position: "relative" }}
        >
          {/* ... rest of your code */}
          <Typography
            sx={{ my: 2, mx: { xs: 3 }, display: { lg: "none", xs: "block" } }}
          >
            {t("prefrence.step1msg")}
          </Typography>
          <Item
            container
            sx={{
              py: 3,
              px: { lg: 3, xs: 2 },
              mx: { xs: 3 },
              boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: { md: 600, xs: 500 },
                fontSize: { md: "30px", xs: "18px" },
                color: "#6A9B41",
                // textAlign:"center",
              }}
            >
              {t("prefrence.free")}
            </Typography>
            <Typography
              sx={{
                fontWeight: { md: 900, xs: 500 },
                fontSize: { md: "40px", xs: "22px" },
                color: "black",
              }}
            >
              $9.99{" "}
              <span style={{ fontSize: "17px" }}>
                USD/ {t("prefrence.month")}
              </span>
            </Typography>
            <Typography
              sx={{
                fontWeight: { md: 900, xs: 500 },
                fontSize: { md: "32px", xs: "20px" },
                color: "black",
              }}
            >
              {t("prefrence.standard")}
            </Typography>
            <List sx={{ px: "auto" }}>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary="Lorem Ipsume" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary="Lorem Ipsume" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary="Lorem Ipsume" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon style={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText primary="Lorem Ipsume" />
              </ListItem>
            </List>

            <Button
              btnname="button.continue"
              type="submit"
              onClick={Preference}
              variant="contained"
              className="Orangebtn"
              sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" } }}
            />
          </Item>
          <WelcomeModal
            open={showWelcomeModal}
            handleClose={() => setShowWelcomeModal(false)}
          />
          <PreferenceModal
            open={showPreference}
            onNext={onNext}
            handleClose={() => setShowPreference(false)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepperStep1;
