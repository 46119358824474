import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  Snackbar,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IdentificationForTypes = [
  { label: "Invitation Message", value: "invitationmessage" },
  { label: "Confirmation message", value: "confirmationmessage" },
  { label: "Invitation Email", value: "invitationemail" },
  { label: "Confirmation Email", value: "confirmationemail" },
];

const IdentificationForm = ({ onClose }) => {
  const [identificationId, setIdentificationId] = useState(null);
  const [label, setLabel] = useState("");
  const [regex, setRegex] = useState("");
  const [identificationFor, setIdentificationFor] = useState("");
  const [signingAgencyId, setSigningAgencyId] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [signingAgencies, setSigningAgencies] = useState([]);
  const [identifications, setIdentifications] = useState([]); // State to hold identifications
  const [filter, setFilter] = useState("");
  const [updated, setUpdated] = useState(false);
  useEffect(() => {
    axios
      .get(`${apiEndpoint}/signing/agencies`)
      .then((response) => {
        setSigningAgencies(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching signing agencies:", error);
      });
  }, []);

  // Fetch existing identification data for editing
  useEffect(() => {
    if (identificationId) {
      axios
        .get(`${apiEndpoint}/identifications/${identificationId}`)
        .then((response) => {
          const { label, regex, identification_for, signing_agency_id } =
            response.data;
          setLabel(label);
          setRegex(regex);
          setIdentificationFor(identification_for);
          setSigningAgencyId(signing_agency_id);
        })
        .catch((error) => {
          console.error("Error fetching identification:", error);
        });
    }
  }, [identificationId]);

  useEffect(() => {
    if (signingAgencyId) {
      axios
        .get(
          `${apiEndpoint}/identifications/agency/${signingAgencyId}`
        )
        .then((response) => {
          setIdentifications(response.data);
        })
        .catch((error) => {
          console.error("Error fetching identifications:", error);
          setError(true);
          setMessage("Failed to fetch identifications.");
        });
    } else {
      setIdentifications([]); // Clear identifications if no agency is selected
    }
  }, [signingAgencyId, updated]);
  // Fetch identifications for the selected signing agency

  const handleSubmit = (e) => {
    e.preventDefault();

    const regularExpression = `/${escapeSpecialChars(label)}/i`;

    const data = {
      label,
      regex: regularExpression,
      identification_for: identificationFor,
      signing_agency_id: signingAgencyId,
    };

    const apiCall = identificationId
      ? axios.put(`${apiEndpoint}/identifications/${identificationId}`, data)
      : axios.post(`${apiEndpoint}/identifications`, data);

    apiCall
      .then((response) => {
        setSuccess(true);
        setMessage(
          `Identification ${
            identificationId ? "updated" : "created"
          } successfully!`
        );
        setUpdated(!updated);
        resetForm();
        if (onClose) onClose(); // Close the form after submission
      })
      .catch((error) => {
        console.error("Error saving identification:", error);
        setError(true);
        setMessage("Failed to save identification.");
      });
  };

  const resetForm = () => {
    setLabel("");
    setRegex("");
    setIdentificationFor("");
    setIdentificationId(null);
  };

  const handleDelete = () => {
    axios
      .delete(`${apiEndpoint}/identifications/${identificationId}`)
      .then(() => {
        setSuccess(true);
        setMessage("Identification deleted successfully!");
      })
      .catch((error) => {
        console.error("Error deleting identification:", error);
        setError(true);
        setMessage("Failed to delete identification.");
      });
  };

  const escapeSpecialChars = (str) => {
    let result = str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&").trim();
    return result.replace(/\s+/g, "\\s*");
  };

  const filteredIdentifications = identifications.filter((identification) =>
    identification.identification_for.includes(filter)
  );

  const handleEdit = (identification) => {
    setLabel(identification.label);
    setRegex(identification.regex); // Assuming you want to edit regex too
    setIdentificationFor(identification.identification_for);
    setSigningAgencyId(identification.signing_agency_id);
    setIdentificationId(identification.id); // Set the ID to enable updating
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Typography variant="h6">
        {identificationId ? "Edit Identification" : "Create Identification"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Select Signing Agency"
              select
              fullWidth
              margin="normal"
              value={signingAgencyId}
              onChange={(e) => {
                setSigningAgencyId(e.target.value);
              }}
              required
            >
              {signingAgencies.map((agency) => (
                <MenuItem key={agency.id} value={agency.id}>
                  {agency.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="What are you identifying for!"
              select
              fullWidth
              margin="normal"
              value={identificationFor}
              onChange={(e) => setIdentificationFor(e.target.value)}
              required
            >
              {IdentificationForTypes.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Identification String"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
        >
          {identificationId ? "Update" : "Create"}
        </Button>
        {identificationId && (
          <Button
            onClick={() => {
              setIdentificationId(null);
              resetForm();
            }}
            variant="contained"
            color="secondary"
            style={{ marginTop: 20, marginLeft: 10 }}
          >
            Cancel Update
          </Button>
        )}
      </form>
      {/* Filter and Table for Identifications */}
      <TextField
        label="Filter by Identification For"
        select
        fullWidth
        margin="normal"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        <MenuItem value="">All</MenuItem>
        {IdentificationForTypes.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </TextField>
      {filteredIdentifications.length > 0 && (
        <TableContainer style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel>Identification String</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Identification For</TableSortLabel>
                </TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredIdentifications.map((identification) => (
                <TableRow key={identification.id}>
                  <TableCell sx={{ color: "blue" }}>
                    {identification.label}
                  </TableCell>
                  {identification.identification_for && (
                    <TableCell>
                      {
                        IdentificationForTypes.filter(
                          (item) =>
                            item.value == identification.identification_for
                        )[0]?.label
                      }
                    </TableCell>
                  )}

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleEdit(identification)}
                      style={{ marginRight: 5 }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => handleDelete(identification.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert onClose={() => setSuccess(false)} severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert onClose={() => setError(false)} severity="error">
          {message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default IdentificationForm;
