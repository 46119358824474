import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';

const DashboardCard = ({ hasSecondButton = false }) => {
    return (
        <Box sx={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', width: '100%' }}>

            {/* First row: Heading and First Button */}
            <Grid container alignItems="center" sx={{ marginBottom: '20px' }}>
                <Grid item xs={8}>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '25.59px',
                            fontWeight: '500',
                            lineHeight: '38.39px',
                            textAlign: 'left',
                        }}
                    >
                        Main Heading
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary">First Button</Button>
                </Grid>
            </Grid>

            {/* Conditional Second Button in a new row */}
            {hasSecondButton && (
                <Grid container justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        <Button variant="contained" color="secondary">Second Button</Button>
                    </Grid>
                </Grid>
            )}

            {/* Rows of 3 or 4 columns depending on button */}
            <Grid container spacing={2}>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '17.23px',
                            fontWeight: '500',
                            lineHeight: '19.82px',
                            textAlign: 'left',
                        }}
                    >
                        Order ID #45672
                    </Typography>
                </Grid>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45673
                    </Typography>
                </Grid>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45674
                    </Typography>
                </Grid>

                {/* Fourth column only when the second button is present */}
                {hasSecondButton && (
                    <Grid item xs={3}>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                            Order ID #45675
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {/* Row 3 */}
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45676
                    </Typography>
                </Grid>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45677
                    </Typography>
                </Grid>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45678
                    </Typography>
                </Grid>
            </Grid>

            {/* Row 4 */}
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45679
                    </Typography>
                </Grid>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45680
                    </Typography>
                </Grid>
                <Grid item xs={hasSecondButton ? 3 : 4}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '17.23px', fontWeight: '500', lineHeight: '19.82px', textAlign: 'left' }}>
                        Order ID #45681
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    );
};

export default DashboardCard;
