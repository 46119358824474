import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Container, Typography } from "@mui/material";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import AccountVarificationimg from "../../Assets/Images/Sigininavatar.png";
import ModalLayout from "../../Layouts/ModalLayout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { borderColor, display } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function UnavailableModal({ open, handleClose }) {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  const [date, setdate] = useState(null);
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        height: { lg: "100%", xs: "100%" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          width: { lg: "100%", xs: "100%" },
          height: { lg: "100%", xs: "100%" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          sx={{
            border: "2px solid white",
            backgroundColor: "white",
            borderRadius: "20px",
          }}
          p={3}
          px={4}
        >
          <Typography
            sx={{
              fontWeight: { md: 700, xs: 600 },
              fontSize: { md: "32px", xs: "18px" },
              color: "#545454",
            }}
            xs={3}
            lg={3}
          >
            {"Specify the time when will you be back?"}
          </Typography>

          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker onChange={(e) => setdate(e)}></DateTimePicker>
            </LocalizationProvider>
          </Grid>

          <Grid
            xl={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              // btnname="button.continue"
              btnname={"Cancel"}
              type="submit"
              variant="outlined"
              onClick={() => handleClose(false)}
              sx={{
                py: "10px",
                fontSize: { lg: "22px", xs: "12px" },
                my: 3,
                px: 10,
                color: "#545454",
                borderColor: "#545454",
              }}
            />
            <Button
              // btnname="button.continue"
              btnname={"Mark as unavailable"}
              type="submit"
              variant="contained"
              className="Orangebtn"
              onClick={() => handleClose(true, date)}
              sx={{
                py: "10px",
                fontSize: { lg: "22px", xs: "12px" },
                my: 3,
                px: 10,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </ModalLayout>
  );
}
