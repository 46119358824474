import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invitations: {
    received: 0,
    declined: 0,
    pending: 0,
    confirmed: 0,
  },
  invitationsBackup: {
    received: 0,
    declined: 0,
    pending: 0,
    confirmed: 0,
  },
  invitationfilter: "day",
};

const invitationsSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    updateInvitations: (state, action) => {
      state.invitations.received = action.payload?.received;
      state.invitations.declined = action.payload?.declined;
      state.invitations.pending = action.payload?.pending;
      state.invitations.confirmed = action.payload?.confirmed;
    },
    updateInvitationsBackup: (state, action) => {
      state.invitationsBackup.received = action.payload?.received;
      state.invitationsBackup.declined = action.payload?.declined;
      state.invitationsBackup.pending = action.payload?.pending;
      state.invitationsBackup.confirmed = action.payload?.confirmed;
    },
    updateInvitationFilter: (state, action) => {
      state.invitationfilter = action.payload;
    },
  },
});

export const {
  updateInvitations,
  updateInvitationFilter,
  updateInvitationsBackup,
} = invitationsSlice.actions;

export default invitationsSlice.reducer;
