import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import GoogleIcon from "../Assets/Icons/Google.svg";
import AppleIcon from "../Assets/Icons/apple.svg";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import AppleLogin from "react-apple-login";
import Cookie from "js-cookie";
import AccountVarification from "../Pages/Auth/AccountVarification";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateUser } from "../Redux/Slices/userSlice";
import { jwtDecode } from "jwt-decode";
import GetEmailModal from "../Pages/Auth/GetEmailModal";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function RegisterWithGoogle({
  setLoading,
  googlebutton,
  applebutton,
  googlesource,
}) {
  const [t, i18n] = useTranslation("global");
  const [errors, setErrors] = useState(false);
  // const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [showAccountVarificationModal, setShowAccountVarificationModal] =
    useState(false);
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [getEmailModal, setGetEmailModal] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  const AccountVarifications = () => {
    setShowAccountVarificationModal(true);
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const email = res.data.email;
        const password = res.data.sub;
        const firstName = res.data.given_name;
        const lastName = res.data.family_name;

        signInOrRegister(email, password, firstName, lastName, false);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleAppleResponse = (response) => {
    if (response.error) {
      toast.error(response.error);
      return;
    }
    const info = jwtDecode(response.authorization.id_token);
    setpassword(info?.sub);
    const email = info?.email ?? null;
    if (!email && googlesource === "signup") {
      setGetEmailModal(true);
    } else {
      signInOrRegister(email, password, null, null, true);
    }
  };

  const handleEmailProvided = () => {
    setGetEmailModal(false);
    signInOrRegister(email, password, null, null, true);
  };

  const signInOrRegister = (
    email,
    password,
    firstName = null,
    lastName = null,
    apple = false
  ) => {
    if (googlesource === "signup") {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      const formdata = new FormData();
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("email", email);
      formdata.append("password", password);
      formdata.append("isApple", apple);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${apiEndpoint}/register`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return { error: { message: "Unauthorized" } };
          }
        })
        .then((result) => {
          setLoading(false);

          if (result.error) {
            toast.error(result.error.email);
            setErrors(result.error);
          } else {
            dispatch(updateUser(result.data.user));
            if (result.data.token != null) {
              Cookie.set("access_token", result.token, { expires: 14 });
            }
            Cookie.set("user_email", result.data.user.email, {
              expires: 14,
            });
            AccountVarifications();
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else if (googlesource === "signin") {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);
      formdata.append("isApple", apple);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/login`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return { error: { message: "Unauthorized" } };
          }
        })
        .then((result) => {
          setLoading(false);
          if (result.error) {
            if (result?.data?.user) {
              dispatch(updateUser(result.data.user));
            }
            if (result.error.email === "Email not verified!") {
              sessionStorage.clear();
              Cookie.set("user_email", result.data.user.email);
              Cookie.set("access_token", result.data.token, {
                expires: 14,
              });
              navigate("/accountconfirmation");
              return;
            } else if (result.error.email === "User with email not found!") {
              sessionStorage.clear();
              toast.error(result?.error?.email);
              return;
            } else if (
              result.error.email == "User with email already exists!"
            ) {
              toast.error("email already exists");
            } else if (result.error.password == "Incorrect Password") {
              toast.error("Email is not registered with google");
            }
            setErrors(result.error);
          } else {
            dispatch(updateUser(result.data.user));
            if (result.data.message == "Preferences not set!") {
              if (result.data.token != null) {
                Cookie.set("access_token", result.data.token, {
                  expires: 14,
                });
                Cookie.set("user_email", result.data.user.email, {
                  expires: 14,
                });
              }
              navigate(
                `/accountconfirmation/subscription/${result.data.user?.id}`
              );
            } else {
              if (result.data.token != null) {
                Cookie.set("access_token", result.data.token, {
                  expires: 14,
                });
              }
              Cookie.set(
                "subscription_status",
                result.data.user.subscription_status,
                { expires: 14 }
              );

              Cookie.set("user_email", result.data.user.email, {
                expires: 14,
              });
              navigate("/Dashboard");
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const handleAppleError = (error) => {
    console.error("Apple login error:", error); // Log error from Apple login
    setErrors(true);
    toast.error(t("signin.error"));
  };

  return (
    <Box sx={{ "& button": { my: 1 } }}>
      <Toaster />
      {/* {loading && (
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )} */}
      <div>
        <Button
          className="greybtn"
          onClick={() => login()}
          sx={{
            width: "100%",
            py: "10px",
            fontSize: { xs: "12px", md: "20px" },
            borderRadius: "8px",
          }}
          size="large"
          variant="contained"
          startIcon={
            <img
              src={GoogleIcon}
              alt="Google Icon"
              style={{ width: "28px", height: "28px" }}
            />
          }
        >
          {t(googlebutton)}
        </Button>
      </div>
      <div>
        <AppleLogin
          clientId="comgosigning.test"
          redirectURI="https://test.gosignings.com" // Replace with your redirect URI
          responseType="code id_token"
          responseMode="query"
          scope="name email"
          callback={handleAppleResponse}
          usePopup={true}
          designProp={{
            height: 44,
            width: "100%",
            color: "black",
            border: false,
            type: "sign in",
            border_radius: 15,
            scale: 1,
            locale: "en_US",
          }}
          render={(renderProps) => (
            <Button
              className="greybtn"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              sx={{
                width: "100%",
                py: "10px",
                fontSize: { xs: "12px", md: "20px" },
                borderRadius: "8px",
                mt: 2,
              }}
              size="large"
              variant="contained"
              startIcon={
                <img
                  src={AppleIcon}
                  alt="Apple Icon"
                  style={{ width: "28px", height: "28px" }}
                />
              }
            >
              {t(applebutton)}
            </Button>
          )}
        />

        <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
          {t("signin.or")}
        </Typography>
      </div>

      <AccountVarification
        open={showAccountVarificationModal}
        handleClose={() => setShowAccountVarificationModal(false)}
      />
      <GetEmailModal
        open={getEmailModal}
        handleContinue={handleEmailProvided}
        setemail={setemail}
        setGetEmailModal={setGetEmailModal}
      ></GetEmailModal>
    </Box>
  );
}
