import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div
            style={{
              display: "flex",
              width: "100vw",
              height: "95vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Something went wrong.</h1>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
