import { createSlice } from "@reduxjs/toolkit";
import { addAppleAccount } from "../../thunks/AppleAccount";

const appleSlice = createSlice({
  name: "appleAccount",
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null, // To store the response data if needed
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addAppleAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addAppleAccount.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.success = true;
        state.data = action.payload; // Store the successful response data
      })
      .addCase(addAppleAccount.rejected, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.success = false;
        state.error = action.payload || action.error.message; // Handle custom or generic error messages
      });
  },
});

export default appleSlice.reducer;
