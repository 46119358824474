import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container, TextField, Typography } from "@mui/material";
import AuthLayout from "../../Layouts/AuthLayout";
import ImgWithPopup from "../../Components/ImgWithPopup";
import SiteLogo from "../../Components/Logo";
import SigninAvatar from "../../Assets/Images/Sigininavatar.png";
import RegisterWithGoogle from "../../Components/RegisterWithGoogle";
import Password from "../../Components/Password";
import CheckboxLabel from "../../Components/Checkbox";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import Emailimg from "../../Assets/Images/emailimg.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "19px",
  padding: theme.spacing(1),
}));

export default function ResetPasswordEmail() {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  return (
    <AuthLayout>
      <Container zIndex sx={{ position: "relative" }}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
          <SiteLogo />
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          //   sx={{  }}
        >
          <Grid item xs={12} md={8} lg={8}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: "38px", xs: "18px" },
                fontWeight: { lg: 700, xs: 500 },
              }}
            >
              {t("reset_pass_email.hey")} <br />
              {t("reset_pass_email.reset_pass")}
            </Typography>
            <Typography
              sx={{
                color: "black",
                my: 1,
                fontSize: { lg: "24px", xs: "12px" },
                fontWeight: { lg: 400, xs: 400 },
              }}
            >
              {t("reset_pass_email.desc1")}
              <br />
              {t("reset_pass_email.desc2")}
              <br />
              {t("reset_pass_email.desc3")}
            </Typography>
            <Typography
              sx={{
                color: "black",
                my: 1,
                fontSize: { lg: "24px", xs: "12px" },
                fontWeight: { lg: 700, xs: 600 },
              }}
            >
              {t("reset_pass_email.copy_paste")} <br />
              <span style={{ color: "#F26F21" }}>gosignings.com/.........</span>
            </Typography>
            <Grid xs={12} lg={6}>
              <Link
                to="/signin/resetpassword"
                style={{ textDecoration: "none" }}
              >
                <Button
                  btnname="button.reset"
                  type="submit"
                  variant="outlined"
                  sx={{
                    my: 3,
                    borderColor: "#F26F21",
                    borderWidth: "2px",
                    borderRadius: "30px",
                    px: { lg: 5, xs: 3 },
                    py: 1.5,
                    fontWeight: 700,
                    fontSize: { lg: "20px", xs: "12px" },
                    mx: 1,
                    color: "#F26F21",
                    transition: "background-color 0.5s ease, color 0.7s ease",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#F26F21",
                      color: "white",
                      borderColor: "#F26F21",
                    },
                  }}
                />
              </Link>
            </Grid>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: "20px", xs: "12px" },
                fontWeight: { lg: 400, xs: 400 },
                position: "relative",
              }}
            >
              {t("reset_pass_email.llc")}
              <br />
              {t("reset_pass_email.visit")}{" "}
              <span style={{ color: "#F26F21" }}>
                {" "}
                www.centralmasssoftware.com
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            xlg={4}
            sx={{ display: { md: "block", xs: "none" } }}
          >
            <img
              src={Emailimg}
              alt="Emailimg"
              style={{
                maxWidth: "100%",
                height: "80vh",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </AuthLayout>
  );
}
