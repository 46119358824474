import React, { useEffect, useState } from "react";
import customMarkerImage from "../Assets/Icons/Marker.png";
import LocationIcon from "../Assets/Icons/location.svg";
import currentlocationicon from "../Assets/Icons/currentlocationicon.png";

import { useSelector } from "react-redux";

const Map = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [distance, setdistance] = useState("");
  const [duration, setduration] = useState("");
  // console.log(distance);

  const events = useSelector((state) => state.calenderEvents.data);

  const emails = useSelector((state) => {
    return state.selectedCalendarEmails.selectedCalendarEmails;
  });

  const [Orders, setOrders] = useState([]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(pos);
        setUserLocation(pos);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const GEOCODE_API_URL = "https://maps.googleapis.com/maps/api/geocode/json";
  const API_KEY = "AIzaSyCi76nnMh57AEohyRP5kQU2SpH6mMpf3y0";

  useEffect(() => {
    const fetchCoordinates = async (address) => {
      try {
        const response = await fetch(
          `${GEOCODE_API_URL}?address=${encodeURIComponent(
            address
          )}&key=${API_KEY}`
        );
        const data = await response.json();
        const { results } = data;
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          return { lat, lng };
        }
        return null;
      } catch (error) {
        console.error("Error fetching coordinates:", error);
        return null;
      }
    };

    const processEvents = async () => {
      if (events && emails.length > 0 && userLocation) {
        const mappedEvents = await Promise.all(
          events.map(async (event) => {
            const coordinates = await fetchCoordinates(event.location);
            return {
              ...event,
              title: event.summary,
              start: userLocation,
              end: coordinates,
            };
          })
        );

        const filtered = mappedEvents.filter((event) => {
          return emails.some((email) => email.account_email === event.email);
        });
        setOrders(filtered);
      } else {
        setOrders([]);
      }
    };

    processEvents();
  }, [events, emails, userLocation]);

  // useEffect(() => {
  //   if (events && emails.length > 0 && userLocation) {
  //     const mappedEvents = events.map((event) => ({
  //       ...event,
  //       title: event.summary,
  //       start: userLocation,
  //       end: event.location,
  //     }));
  //     const filtered = mappedEvents.filter((event) => {
  //       return emails.map((email) => {
  //         return email.account_email == event.creator.email;
  //       });
  //     });
  //     setOrders(filtered);
  //   } else {
  //     setOrders([]);
  //   }
  // }, [events, emails, userLocation]);

  useEffect(() => {
    if (userLocation) {
    }
    const handleLocationError = (
      browserHasGeolocation,
      infoWindow,
      map,
      pos
    ) => {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? `<img src="${LocationIcon}" alt="Location found" style="width: 100px; height: auto;">`
          : "Error: Your browser doesn't support geolocation."
      );
      infoWindow.open(map);
    };

    const initMap = (mapElement) => {
      let map, infoWindow;
      let activeDirectionsRenderer = null;
      let userMarker = null;
      let userToOrderPolyline = null;

      map = new window.google.maps.Map(mapElement, {
        zoom: 12,
        center: { lat: 34.1536, lng: 73.2482 },
      });

      infoWindow = new window.google.maps.InfoWindow();
      const icon = document.createElement("img");
      icon.src = currentlocationicon;
      icon.alt = "Current Location Icon";
      icon.style.width = "50px";
      icon.style.height = "50px";
      icon.style.cursor = "pointer";
      icon.style.marginRight = "5px";
      icon.classList.add("custom-map-control-button");
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(icon);

      const routeInfoDiv = document.createElement("div");
      routeInfoDiv.id = "routeInfoDiv";
      routeInfoDiv.style.position = "absolute";
      routeInfoDiv.style.top = "10px";
      routeInfoDiv.style.right = "10px";
      routeInfoDiv.style.backgroundColor = "white";
      routeInfoDiv.style.padding = "10px";
      routeInfoDiv.style.borderRadius = "5px";
      routeInfoDiv.style.boxShadow = "0 2px 6px rgba(0,0,0,0.3)";
      routeInfoDiv.style.display = "none";
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        routeInfoDiv
      );

      icon.addEventListener("click", () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              setUserLocation(pos);

              // Inside the click event listener for the locationButton
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const pos = {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    };
                    infoWindow.setPosition(pos);
                    // infoWindow.setContent(`<img src="${LocationIcon}" alt="Location found" style="width: 40px; height: 40px;">`);
                    infoWindow.setContent("Location found.");
                    infoWindow.open(map);
                    map.panTo(pos);

                    setUserLocation(pos);

                    if (!userMarker) {
                      userMarker = new window.google.maps.Marker({
                        position: pos,
                        map,
                        icon: {
                          url: customMarkerImage,
                          scaledSize: new window.google.maps.Size(40, 40),
                        },
                      });
                    } else {
                      userMarker.setPosition(pos);
                    }
                  },

                  () => {
                    handleLocationError(true, infoWindow, map, map.getCenter());
                  }
                );
                // console.log("pos");
                // console.log(pos);
              } else {
                handleLocationError(false, infoWindow, map, map.getCenter());
              }

              infoWindow.setPosition(pos);
              infoWindow.setContent(
                `<img src="${LocationIcon}" alt="Location found" style="width: 40px; height: 40px;">`
              );
              infoWindow.open(map);
              map.setCenter(pos);
            },
            () => {
              handleLocationError(true, infoWindow, map, map.getCenter());
            }
          );
        } else {
          handleLocationError(false, infoWindow, map, map.getCenter());
        }
      });

      // const Orders = [
      //   {
      //     start: { lat: 34.195116, lng: 73.242294 },
      //     end: { lat: 34.236847, lng: 73.240313 },
      //     title: "Order1",
      //   },
      //   {
      //     start: { lat: 34.171828, lng: 73.240313 },
      //     end: { lat: 34.213741, lng: 73.236298 },
      //     title: "Order2",
      //   },
      //   {
      //     start: { lat: 34.168139, lng: 73.240813 },
      //     end: { lat: 34.202542, lng: 73.236585 },
      //     title: "Order3",
      //   },
      //   {
      //     start: { lat: 34.140287, lng: 73.241637 },
      //     end: { lat: 34.115349, lng: 73.237727 },
      //     title: "Order4",
      //   },
      //   {
      //     start: { lat: 34.115349, lng: 73.237727 },
      //     end: { lat: 34.195116, lng: 73.242294 },
      //     title: "Order5",
      //   },
      // ];

      const markers = [];
      const directionsRenderers = [];

      const renderOrder = (order, index) => {
        const { start, end, title } = order;

        const startMarker = new window.google.maps.Marker({
          position: start,
          map,
          title: `${index + 1}. ${title} (Start)`,
          label: {
            text: `${index + 1}`,
            color: "#ffffff",
            fontWeight: "bold",
          },
          optimized: false,
          icon: {
            url: customMarkerImage,
            scaledSize: new window.google.maps.Size(40, 40),
          },
        });

        const endMarker = new window.google.maps.Marker({
          position: end,
          map,
          title: `${index + 1}. ${title} (End)`,
          label: {
            text: `${index + 1}`,
            color: "#ffffff",
            fontWeight: "bold",
          },
          optimized: false,
          icon: {
            url: customMarkerImage,
            scaledSize: new window.google.maps.Size(40, 40),
          },
        });

        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer({
          map,
          suppressMarkers: true,
        });

        const request = {
          origin: start,
          destination: end,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);

            setdistance(result.routes[0].legs[0].distance.text);
            setduration(result.routes[0].legs[0].duration.text);

            startMarker.addListener("click", () => {
              infoWindow.close();
              infoWindow.setContent(`
          <div>
            <b>${title}</b>
            <br>
            Distance: ${distance}
            <br>
            Duration: ${duration}
          </div>`);
              infoWindow.open(startMarker.getMap(), startMarker);
              hideOtherOrders(index);

              // routeInfoDiv.style.display = "none"; // Show the route info div
              showUserToOrderPath(start);
            });

            endMarker.addListener("click", () => {
              infoWindow.close();
              infoWindow.setContent(`
          <div>
            <b>${title}</b>
            <br>
            Distance: ${distance}
            <br>
            Duration: ${duration}
          </div>`);
              infoWindow.open(endMarker.getMap(), endMarker);
              hideOtherOrders(index);
              // routeInfoDiv.style.display = "block"; // Show the route info div
              showUserToOrderPath(end);
            });
          } else {
            console.error("Directions request failed due to " + status);
          }
        });

        markers.push({ startMarker, endMarker });
        directionsRenderers.push(directionsRenderer);
      };

      const hideOtherOrders = (activeIndex) => {
        markers.forEach((markerSet, index) => {
          if (index !== activeIndex) {
            markerSet.startMarker.setMap(null);
            markerSet.endMarker.setMap(null);
            directionsRenderers[index].setMap(null);
          } else {
            markerSet.startMarker.setMap(map);
            markerSet.endMarker.setMap(map);
            directionsRenderers[index].setMap(map);
            directionsRenderers[index].setDirections(
              directionsRenderers[index].getDirections()
            );
          }
        });
      };

      const showUserToOrderPath = (destination) => {
        if (!userLocation || !destination) return;

        const directionsService = new window.google.maps.DirectionsService();
        const request = {
          origin: userLocation,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setdistance(result.routes[0].legs[0].distance.text);
            setduration(result.routes[0].legs[0].duration.text);

            // routeInfoDiv.innerHTML += `
            // </br>
            //   <div>Distance: ${result.routes[0].legs[0].distance.text}</div>
            //   <div id="routeInfoDiv">Duration: ${result.routes[0].legs[0].duration.text}</div>
            // `;
            // routeInfoDiv.style.display = "block";

            if (userToOrderPolyline) {
              userToOrderPolyline.setMap(null);
            }

            userToOrderPolyline = new window.google.maps.Polyline({
              path: result.routes[0].overview_path,
              strokeColor: "#83C066",
              strokeOpacity: 1,
              strokeWeight: 5,
              icons: [
                {
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 4,
                    strokeColor: "#83C066",
                  },
                  offset: "0%",
                  repeat: "20px",
                },
              ],
              map,
            });
          } else {
            console.error("Directions request failed due to " + status);
          }
        });
      };

      Orders.forEach((order, index) => {
        renderOrder(order, index);
      });
    };

    if (window.google && window.google.maps) {
      const mapElement = document.getElementById("map");
      initMap(mapElement);
    } else {
      window.initMap = initMap;
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCi76nnMh57AEohyRP5kQU2SpH6mMpf3y0&callback=initMap`;
      script.defer = true;
      script.async = true;

      const existingScript = document.querySelector(
        'script[src^="https://maps.googleapis.com/maps/api/js"]'
      );
      if (existingScript) {
        existingScript.remove();
      }

      document.head.appendChild(script);
    }
  }, [userLocation, Orders]);

  return <div id="map" style={{ height: "600px" }}></div>;
};

export default Map;
