import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Button from "../../Components/Button";
import PopoverHover from "../../Components/Popover";
import RowRadioButtonsGroup from "../../Components/RowRadioButtonsGroup";
import { useTranslation } from "react-i18next";
import GoogleCalnder from "../../Components/GoogleCalnder";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";

import { useSelector, useDispatch } from "react-redux";
import { updateStepper5 } from "../../Redux/Slices/preferenceSlice";
import { updateUser } from "../../Redux/Slices/userSlice";
import {
  setCurrentStepper,
  setSteppers,
} from "../../Redux/Slices/stepperInfoSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import toast from "react-hot-toast";
import Outlook from "../Auth/Outlook";
import { postRequest } from "../../Requests/Requests";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import GoogleCalendar from "../../Components/GoogleCalnder";
import AppleCalendar from "../Auth/Apple/AppleCalendar";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const StepperStep5 = ({
  onBack,
  onNext,
  lg = 7,
  show,
  // formData,
  // onFormChange,
  stepperData,
  // setFormData,
}) => {
  const [t, i18n] = useTranslation("global");
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [calendarError, setCalendarError] = useState(false);
  const [reminderError, setReminderError] = useState(false);
  const [refreshTokens, setRefreshTokens] = useState({});
  const [loading, setLoading] = useState(false);

  const [notificationError, setNotificationError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxFormData = useSelector((state) => state.preferences.stepper5);
  const user = useSelector((state) => state.user.user);
  const [stepper5Completed, setStepper5Completed] = useState(false);

  const prefSet =
    typeof user?.preferences_set == Boolean
      ? user?.preferences_set
      : user?.preferences_set == "true"
      ? true
      : false;

  const [formData, setFormData] = useState(
    reduxFormData ?? {
      calendar: "",
      reminder: "",
      notification: "",
      accounts: [],
      mail_accounts: [],
    }
  );

  const onFormChange = (formKey, fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const [calenderAccountAdded, setCalenderAccountAdded] = useState(false);
  const stepperInfo = useSelector((state) => state.stepperInfo.steppers);

  console.log('');
  useEffect(() => {
    // if (true) {
    if (stepperInfo?.stepper_5 && !reduxFormData) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/get/stepper5`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          if (result.error) {
            setLoading(false);
          } else {
            setLoading(false);
            if (result?.data) {
              setFormData(result?.data);
              dispatch(updateStepper5({ ...result?.data }));
              dispatch(setSteppers(result?.stepperInfo));
              if (prefSet) {
                setStepper5Completed(true);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [calenderAccountAdded]);

  const handleEmailSelect = (email, refreshToken) => {
    setSelectedEmails(email);
    setRefreshTokens(refreshToken);

    setFormData((prevData) => ({
      ...prevData,
      accounts: [
        ...prevData?.accounts,
        { account_email: email, refresh_token: refreshToken },
      ],
    }));
  };

  const [selectedcalenderState, setSelectedcalenderState] =
    React.useState("default_value");
  const calender = [
    { value: "Google Calendar", label: "prefrence.google_calender" },
    { value: "Apple I Calendar", label: "prefrence.apple_calender" },
  ];
  const [selectedReminderState, setSelectedReminderState] =
    React.useState("default_value");
  const Reminder = [
    { value: "Daily", label: "prefrence.daily" },
    { value: "Weekly", label: "prefrence.weekly" },
  ];
  const [selectedNotificationsState, setSelectedNotificationsState] =
    React.useState("default_value");
  const Notifications = [
    { value: "Email", label: "prefrence.email" },
    { value: "Text Messages", label: "prefrence.msg" },
    { value: "None", label: "prefrence.none" },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!formData.calendar) {
      setCalendarError(true);
      return;
    }
    if (!formData.reminder) {
      setReminderError(true);
      return;
    }
    if (!formData.notification) {
      setNotificationError(true);
      return;
    }
    if (formData.calendar && formData.reminder && formData.notification) {
      sessionStorage.setItem("step5", false);
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );

      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("data", JSON.stringify(formData));
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/add/stepper5`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          setLoading(false);
          if (result.error) {
            toast.error(result.error);
            setLoading(false);
          } else {
            dispatch(updateStepper5({ ...result?.data }));
            dispatch(updateUser(result?.user));
            // dispatch(setSteppers(result?.stepperInfo));
            navigate("/Dashboard");
          }
        })
        .catch((error) => {
          toast.error("some error occured! refresh the page and try again!");
          console.error(error);
          setLoading(false);
        });
    }
  };

  const removeEmail = (email) => {
    setFormData((prevData) => ({
      ...prevData,
      accounts: prevData.accounts.filter((item) => item.account_email != email),
    }));
  };

  const handleupdate = () => {
    if (!formData.calendar) {
      setCalendarError(true);
      return;
    }
    if (!formData.reminder) {
      setReminderError(true);
      return;
    }
    if (!formData.notification) {
      setNotificationError(true);
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("data", JSON.stringify(formData));
    form_data.append("email", user?.email);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/preferences/update/stepper5`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        console.log(result);
        if (result.error) {
          toast.error(result.error);

          setLoading(false);
        } else {
          setLoading(false);
          if (result?.data) {
            setFormData(result.data);
            dispatch(updateStepper5({ ...result?.data }));
            toast.success("Updated");
          }
        }
      })
      .catch((error) => {
        toast.error("some error occured! refresh the page and try again!");
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllSigningAgencies();
  }, []);

  const [agencies, setAgencies] = useState([]);

  const getAllSigningAgencies = async () => {
    try {
      const formData = new FormData();
      formData.append("email", user?.email);
      postRequest("/get/signingAgencies", formData)
        .then((data) => setAgencies(data?.agencies))
        .catch((e) => console.error(e));
    } catch (e) {}
  };

  const addMailAccount = () => {
    setFormData((prevData) => ({
      ...prevData,
      mail_accounts: [
        ...prevData.mail_accounts,
        {
          account_type: null,
          account_email: null,
          refresh_token: null,
          signing_agency_id: null,
          user_id: user?.id,
        },
      ],
    }));
  };

  return (
    <Container sx={{ position: "relative" }}>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "transparent",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}

      <Typography
        sx={{
          mx: { lg: "auto", xs: 3 },
          textAlign: "center",
          fontSize: { lg: "40px", xs: "18px" },
          fontWeight: { lg: 700, xs: 600 },
        }}
      >
        {show && (
          <>
            {" "}
            <span> {user?.first_name}</span> {t("prefrence.step5_title")}
          </>
        )}
      </Typography>
      <Grid container>
        <Grid
          item
          lg={lg}
          xs={12}
          sx={{
            backgroundColor: "white",
            mx: "auto",
            my: 2,
            p: { lg: 3, xs: 2 },
            borderRadius: "8px",
          }}
        >
          <form onSubmit={handleFormSubmit}>
            {/* Calendar Radio Group */}
            <Grid sx={{ display: "flex" }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  fontSize: { lg: "25px", xs: "14px" },
                  fontWeight: { lg: 600, xs: 500 },
                  color: "black",
                }}
              >
                {t("prefrence.calendar")}
              </FormLabel>
              <Grid className="radiopopover">
                <PopoverHover text={t("prefrence.calendar_popover")} />
              </Grid>
            </Grid>
            <Box>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="calendar"
                id="calendar"
                value={formData.calendar}
                onChange={(event) => {
                  onFormChange("stepper5", "calendar", event.target.value);
                  setCalendarError(false); // Reset error state
                }}
              >
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {formData?.accounts?.length < 1 && (
                      <>
                        <FormControlLabel
                          value="google"
                          control={
                            <Radio
                              checked={formData?.calendar == "google"}
                              sx={{
                                color: "#F6B980",
                                "&.Mui-checked": {
                                  color: "#F6B980",
                                },
                              }}
                            />
                          }
                          label={t("prefrence.google_calender")}
                        />

                        {formData?.calendar == "google" && (
                          <GoogleCalnder
                            activity={"calendar"}
                            onEmailSelect={handleEmailSelect}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", ms: 1 }}>
                  {formData?.accounts?.length < 1 && (
                    <>
                      <FormControlLabel
                        value="outlook"
                        control={
                          <Radio
                            checked={formData?.calendar == "outlook"}
                            sx={{
                              color: "#F6B980",
                              "&.Mui-checked": {
                                color: "#F6B980",
                              },
                            }}
                          />
                        }
                        // label={t("prefrence.apple_calender")}
                        label={"Outlook calendar"}
                      />
                      {formData?.calendar == "outlook" && (
                        <Outlook
                          activity={"calendar"}
                          authFlowType={"calendar"}
                          setLoading={setLoading}
                          setCalenderAccountAdded={setCalenderAccountAdded}
                          onEmailSelect={handleEmailSelect}
                        />
                      )}
                    </>
                  )}
                </Box>
              </RadioGroup>
              <div style={{ marginTop: 1, marginBottom: 10 }}>
                {formData?.accounts &&
                  formData?.accounts?.length > 0 &&
                  formData?.accounts?.map((item, index) => {
                    if (item.account_email) {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            my: 1,
                          }}
                        >
                          {item.account_email}
                          {""}
                          <CancelIcon
                            sx={{ cursor: "pointer", mx: 2 }}
                            onClick={() => removeEmail(item.account_email)}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
              {calendarError && (
                <FormHelperText error>Please select a calendar</FormHelperText>
              )}
            </Box>

            {/* // add mail for agencies  */}
            <Grid sx={{ display: "flex" }}>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  fontSize: { lg: "25px", xs: "14px" },
                  fontWeight: { lg: 600, xs: 500 },
                  color: "black",
                }}
              >
                {/* {t("prefrence.calendar")} */}
                {"Mails"}
              </FormLabel>
              <Grid className="radiopopover">
                <PopoverHover text={t("prefrence.calendar_popover")} />
              </Grid>
            </Grid>

            {/* ///////////////////////////////////////////////////////////////////////  */}

            <Button
              btnname="Add mail account"
              type="button"
              onClick={addMailAccount}
              variant="contained"
              className="greybtn"
              sx={{
                px: { lg: 7, xs: 3 },
                py: 1.2,
                fontSize: { lg: "16px", xs: "12px" },
                mx: 1,
                borderRadius: "8px",
                my: 1,
              }}
            />

            {formData.mail_accounts &&
              formData.mail_accounts?.map((item, index) => {
                return (
                  <>
                    <Box sx={{ display: "flex", my: 1 }}>
                      <FormControl sx={{ width: "30%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Select Agency
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            formData?.mail_accounts[index].signing_agency_id
                          }
                          label="Select Agency"
                          onChange={(e) => {
                            let mailAccounts = [...formData.mail_accounts];
                            mailAccounts[index] = {
                              ...mailAccounts[index],
                              signing_agency_id: e.target.value,
                            };

                            setFormData((prevData) => ({
                              ...prevData,
                              mail_accounts: mailAccounts,
                            }));
                          }}
                        >
                          {agencies &&
                            agencies.map((item) => {
                              return (
                                <MenuItem value={item?.id}>
                                  {item?.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {formData.mail_accounts[index].account_email ? (
                          <>
                            <Typography sx={{ mx: 2 }}>
                              {formData.mail_accounts[index].account_email}
                            </Typography>
                          </>
                        ) : (
                          <>
                            {/* <GoogleIcon sx={{ cursor: "pointer", ml: 2 }} /> */}
                            <GoogleCalendar
                              activity={"mail"}
                              setFormData={setFormData}
                              formData={formData}
                              index={index}
                            />
                            <Outlook
                              activity={"mail"}
                              setFormData={setFormData}
                              formData={formData}
                              index={index}
                              authFlowType="calendar"
                            />
                            <AppleCalendar
                              activity={"mail"}
                              setFormData={setFormData}
                              formData={formData}
                              index={index}
                              authFlowType="calendar"
                            />
                          </>
                        )}
                        <CancelIcon
                          sx={{ cursor: "pointer", mx: 2 }}
                          onClick={() => {
                            let mailAccounts = [...formData.mail_accounts];
                            mailAccounts.splice(index, 1);
                            setFormData((prevData) => ({
                              ...prevData,
                              mail_accounts: mailAccounts,
                            }));
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                );
              })}

            {/* ///////////////////////////////////////////////////////////////////////  */}

            {/* Reminder Radio Group */}
            <Box sx={{ display: "flex", my: 2 }}>
              <RowRadioButtonsGroup
                label={t("prefrence.report")}
                labelConfigs={Reminder}
                selectedValue={formData.reminder}
                setSelectedValue={(value) => {
                  onFormChange("stepper5", "reminder", value);
                  setReminderError(false); // Reset error state
                }}
              />
              {reminderError && (
                <FormHelperText error>Please select a reminder</FormHelperText>
              )}
            </Box>
            {/* Notification Radio Group */}
            <Box sx={{ display: "flex", my: 2 }}>
              <RowRadioButtonsGroup
                label={t("prefrence.notification")}
                labelConfigs={Notifications}
                selectedValue={formData.notification}
                setSelectedValue={(value) => {
                  onFormChange("stepper5", "notification", value);
                  setNotificationError(false); // Reset error state
                }}
              />
              {notificationError && (
                <FormHelperText error>
                  Please select a notification
                </FormHelperText>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                btnname="button.back"
                type="button"
                onClick={onBack}
                variant="contained"
                className="greybtn"
                sx={{
                  px: { lg: 7, xs: 3 },
                  py: 1.2,
                  fontSize: { lg: "16px", xs: "12px" },
                  mx: 1,
                  borderRadius: "8px",
                }}
              />

              {stepperInfo && stepperInfo?.stepper_5 && prefSet ? (
                // {reduxFormData && prefSet ? (
                <>
                  <Button
                    btnname="udpate"
                    onClick={handleupdate}
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                </>
              ) : (
                <Button
                  btnname="button.next"
                  type="submit"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    px: { lg: 7, xs: 3 },
                    py: 1.2,
                    fontSize: { lg: "16px", xs: "12px" },
                    mx: 1,
                    borderRadius: "8px",
                  }}
                />
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
export default StepperStep5;
