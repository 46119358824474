import React, { useState } from "react";
import { Grid } from "@mui/material";
import PendingListBox from "../../../Components/PendingListBox";
import Map from "../../../Components/map";
import { useTranslation } from "react-i18next";
import { updateInvitationsBackup } from "../../../Redux/Slices/InvitationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../../Requests/Requests";

const PendingList = ({ Pending }) => {
  const [t, i18n] = useTranslation("global");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  // Logic to paginate the Pending items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Pending?.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          {currentItems &&
            currentItems.map((item, index) => (
              <PendingListBox
                key={index}
                order={item}
                recieveddate={item.created_at}
                recievedtime={item.closing_time}
                orderdate={item.closing_date}
                location={item.closing_city}
                status={item.closing_status}
              />
            ))}

          {/* Pagination controls */}
          <div style={{ marginTop: "20px" }}>
            {Pending &&
              Array.from(
                { length: Math.ceil(Pending.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                      margin: "0 5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      backgroundColor:
                        currentPage === index + 1 ? "#ccc" : "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  >
                    {index + 1}
                  </button>
                )
              )}
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          {currentItems && currentItems?.length > 0 && <Map />}
        </Grid>
      </Grid>
    </div>
  );
};

export default PendingList;
