import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { updateCalenderEvents } from "../Redux/Slices/calendarSlice";

const localizer = momentLocalizer(moment);
const eventPropGetter = (event) => {
  return {
    style: {
      backgroundColor: event.color,
      color: "#8C8C8C",
    },
  };
};

const BigCalendar = () => {
  const events = useSelector((state) => state.calenderEvents.data);

  const emails = useSelector((state) => {
    return state.selectedCalendarEmails.selectedCalendarEmails;
  });

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (events && events.length > 0 && emails.length > 0) {
      setloading(true);
      const mappedEvents = events.map((event) => ({
        ...event,
        title: event?.summary,
        start: new Date(event?.start?.dateTime),
        end: new Date(event?.end?.dateTime),
      }));
      const filtered = mappedEvents.filter((event) => {
        return emails.some((email) => email.account_email === event.email);
      });
      setFilteredEvents(filtered);
      setloading(false);
    } else {
      setFilteredEvents([]);
      setloading(false);
    }
  }, [events, emails]);

  const dispatch = useDispatch();

  const cleareventsfromredux = () => {
    dispatch(updateCalenderEvents(null));
    // console.log("cleard");
  };
  // console.log(events)

  return (
    <div className="App">
      <main>
        <div style={{ height: 500 }}>
          {/* <Typography sx={{ cursor: "pointer" }} onClick={cleareventsfromredux}>
            cleareventsfromredux
          </Typography> */}
          {loading && <Typography>Loading...</Typography>}
          {!loading && (
            <Calendar
              localizer={localizer}
              events={filteredEvents}
              startAccessor="start"
              endAccessor="end"
              style={{
                padding: "0px",
                backgroundColor: "#fff",
                border: "1px solid #ddd",
              }}
              eventPropGetter={eventPropGetter}
              titleAccessor="summary"
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default BigCalendar;
