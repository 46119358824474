// src/Redux/Slices/authFlowSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userinfo: null,
  authFlowType: null,
};

const authFlowSlice = createSlice({
  name: "authFlow",
  initialState,
  reducers: {
    setauthFlowUserinfo: (state, action) => {
      state.userinfo = action.payload;
    },
    setAuthFlowType: (state, action) => {
      state.authFlowType = action.payload;
    },
  },
});

export const { setauthFlowUserinfo, setAuthFlowType } = authFlowSlice.actions;

export default authFlowSlice.reducer;
