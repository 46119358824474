import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { incrementNotifications } from "../Redux/Slices/notificationsSlice";
import {
  updateInvitations,
  updateInvitationsBackup,
} from "./Slices/InvitationsSlice";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

window.Pusher = Pusher;

const NotificationChannel = (userId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId) return;

    const echo = new Echo({
      broadcaster: "pusher",
      // key: process.env.REACT_APP_PUSHER_KEY,
      // cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      key: "70292e4e4fbfbb592e90",
      cluster: "ap2",
      forceTLS: true,
    });

    const channel = echo.channel("Gosignings");

    const handleNotification = (data) => {
      dispatch(incrementNotifications(data));
    };

    const handleinvitation = (data) => {
      // console.log(data)
      // dispatch(updateInvitations(data[0]));
      dispatch(updateInvitationsBackup(data[0]));
    };

    channel.listen(`.30-day-trail.${userId}`, handleNotification);
    channel.listen(`.update-invitations.${userId}`, handleinvitation);

    return () => {
      channel.stopListening(`.30-day-trail.${userId}`, handleNotification);
      channel.stopListening(`.update-invitations.${userId}`, handleinvitation);
      echo.leaveChannel("Gosignings");
    };
  }, [userId, dispatch]);
};

export default NotificationChannel;
