import React from "react";
import { Grid, Typography } from "@mui/material";
import ModalLayout from "../Layouts/ModalLayout";
import Button from "../Components/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const RejectionReasonModal = ({ open, handleClose, parentNotify, reason }) => {
  const [t, i18n] = useTranslation("global");
  const [isFormValid, setIsFormValid] = React.useState(false); // State for form validation

  const handleReject = async () => {
    await parentNotify(); // Notify the parent component
    handleClose(); // Close the modal in the parent component
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      handleReject();
    }
  };

  const handleInputChange = (e) => {
    // Update isFormValid based on the input field's content
    setIsFormValid(e.target.value.trim() !== "");
  };

  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "40%", xs: "60%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "60%", xs: "70%" },
        mx: { sm: 5, lg: 0 },
        borderRadius: "10px",
        bgcolor: "background.paper",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Typography
          sx={{
            fontSize: { lg: "24px", xs: "18px" },
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {t("rejection.reasontitle")}
        </Typography>
        <TextField
          disabled
          fullWidth
          required
          label={t("rejection.reason")}
          placeholder={t("rejection.reason_placeholder")}
          multiline
          rows={4}
          value={reason}
          variant="outlined"
          sx={{
            borderRadius: "11px",
            my: 3,
            // backgroundColor: "#FAFAFA",
            border: "1px solid #8C8C8C",
            color: "black",
          }}
          onChange={handleInputChange} // Handle input changes
        />
        <Grid
          sx={{
            my: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            btnname="button.cancel"
            onClick={handleClose}
            variant="contained"
            className="Orangebtn"
            sx={{
              borderRadius: "8px",
              px: { lg: 7, xs: 3 },
              py: 1.2,
              fontSize: { lg: "16px", xs: "12px" },
              mx: 1,
              color: "white",
              transition: "background-color 0.5s ease, color 0.7s ease",
              backgroundColor: "white",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", backgroundColor: "#ECECEC" },
            }}
          />
          {/* <Button
            btnname="button.submit"
            type="submit"
            variant="contained"
            className="Orangebtn"
            sx={{
              px: { lg: 7, xs: 3 },
              py: 1.2,
              fontSize: { lg: "16px", xs: "12px" },
              mx: 1,
            }}
          /> */}
        </Grid>
      </form>
    </ModalLayout>
  );
};

export default RejectionReasonModal;
