import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "orders",
  initialState: {
    data: null,
    BackupOrders: null,
    orderfilter: "day",
  },
  reducers: {
    updateOrders: (state, action) => {
      state.data = action.payload;
    },
    updateBackupOrders: (state, action) => {
      state.BackupOrders = action.payload;
    },
    updateOrderFilter: (state, action) => {
      state.orderfilter = action.payload;
    },
  },
});

export const { updateOrders, updateBackupOrders,updateOrderFilter } = orderSlice.actions;
export default orderSlice.reducer;
