import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import AuthLayout from "../../Layouts/AuthLayout";
import StepperStep1 from "./StepperStep1";
import StepperStep2 from "./StepperStep2";
import StepperStep3 from "./StepperStep3";
import { Button } from "@mui/material";
import StepperStep4 from "./StepperStep4";
import StepperStep5 from "./StepperStep5";
import Cookie from "js-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../Redux/Slices/userSlice";
import { setSteppers } from "../../Redux/Slices/stepperInfoSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const steps = ["", "", "", "", ""];

export default function HorizontalStepper() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user_id } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [default_invitaion_settings, setdefault_invitaion_settings] =
    useState(true);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const userSlice = useSelector((state) => state.user);
  const stepperInfo = useSelector((state) => state.stepperInfo.steppers);

  useEffect(() => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    const form_data = new FormData();
    form_data.append("user_id", user_id);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/get/user/info`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        if (result.error) {
          setLoading(false);
        } else {
          setLoading(false);
          dispatch(updateUser(result?.user));
          dispatch(setSteppers(result?.stepperInfo));
          Cookie.set("access_token", result?.token);
          Cookie.set("user_email", result?.user?.email);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const [startTimes, setStartTimes] = useState(new Array(7).fill(null));
  const [endTimes, setEndTimes] = useState(new Array(7).fill(null));

  return (
    <AuthLayout sx={{ maxHeight: "100vh", minHeight: "100%" }}>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}

      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{ width: { lg: "50%", xs: "90%" }, mx: "auto", pt: 7 }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton
              sx={{ zIndex: 1000, color: "red" }}
              color="inherit"
              onClick={handleStep(index)}
              completed={completed[index]}
              disabled={index > completedSteps()}
            >
              {" "}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              {activeStep === 0 && <StepperStep1 onNext={handleNext} />}
              {activeStep === 1 && (
                <StepperStep2
                  setloading={setLoading}
                  onBack={handleBack}
                  onNext={handleNext}
                  show={true}
                />
              )}
              {activeStep === 2 && (
                <StepperStep3
                  onBack={handleBack}
                  onNext={handleNext}
                  show={true}
                  startTimes={startTimes}
                  endTimes={endTimes}
                  setStartTimes={setStartTimes}
                  setEndTimes={setEndTimes}
                  setdefault_invitaion_settings={setdefault_invitaion_settings}
                />
              )}
              {activeStep === 3 && (
                <StepperStep4
                  onBack={handleBack}
                  onNext={handleNext}
                  show={true}
                  setdefault_invitaion_settings={setdefault_invitaion_settings}
                />
              )}
              {activeStep === 4 && (
                <StepperStep5
                  onBack={handleBack}
                  onNext={handleNext}
                  show={true}
                />
              )}
            </Typography>
          </React.Fragment>
        )}
      </div>
    </AuthLayout>
  );
}
