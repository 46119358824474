import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ForgetAvatar from "../../Assets/Images/forget avatar.png";
import Paper from "@mui/material/Paper";
import { Container, Typography } from "@mui/material";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import CustomField from "../../Components/CustomField";
import RightImgWithPopup from "../../Components/RightImgWithPopup";
import ForgetEmailModal from "./ForgetEmailModal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function ForgetPassword({
  open,
  handleClose,
  setShowForgetPasswordModal,
  setloading,
}) {
  const [t, i18n] = useTranslation("global");
  const [showForgetEmail, setShowForgetEmail] = useState(false);
  const [email, setEmail] = useState("");
  const ForgetEmail = () => {
    setShowForgetEmail(true);
  };

  const handleSubmit = (e) => {
    setloading(true);
    e.preventDefault();
    setShowForgetPasswordModal(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      console.error("text doesn't meet the criteria.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append(
      "Origingosigning",
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );

    const formdata = new FormData();
    formdata.append("email", email);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };
    fetch(`${apiEndpoint}/reset/password`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          return { error: { message: "Unauthorized" } };
        }
      })
      .then((result) => {
        if (result.error) {
          // console.log(result.error);
          setloading(false);
        } else {
          // console.log(result);
          ForgetEmail();
          setloading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  };

  const theme = useTheme();
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
      }}
    >
      <Button
        sx={{
          position: "absolute",
          top: "8%",
          right: "5%",
          color: "#fff",
          fontSize: { lg: "22px", xs: "12px" },
          "&:hover": { boxShadow: "none", color: "red" },
        }}
        onClick={handleClose}
        btnname="x"
      />
      {/* </Button> */}
      <Container zIndex sx={{}}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ maxHeight: "100%", minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xlg={6}
            sx={{ my: "auto", position: "relative" }}
          >
            <Grid
              sx={{
                px: { lg: 4, xs: 2 },
                boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "15px" },
                  mt: 2,
                  fontWeight: { lg: 500, xs: 500 },
                  color: "#6A9B41",
                  my: 2,
                  display: { md: "none", xs: "block" },
                }}
              >
                {t("signin.forget_msg")}
              </Typography>
              <Typography
                sx={{
                  fontWeight: { md: 600, xs: 600 },
                  fontSize: { md: "32px", xs: "18px" },
                  color: "white",
                }}
              >
                {t("signin.forget_pass")}
              </Typography>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%", // Set the width of the form
                }}
              >
                <CustomField
                  label={t("form.email")}
                  placeholder="abc132@email.com"
                  type="email"
                  id="email"
                  name="email"
                  onChange={(email) => setEmail(email)}
                  sx={{
                    my: 3,
                    color: "white",
                    borderColor: "white",
                    borderWidth: "2px", // For the text color
                    "& label": {
                      color: "white", // For the label color
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      color: "white", // For the text color in normal state
                      borderColor: "white", // For the border color in normal state
                      borderWidth: "2px", // Adjust the border width if needed
                      "&:hover fieldset": {
                        borderColor: "white", // For the border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white", // For the border color when focused
                      },
                    },
                  }}
                />

                <Button
                  btnname="button.continue"
                  type="submit"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    py: "10px",
                    fontSize: { lg: "22px", xs: "12px" },
                    my: 1,
                  }}
                />
                <Link to="/contactus" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      mt: 3,
                      fontSize: { md: "18px", xs: "12px" },
                      color: "#F26F21",
                    }}
                  >
                    {t("signin.contact_support")}
                  </Typography>
                </Link>
              </form>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{ p: 0, display: { xs: "none", md: "block" } }}
          >
            <RightImgWithPopup
              charcterStyle={{ maxWidth: "110%", transform: "scaleX(-1)" }}
              style={{ bottom: "-17vh" }}
              img={ForgetAvatar}
              msg="signin.forget_msg"
            />
          </Grid>
        </Grid>
        <ForgetEmailModal
          open={showForgetEmail}
          handleClose={() => setShowForgetEmail(false)}
          setShowForgetEmail={setShowForgetEmail}
        />
      </Container>
    </ModalLayout>
  );
}
