import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";
import AuthLayout from "../Layouts/AuthLayout";
import SiteLogo from "../Components/Logo";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import Button from "../Components/Button";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { display, width } from "@mui/system";
import { Link } from "react-router-dom";


export default function ExpiredToken() {
  const [t, i18n] = useTranslation("global");

  return (
    <AuthLayout>
      <Container >
        <SiteLogo />
        <Grid sx={{
          display: "flex", alignItems: "center",
          justifyContent: "center",
          flexDirection: "column", maxHeight: "100vh", minHeight: "90vh"
        }}>
          <Typography sx={{ fontSize: { lg: "55px", xs: "35px" }, fontWeight: 800, color: "#6A9B41" }}>Opps</Typography>
          <Typography sx={{ fontSize: { lg: "45px", xs: "30px" }, fontWeight: 800 }}>Email Token Expired!</Typography>
          <Link to={"/signin"}
                          style={{ textDecoration: "none" }}>
            <Button
              btnname="Back to Home"
              type="submit"
              variant="contained"
              className="Orangebtn"
              sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" }, my: 7, width: { lg: "548px", xs: "220px" } }}
            />
            </Link>
        </Grid>
      </Container>
    </AuthLayout>
  );
}

