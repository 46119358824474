import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";

export default function BasicButtons({ btnname, variant, className, sx, type, onClick }) {
  const [t, i18n] = useTranslation("global");
  return (
    <Stack >
      <Button variant={variant} type={type} onClick={onClick} className={`${className} `} sx={{
        ...sx, '&: hover': {
          backgroundColor: '#7cb74a', color: 'white'
        }
      }} > {t(btnname)}</Button>
    </Stack>
  );
}