import React, { useState, useEffect } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import SettingSidebar from "../../Components/SettingSidebar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepperStep3 from "../Subscription/StepperStep3";
import StepperStep4 from "../Subscription/StepperStep4";
import StepperStep5 from "../Subscription/StepperStep5";
import UserPrefrenceStep1 from "../../Components/UserPrefrenceStep1";
import { useTranslation } from "react-i18next";
import Cookie from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { updatePreferences } from "../../Redux/Slices/preferenceSlice";
import { setCurrentStepper } from "../../Redux/Slices/stepperInfoSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const steps = ["", "", ""];

const UserPrefrence = () => {
  const preferences = useSelector((state) => state.preferences.data);
  const currentStepper = useSelector(
    (state) => state.stepperInfo.currentStepper
  );

  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [t, i18n] = useTranslation("global");
  const [formData, setFormData] = useState({
    stepper2: {
      select_number: "",
      voipmsg: "",
      delete_msg: "",
    },
    stepper3: {
      default: "",
      checkbox: "",
      start_time: "",
      end_time: "",
      checkedDays: "",
      time_zone: "",
    },
    stepper4: {
      location: "",
      default_drive_time: "",
      drive_time_number: "",
      drive_time_formate: "",
      travel_mode: "",
      default_Early_Arrival: "",
      early_arrival_number: "",
      early_arrival_time_formate: "",
      close_appointment_default: "",
      close_appointment_number: "",
      close_appointment_time_formate: "",
      buffer_default: "",
      buffer_number: "",
      buffer_time_formate: "",
      fee_req_default: "",
      fee_req: "",
    },
    stepper5: {
      calendar: "",
      reminder: "",
      notification: "",
      accounts: [],
    },
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [default_invitaion_settings, setdefault_invitaion_settings] =
    useState(true);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    dispatch(setCurrentStepper(newActiveStep));
  };

  const handleBack = () => {
    const prevStep = currentStepper == 0 ? 0 : currentStepper - 1;
    dispatch(setCurrentStepper(prevStep));
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    dispatch(setCurrentStepper(step));
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    dispatch(setCurrentStepper(0));
  };

  const [startTimes, setStartTimes] = useState(new Array(7).fill(null));
  const [endTimes, setEndTimes] = useState(new Array(7).fill(null));
  return (
    <>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <DashboardLayout>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <SettingSidebar />
            </Grid>
            <Grid item xs={12} lg={9}>
              <Grid
                item
                lg={10}
                xs={12}
                sx={{ justifyContent: "center", mx: "auto" }}
              >
                <Typography
                  sx={{
                    fontSize: { lg: "32px", xs: "18px" },
                    fontWeight: 800,
                    color: "#545454",
                  }}
                >
                  {t("prefrence.title")}
                </Typography>
                {/* stepper code  */}
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  sx={{ mx: "auto", pt: 7, width: { lg: "90%", xs: "90%" } }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton
                        sx={{ zIndex: 1000 }}
                        color="inherit"
                        onClick={handleStep(index)}
                        completed={completed[index]}
                        disabled={index > completedSteps()}
                      ></StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {allStepsCompleted() ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1, py: 1, boxShadow: 1 }}>
                        {currentStepper === 0 && (
                          <StepperStep3
                            onBack={handleBack}
                            onNext={handleNext}
                            lg={12}
                            show={false}
                            formData={formData.stepper3}
                            startTimes={startTimes}
                            endTimes={endTimes}
                            setdefault_invitaion_settings={
                              setdefault_invitaion_settings
                            }
                          />
                        )}
                        {currentStepper === 1 && (
                          <StepperStep4
                            onBack={handleBack}
                            onNext={handleNext}
                            lg={12}
                            show={false}
                            setdefault_invitaion_settings={
                              setdefault_invitaion_settings
                            }
                          />
                        )}
                        {currentStepper === 2 && (
                          <StepperStep5
                            onBack={handleBack}
                            onNext={handleNext}
                            lg={12}
                            show={false}
                          />
                        )}
                      </Typography>
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default UserPrefrence;
