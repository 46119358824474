import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addAppleAccount } from "../../../Redux/thunks/AppleAccount";

const NYLAS_CLIENT_ID = process.env.REACT_APP_NYLAS_CLIENT_ID;
const NylasBaseURL = process.env.REACT_APP_NYLASBASEURL;
const NYLAS_REDIRECT_URL = process.env.REACT_APP_NYLAS_REDIRECT_URL;
const NYLAS_CLIENT_SECRET = process.env.REACT_APP_NYLAS_CLIENT_SECRET;
export default function AppleCalendar({
  authFlowType,
  activity,
  onEmailSelect,
  formData,
  setFormData,
  index,
}) {
  const [authWindow, setAuthWindow] = useState(null);

  const dispatch = useDispatch();

  const handleAppleAuth = () => {
    try {
      const authUrl = `${NylasBaseURL}/v3/connect/auth?client_id=${NYLAS_CLIENT_ID}&redirect_uri=${NYLAS_REDIRECT_URL}&response_type=code&provider=google&access_type=offline&state=sQ6vFQN`;
      const newWindow = window.open(authUrl, "_blank", "width=500,height=600");
      setAuthWindow(newWindow);

      const checkUrlChange = setInterval(async () => {
        try {
          if (newWindow.location.href.includes(NYLAS_REDIRECT_URL)) {
            const urlParams = new URLSearchParams(newWindow.location.search);
            const code = urlParams.get("code");
            if (code) {
              localStorage.setItem("authorizationCode", code);
              newWindow.close();
              clearInterval(checkUrlChange);
              setAuthWindow(null);
              try {
                const response = await axios.post(
                  `${NylasBaseURL}/v3/connect/token`,
                  {
                    code: code,
                    client_id: NYLAS_CLIENT_ID,
                    client_secret: NYLAS_CLIENT_SECRET,
                    redirect_uri: NYLAS_REDIRECT_URL,
                    grant_type: "authorization_code",
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                const grant_id = response.data.grant_id;
                const email = response.data.email;

                let mailAccounts = [...formData.mail_accounts];
                mailAccounts[index] = {
                  ...mailAccounts[index],
                  account_type: "apple",
                  account_email: email,
                };
                setFormData((prevData) => ({
                  ...prevData,
                  mail_accounts: mailAccounts,
                }));

                dispatch(
                  addAppleAccount({
                    grant_id: grant_id,
                    email: email,
                    signing_agency_id:
                      formData.mail_accounts[index].signing_agency_id,
                  })
                );
              } catch (error) {
                console.log(error.response);
              }
            }
          }
        } catch (error) {}
        if (newWindow.closed) {
          clearInterval(checkUrlChange);
          setAuthWindow(null);
        }
      }, 200);
    } catch (e) {}
  };

  return (
    <>
      {activity === "calendar" && (
        <Typography
          onClick={handleAppleAuth}
          sx={{
            fontSize: "14px",
            color: "#F26F21",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          Add
        </Typography>
      )}
      {activity === "mail" && (
        <AppleIcon
          onClick={handleAppleAuth}
          sx={{ cursor: "pointer", ml: 2 }}
        />
      )}
      {activity === "signin" && <></>}
      {activity === "signup" && <></>}
    </>
  );
}
