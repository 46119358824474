// import React from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { useTranslation } from "react-i18next";

// function CustomSelect({ label, menuItems, required , id, name, onChange}) {
//   const [t, i18n] = useTranslation("global");
//   const valueChangr = (e) =>{
//     const {name,value} = e.target
//     onChange(name,value)
//   }
//   return (
//     <div>
//       <FormControl fullWidth sx={{ my: 1 }}>
//         <InputLabel id="demo-simple-select-helper-label" required={required}>
//           {label}
//         </InputLabel>
//         <Select
//           required={required}
//           labelId={`${id}-label`}
//           id={id}
//           onChange={valueChangr}
//           name={name}
//           label={t(label)}
//           sx={{ borderRadius: "12px" }}
//           MenuProps={{
//             disableScrollLock: true, // Disable the scroll lock on the menu
//           }}
//         >
//           {menuItems.map((item, index) => (
//             <MenuItem key={index} value={item.value}>
//               {t(item.label)}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }

// export default CustomSelect;

import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

function CustomSelect({
  label,
  disabled,
  menuItems,
  required,
  id,
  name,
  onChange,
  value,
}) {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <FormControl fullWidth sx={{ my: 1 }}>
        <InputLabel id={`${id}-label`} required={required}>
          {label}
        </InputLabel>
        <Select
          disabled={disabled}
          required={required}
          labelId={`${id}-label`}
          id={id}
          onChange={onChange}
          value={value}
          name={name}
          label={t(label)}
          sx={{ borderRadius: "12px" }}
          MenuProps={{
            disableScrollLock: true, // Disable the scroll lock on the menu
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {t(item.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomSelect;

// import React from "react";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";

// const CustomSelect = ({ label, options = [], value, onChange }) => {
//   return (
//     <FormControl fullWidth>
//       <InputLabel>{label}</InputLabel>
//       <Select value={value} onChange={(e) => onChange(e.target.value)}>
//         {options.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             {option.label}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default CustomSelect;
