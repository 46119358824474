import React from "react";
import { Grid, Typography } from "@mui/material";
import ModalLayout from "../Layouts/ModalLayout";
import Button from "../Components/Button";
import CustomField from "./CustomField";
import { useTranslation } from "react-i18next";

const EditModal = ({ open, handleClose, order }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "40%", xs: "70%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "80%", xs: "42%" },
        mx: { sm: 5, lg: 0 },
        borderRadius: "10px",
        bgcolor: "background.paper",
      }}
    >
      <Typography
        sx={{
          fontSize: { lg: "24px", xs: "18px" },
          fontWeight: 600,
          textAlign: "center",
          mb: 5,
        }}
      >
        {t("editmodal.editorder_left")} <span>{order?.id}</span>{" "}
        {t("editmodal.editorder_right")}
      </Typography>
      <form>
        <Grid item xs={12}>
          <CustomField
            label={t("editmodal.ordername")}
            placeholder={t("editmodal.ordername")}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomField
            label={t("editmodal.location")}
            placeholder={t("editmodal.locationplaceholder")}
            type="text"
            value={order?.closing_city}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="" type="date" value={order?.closing_date} />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="" type="time" value={order?.closing_time} />
        </Grid>
        <Grid
          sx={{
            my: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            btnname="button.cancel"
            type="submit"
            onClick={handleClose}
            variant="contained"
            className=""
            sx={{
              borderRadius: "8px",
              px: { lg: 7, xs: 3 },
              py: 1.2,
              fontSize: { lg: "16px", xs: "12px" },
              mx: 1,
              color: "black",
              transition: "background-color 0.5s ease, color 0.7s ease",
              backgroundColor: "white",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", backgroundColor: "#ECECEC" },
            }}
          />
          <Button
            btnname="button.submit"
            type="submit"
            variant="contained"
            className="Orangebtn"
            sx={{
              px: { lg: 7, xs: 3 },
              py: 1.2,
              fontSize: { lg: "16px", xs: "12px" },
              mx: 1,
            }}
          />
        </Grid>
      </form>
    </ModalLayout>
  );
};

export default EditModal;
