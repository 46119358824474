import React from 'react';
import { Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import { Box, Container } from '@mui/system';
import Button from '../Components/Button';
import { Link } from 'react-router-dom';

const Signup = () => {

    const points = [
        'Intelligently evaluates new work opportunities.',
        'Ensures no invitations are “missed”',
        'Ensures that the notary promptly responds to each invitation received.',
        'Guarantees that no invitation goes unanswered.',
        'Simple to install, easy to use.',
        'Payback on the Yearly cost within hours or days.',
        'Experience a quick return on investment within a short timeframe.',
        'Provides capabilities that no other solution offers.',
        'Maximizes the notary’s Revenues, Income, and Expense Deductions.',
        'Saves time by evaluating opportunities for the notary.'
    ];

    return (
        <Box
            sx={{
                background: "linear-gradient(90deg, #ffffff 40%, #fdefe3 60%)",
                paddingTop: { xs: '0px', md: '70px' },
                paddingBottom: '40px',
                marginTop: {md: 'auto', xs: '50px'}
            }}
        >
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', height: '400px', justifyContent: { sm: 'center', md: 'space-between', xs: 'space-around' }, alignItems: { xs: 'center', md: 'flex-start' } }}>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                lineHeight: '130%',
                                fontSize: { lg: "42px", xs: "36px" },
                                fontWeight: 600,
                                textTransform: "capitalize",
                                color: 'black',
                                textAlign: { md: 'left', xs: 'center' },
                                marginTop: { xs: '-40px', md: '0px' }
                            }}
                        >
                            Why Should I Sign Up For <span style={{ color: "#F26F21" }}> GoSignings?</span>
                        </Typography>
                        <Link to={"Signup"} style={{ textDecoration: 'none' }}>
                            <Button
                                btnname='Register Now!'
                                variant='contained'
                                sx={{
                                    backgroundColor: '#f26f21', color: 'black', fontSize: '24px', color: 'white', marginTop: { xs: '-80px', md: '10px' }, lineHeight: '200%', borderRadius: '10px', textTransform: 'none', width: { xs: '250px', md: '300px' }, '&:hover': {
                                        backgroundColor: '#7cb74a', color: 'white'
                                    }
                                }}
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                lineHeight: '130%',
                                fontSize: { lg: "40px", xs: "37px" },
                                fontWeight: 600,
                                textTransform: "capitalize",
                                color: 'black',
                                textAlign: { md: 'left', xs: 'center' },
                                marginTop: { xs: '-80px', md: 'auto' }

                            }}
                        >
                            <span style={{ color: "#F26F21" }}>GoSignings</span> Provides Significant Value to Mobile Notaries
                        </Typography>
                        <List>
                            <ul style={{ listStyleType: 'disc', paddingLeft: '30px' }}>
                                {points.map((point, index) => (
                                    <li key={index}>
                                        <Typography
                                            sx={{
                                                fontSize: { lg: '18px', xs: '14px' },
                                                fontWeight: '400',
                                                color: 'black',
                                                width: 'auto',
                                                marginLeft: { xs: '4px', md: '10px' },
                                            }}
                                        >
                                            {point}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </List>

                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Signup;
