import React from "react";
import "./App.css";
import SignIn from "./Pages/Auth/SignIn";
import "./index.css";
import SignUp from "./Pages/Auth/SignUp";
import AccountConfirmationEmail from "./Pages/Auth/AccountConfirmationEmail";
import ResetPasswordEmail from "./Pages/Auth/ResetPasswordEmail";
import ResetPassword from "./Pages/Auth/ResetPassword";
import HorizontalStepper from "./Pages/Subscription/Stepper";
import ContactUs from "./Pages/ContactUs";
import HelpSupport from "./Pages/HelpSupport";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Dashboard from "./Pages/DashboardScreens/Dashboard";
import LandingPage from "./Pages/LandingPage_1";
import Inbox from "./Pages/DashboardScreens/Inbox";
import Setting from "./Pages/DashboardScreens/Setting";
import UserPrefrence from "./Pages/DashboardScreens/UserPrefrence";
import BillingHistory from "./Pages/DashboardScreens/BillingHistory";
import Subscription from "./Pages/DashboardScreens/Subscription";
import SubscriptionAccount from "./Pages/DashboardScreens/SubscriptionAccount";
import StepperStep1 from "./Pages/Subscription/StepperStep1";
import Errorpage from "./Pages/Errorpage";
import ExpiredToken from "./Pages/ExpiredToken";
import ProtectedRoute from "./ProtectedRoutes";
import NotificationChannel from "./Redux/NotificationChannel";
import { useSelector } from "react-redux";
import VoipPage from "./Pages/DashboardScreens/VoipPage";
import OutlookCallback from "./Pages/OutlookRedirect";
import ErrorBoundary from "./Pages/ErrorBoundary";
import TermsAndConditions from "./Pages/Auth/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import EnvironmentVariableTest from "./Pages/EnvironmentVariableTest";
import AdminLayout from "./Pages/Admin/AdminLayout";
import BrowserAutomation from "./Pages/Admin/BrowserAutomation";

function App() {
  const user = useSelector((state) => state.user.user);
  NotificationChannel(user?.id);
  const location = useLocation();
  React.useEffect(() => {
    const path = location.pathname;
    let title = "GoSignings"; // Default title
    switch (path) {
      case "/signin":
        title = "Sign In";
        break;
      case "/Signup":
        title = "Sign Up";
        break;
      case "/contactus":
        title = "Contact Us";
        break;
      case "/accountconfirmation":
        title = "account Confirmation";
        break;
      case "/signin/resetpassword":
        title = "Reset Password";
        break;
      case "/accountconfirmation/subscription":
        title = "Prefrences";
        break;
      case "/helpcenter":
        title = "Help Center";
        break;
      case "/Dashboard":
        title = "Dashboard";
        break;
      case "/inbox":
        title = "Inbox";
        break;
      case "/account":
        title = "Account";
        break;
      case "/prefrence":
        title = "Prefrence";
        break;
      case "/subscription":
        title = "Subscription";
        break;
      case "/subscriptionaccount":
        title = "subscription Account";
        break;
      case "/billing":
        title = "Billing";
        break;
      default:
        title = "GoSignings"; // Default title
        break;
    }
    document.title = title;
  }, [location.pathname]);

  console.error = () => {};

  return (
    <div>
      <ErrorBoundary>
        <Routes>
          <Route path="admin" Component={AdminLayout}>
            <Route
              path="BrowserAutomation/:token"
              Component={BrowserAutomation}
            />
          </Route>

          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/Signup" element={<SignUp />} />
          <Route path="/outlook/callback" element={<OutlookCallback />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/accountconfirmation"
            element={<ProtectedRoute Component={AccountConfirmationEmail} />}
          />

          <Route path="/env" element={<EnvironmentVariableTest />} />

          <Route
            path="/signin/resetpassemail"
            element={<ProtectedRoute Component={ResetPasswordEmail} />}
          />
          <Route
            path="/signin/resetpassword/:id/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/accountconfirmation/subscription/:user_id"
            element={<HorizontalStepper />}
          />
          <Route path="/helpcenter" element={<HelpSupport />} />
          <Route
            path="/Dashboard"
            element={<ProtectedRoute Component={Dashboard} />}
          />
          <Route path="/inbox" element={<ProtectedRoute Component={Inbox} />} />
          <Route
            path="/account"
            element={<ProtectedRoute Component={Setting} />}
          />
          <Route
            path="/prefrence"
            element={<ProtectedRoute Component={UserPrefrence} />}
          />
          <Route
            path="/voip"
            element={<ProtectedRoute Component={VoipPage} />}
          />
          <Route
            path="/subscription"
            element={<ProtectedRoute Component={Subscription} />}
          />
          <Route
            path="/subscriptionaccount/:plan"
            element={<ProtectedRoute Component={SubscriptionAccount} />}
          />
          <Route
            path="/billing"
            element={<ProtectedRoute Component={BillingHistory} />}
          />
          <Route path="/unauthorized" element={<Errorpage />} />
          <Route path="/emailtokenexpired" element={<ExpiredToken />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
