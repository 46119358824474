import React, { useEffect } from "react";
import Cookie from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = (props) => {
  const { Component } = props;
  const user = useSelector((state) => state?.user?.user);
  const userState = useSelector((state) => state?.user);
  const Navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      Navigate("/signin");
    } else if (user?.email_verified_at == null) {
      Navigate("/accountconfirmation");
    } else if (user?.preferences_set == "false") {
      Navigate(`/accountconfirmation/subscription/${user?.id}`);
    } else if (user?.subscription_status == "inactive") {
      Navigate("/subscription");
    }
  }, [user, userState]);

  return (
    <>
      <Component />
    </>
  );
};
export default ProtectedRoute;
