import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import BigCalender from "../../Components/BigCalender";
import CalenderDropdown from "../../Components/CalenderDropdown";

import CheckboxLabel from "../../Components/Checkbox";
const CalenderTab = () => {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ display: { xs: "none", lg: "block" } }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar sx={{ width: "80%" }} />
            </LocalizationProvider>
            <CalenderDropdown sx={{ display: { lg: "block", xs: "none" } }} />
          </Grid>
          <Grid item xs={12} lg={9}>
            {/* <CalenderDropdown sx={{ display: { lg: "none", xs: "block" } }} /> */}
            <BigCalender />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CalenderTab;
