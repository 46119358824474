import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import SignupAvatar from "../../Assets/Images/signupimg.png";
import ImgWithPopup from "../../Components/ImgWithPopup";
import { Container, Typography } from "@mui/material";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import ModalLayout from "../../Layouts/ModalLayout";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function ForgetEmailModal({ open, handleClose }) {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
      }}
    >
      <Button
        sx={{
          position: "absolute",
          top: "8%",
          right: "5%",
          color: "#fff",
          fontSize: { lg: "22px", xs: "12px" },
          "&:hover": { boxShadow: "none", color: "red" },
        }}
        onClick={handleClose}
        btnname="x"
      />
      {/* </Button> */}
      <Container zIndex sx={{}}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ maxHeight: "100%", minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{ p: 0, display: { xs: "none", md: "block" } }}
          >
            <ImgWithPopup
              charcterStyle={{ maxWidth: "107%" }}
              style={{ bottom: "-15vh", left: "39%" }}
              img={SignupAvatar}
              msg="signin.forget_pass_email_msg"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xlg={6}
            sx={{ my: "auto", position: "relative" }}
          >
            <Grid
              sx={{
                px: { lg: 4, xs: 2 },
                boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: { md: 600, xs: 500 },
                  fontSize: { md: "32px", xs: "18px" },
                  color: "#6A9B41",
                }}
              >
                {t("signin.check_email")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  mt: 2,
                  fontWeight: { lg: 500, xs: 500 },
                  color: "white",
                  display: { md: "none", xs: "block" },
                }}
              >
                {t("signin.forget_pass_email_msg")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  mt: 2,
                  fontWeight: { lg: 500, xs: 500 },
                  color: "white",
                }}
              >
                {t("signin.not_recieve")}
              </Typography>
              <List component="nav">
                <ListItem>
                  <ListItemIcon>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        marginRight: "10px",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      fontSize: { lg: "20px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("signin.forget_pass_email_list1")}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        // marginRight: "10px",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      fontSize: { lg: "20px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("signin.forget_pass_email_list2")}
                  </Typography>
                </ListItem>
              </List>
              {/* <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  fontWeight: { lg: 500, xs: 500 },
                  color: "#F26F21",
                }}
              >
                {t("signin.resend_email")}
              </Typography> */}
              <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  fontWeight: { lg: 500, xs: 500 },
                  color: "white",
                }}
              >
                {" "}
                {t("signin.nothelp")}{" "}
                <Link to="/contactus" style={{ textDecoration: "none" }}>
                  <span style={{ color: "#F26F21" }}>
                    {" "}
                    {t("signin.contact_support")}{" "}
                  </span>
                </Link>{" "}
              </Typography>

              <Grid>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  btnname="button.continue"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    py: "10px",
                    fontSize: { lg: "22px", xs: "12px" },
                    my: 3,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ModalLayout>
  );
}
