import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Container, Typography } from "@mui/material";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import AccountVarificationimg from "../../Assets/Images/accountvarificationavatar.png";
import ModalLayout from "../../Layouts/ModalLayout";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function AccountVarification({ open, handleClose, email }) {
  const [t, i18n] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const user = useSelector((state) => state.user.user);
  // console.log(user);

  const navigate = useNavigate();

  let resendEmailVerification = () => {
    setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    const token = Cookie.get("access_token");
    myHeaders.append("Authorization", `Bearer ${token}`);

    console.log(token);

    const formdata = new FormData();
    formdata.append("email", Cookie.get("user_email"));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_GOSIGNING_API_ENDPOINT}/email/resend-email-verification`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
          // navigate("/unauthorized", { state: { propValue: "Unauthorized" } });
        }
      })
      .then((result) => {
        setLoading(false);
        if (result.data.code == 1) {
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
      })

      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <ModalLayout
      open={open}
      handleClose={handleClose}
      sx={{
        width: { lg: "100%", xs: "100%" },
        p: { lg: 7, xs: 2 },
        height: { lg: "100%", xs: "100%" },
      }}
    >
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <Button
        sx={{
          position: "absolute",
          top: "8%",
          right: "5%",
          color: "#fff",
          fontSize: { lg: "22px", xs: "12px" },
          "&:hover": { boxShadow: "none", color: "red" },
        }}
        onClick={handleClose}
        btnname="x"
      />
      {/* </Button> */}
      <Container zIndex sx={{ py: { lg: 5 } }}>
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} md={4} lg={4}>
            <Grid sx={{ display: { xs: "none", md: "block" } }}>
              <Box display="flex" justifyContent="center">
                <img
                  src={AccountVarificationimg}
                  alt="img"
                  style={{
                    maxWidth: "55vh",
                    maxHeight: "90vh",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xlg={8} sx={{}}>
            <Grid sx={{ pl: { lg: 7, xl: 20 } }}>
              <Typography
                sx={{
                  fontWeight: { md: 700, xs: 600 },
                  fontSize: { md: "40px", xs: "18px" },
                  color: "#6A9B41",
                }}
              >
                {t("signup_modal.check_email")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "20px", xs: "12px" },
                  mt: 2,
                  fontWeight: { lg: 500, xs: 500 },
                  color: "white",
                }}
              >
                {t("signup_modal.text1")}{" "}
                <span style={{ color: "#6A9B41" }}> {email}</span>
                <br />
                {t("signup_modal.text2")} <br />
                {t("signup_modal.text3")}
                <br />
                {t("signup_modal.text4")}
                <br />
                {t("signup_modal.text5")} <br />
              </Typography>
              <List component="nav">
                <ListItem>
                  <ListItemIcon>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#F26F21", // orange color
                        marginRight: "10px",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      fontSize: { lg: "20px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("signup_modal.click")}{" "}
                    <span
                      style={{ color: "#F26F21", cursor: "pointer" }}
                      onClick={resendEmailVerification}
                    >
                      {" "}
                      {t("signin.resend_email")}{" "}
                    </span>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#F26F21", // orange color
                        marginRight: "10px",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      fontSize: { lg: "20px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("signin.nothelp")}
                    <Link to="/contactus" style={{ textDecoration: "none" }}>
                      {" "}
                      <span style={{ color: "#F26F21" }}>
                        {" "}
                        {t("contact.contact")}{" "}
                      </span>
                    </Link>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#F26F21", // orange color
                        marginRight: "10px",
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "white",
                      fontSize: { lg: "20px", xs: "12px" },
                      fontWeight: { lg: 500, xs: 500 },
                    }}
                  >
                    {t("signup_modal.incorrectemail")}{" "}
                    <Link to="/" style={{ textDecoration: "none" }}>
                      {" "}
                      <span style={{ color: "#F26F21" }}>
                        {" "}
                        {t("button.back")}{" "}
                      </span>
                    </Link>
                    .
                  </Typography>
                </ListItem>
              </List>

              <Grid lg={8} xl={12}>
                <Button
                  btnname="button.continue"
                  type="submit"
                  variant="contained"
                  onClick={handleClose}
                  className="Orangebtn"
                  sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" } }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ModalLayout>
  );
}
